/** 起点 */
export interface Anchor {
  x: DirectionX
  y: DirectionY
}

export enum DirectionX {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export enum DirectionY {
  Top = 'top',
  Bottom = 'bottom'
}
