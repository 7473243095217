type ValidationResult =
  | {
      ok: true
    }
  | {
      ok: false
      message: string
    }

export const forbiddenChars = /[^A-Z0-9.,&-]/g

export function validate(text: string): ValidationResult {
  if (!text) {
    return {
      ok: false,
      message: '刻印する文字を入力してください'
    }
  }

  const foundForbiddenChars = text.match(forbiddenChars)

  if (foundForbiddenChars !== null) {
    return {
      ok: false,
      message: `入力出来ない文字 (${foundForbiddenChars
        .filter((char, i, str) => str.indexOf(char) === i)
        .map((char) => (/\s/.test(char) ? '空白文字' : char))
        .join(',')}) が含まれています`
    }
  }

  return { ok: true }
}
