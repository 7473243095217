import styled from '@emotion/styled/macro'
import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import { RadioButton, RadioButtonGroup } from '~/components/atoms/RadioButton'
import type { Customization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import { PrintType } from '~/types/PrintType'
import { validate } from '~/utils/printName'

import { TextBox } from './TextBox'

interface PrintNameProps {
  className?: string

  disabled?: boolean

  customization: Customization
  design: Design

  placeholder?: string

  onCustom(customization: Customization): void
}

export const PrintName = ({
  className,
  disabled,
  customization,
  design,
  placeholder,
  onCustom
}: PrintNameProps) => {
  if (!design.printName) {
    return null
  }

  return (
    <Internal
      className={className}
      disabled={disabled}
      customization={customization}
      design={design.printName}
      placeholder={placeholder}
      onCustom={onCustom}
    />
  )
}

interface InternalProps {
  className?: string

  disabled?: boolean

  customization: Customization
  design: Exclude<Design['printName'], undefined>

  placeholder?: string

  onCustom(customization: Customization): void
}

const Internal = ({
  className,
  disabled,
  customization,
  design,
  placeholder,
  onCustom
}: InternalProps) => {
  const availableTypes = useMemo<
    readonly { printType: PrintType; label: string }[]
  >(() => {
    return design.printTypes.map((t) => {
      switch (t.printType) {
        case 'stamp':
          return { printType: PrintType.Embossing, label: '型押し' }
        case 'silver_emboss':
          return { printType: PrintType.SilverPressing, label: 'シルバー' }
        case 'gold_emboss':
          return { printType: PrintType.GoldPressing, label: 'ゴールド' }
      }
    })
  }, [design.printTypes])

  const validation = useMemo(
    () => validate(customization.printName?.text ?? ''),
    [customization.printName?.text]
  )

  const [isTouched, setIsTouched] = useState(!!customization.printName?.text)

  // なし / あり を切り替えた際にはフィールドを触ったかのフラグをリセットする
  useEffect(() => {
    setIsTouched(!!customization.printName?.text)
  }, [!customization.printName])

  const prevFocus = useRef<HTMLElement | null>(null)

  return (
    <Container className={className}>
      <span>
        {typeof customization.printName === 'undefined'
          ? '刻印の種類を選んでください'
          : customization.printName?.printType && !customization.printName.text
          ? '刻印する文字を入力してください'
          : // ラベルがない状態でもレイアウトの都合上要素の高さをそのまま保ちたいのでゼロ幅スペースを表示している
            '\u200b'}
      </span>
      <RadioButtonGroup
        value={
          (customization.printName && customization.printName.printType) ||
          (customization.printName?.printType === null ? 'null' : '')
        }
        onValueChange={(value: PrintType | 'null') => {
          onCustom({
            ...customization,
            printName: {
              printType: value === 'null' ? null : value,
              text: customization.printName?.text || ''
            }
          })
        }}
      >
        {availableTypes.map(({ printType, label }) => (
          <RadioButton key={printType} disabled={disabled} value={printType}>
            {label}
          </RadioButton>
        ))}
        <RadioButton value="null">なし</RadioButton>
      </RadioButtonGroup>
      <TextField
        noValidate
        data-hidden={!customization.printName?.printType || undefined}
        onSubmit={(ev) => {
          ev.preventDefault()

          if (!customization.printName) {
            return
          }

          // 大文字に変換する
          onCustom({
            ...customization,
            printName: {
              printType: customization.printName.printType,
              text: customization.printName.text.toUpperCase()
            }
          })

          // キーボードでフォーム確定したらフォーカスを戻すか外す。
          // バーチャルキーボードで確定しても何も起こらず、ユーザからしたらわかりづらいため。
          // キーボードだけで操作している場合はフォーカスが戻るので、少し使いづらいが...。
          if (
            document.activeElement &&
            document.activeElement.id === 'print_name_text'
          ) {
            if (prevFocus.current) {
              prevFocus.current.focus()
            } else {
              ;(document.activeElement as HTMLInputElement).blur()
            }
          }
        }}
      >
        <TextBox
          id="print_name_text"
          pattern="[A-Z0-9.,&-]*"
          required
          disabled={!customization.printName}
          label="刻印する文字を入力"
          value={customization.printName?.text ?? ''}
          enterKeyHint="done"
          placeholder={placeholder}
          maxLength={design.maxLength}
          inputMode="url"
          state={!isTouched || validation.ok ? 'neutral' : 'invalid'}
          aria-errormessage={
            !isTouched || validation.ok ? undefined : 'print_name_text_error'
          }
          aria-describedby="print_name_text_description"
          onBlur={() => {
            setIsTouched(true)

            if (!customization.printName) {
              return
            }

            // 大文字に変換する
            onCustom({
              ...customization,
              printName: {
                printType: customization.printName.printType,
                text: customization.printName.text.toUpperCase()
              }
            })
          }}
          onFocus={(ev) => {
            if (!ev.relatedTarget || ev.relatedTarget instanceof HTMLElement) {
              prevFocus.current = ev.relatedTarget
            }
          }}
          onChange={(value) => {
            if (!customization.printName) {
              return
            }

            setIsTouched(true)

            onCustom({
              ...customization,
              printName: {
                printType: customization.printName.printType,
                text: value
              }
            })
          }}
        />
        <FieldNote
          id="print_name_text_description"
          data-invalid={!isTouched || validation.ok ? undefined : ''}
        >
          {'半角の英数大文字(A-Z 0-9)と一部記号(. , & -)のみ入力可'}
        </FieldNote>
        <FieldNote
          id="print_name_text_error"
          data-invalid={!isTouched || validation.ok ? undefined : ''}
          data-hidden={!isTouched || undefined}
        >
          {validation.ok
            ? // エラーでなくても行の高さを自然に維持したいのでゼロ幅スペースを表示している
              '\u200b'
            : validation.message}
        </FieldNote>
        <HiddenButton type="submit">名入れ文字列を確定</HiddenButton>
      </TextField>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gutter-medium);

  @media (max-width: 355px) {
    --radio-button--padding-h: var(--gutter-small);
  }

  @media (max-width: 300px) {
    --radio-button--padding-h: var(--gutter-extra-small);
  }
`

const TextField = styled.form`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--gutter-small);

  &[data-hidden] {
    visibility: hidden;
  }
`

const FieldNote = styled.span`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.05em;

  &[data-invalid] {
    color: var(--red-400);
  }

  &[data-hidden] {
    visibility: hidden;
  }
`

const HiddenButton = styled.button`
  display: none;
`
