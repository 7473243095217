import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/react'
import * as React from 'react'
import { ReactNode } from 'react'

import { Icon } from '~/components/atoms/Icon'

export interface ButtonsProps {
  className?: string

  state: 'next-only' | 'both' | 'cart-only'

  disabled?: boolean

  nextButtonLabel: ReactNode
  cartButtonLabel: ReactNode

  onClickNextButton(): void
  onClickCartButton(): void
}

export const Buttons = ({
  state,
  disabled,
  nextButtonLabel,
  cartButtonLabel,
  onClickCartButton,
  onClickNextButton,
  ...rest
}: ButtonsProps) => {
  return (
    <Container {...rest} data-state={state}>
      {state !== 'cart-only' && (
        <NextButtonContainer
          disabled={disabled}
          onClick={() => onClickNextButton()}
        >
          <ButtonLabel>{nextButtonLabel}</ButtonLabel>
        </NextButtonContainer>
      )}
      {state !== 'next-only' && (
        <CartButtonContainer
          disabled={disabled}
          onClick={() => onClickCartButton()}
        >
          <ButtonBody data-compact={state === 'both'}>
            <ButtonIcon data-animate={state === 'both'}>
              <Icon type="cart-in" />
            </ButtonIcon>
            <ButtonLabel>{cartButtonLabel}</ButtonLabel>
          </ButtonBody>
        </CartButtonContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto) max-content;
  grid-template-areas: 'next cart';
  align-items: stretch;
  gap: var(--gutter-medium);

  &[data-state='next-only'] {
    gap: 0;
    grid-template-columns: 100% 0;
  }

  &[data-state='cart-only'] {
    gap: 0;
    grid-template-columns: 0 100%;
  }
`

const ButtonContainer = styled.button`
  --_button-bg: var(--teal-400);
  --_button-hover-bg: var(--teal-100);
  --_shadow-cast: 4px 4px 8px hsla(0, 0%, 25%, 0.3);

  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: var(--gutter-small) var(--gutter-extra-large);
  border: 1px solid var(--_button-bg);

  background-color: var(--_button-bg);
  border-radius: 24px;
  color: var(--gray-0);

  &:not(:disabled) {
    box-shadow: var(--_shadow-cast);
  }

  &:hover:not(:active) {
    background-color: var(--_button-hover-bg);
  }

  &:focus-visible {
    background-color: var(--_button-bg);
    box-shadow: var(--_shadow-cast), inset 0 0 0 4px var(--_button-hover-bg);
    border-color: var(--_button-hover-bg);
    outline: none;
  }

  &:disabled {
    background-color: var(--gray-200);
    border-color: var(--gray-700);
    color: var(--black-200);
  }
`

const NextButtonContainer = styled(ButtonContainer)`
  grid-area: next;
`

const CartButtonContainer = styled(ButtonContainer)`
  --_button-bg: var(--red-400);
  --_button-hover-bg: var(--red-100);

  grid-area: cart;
`

const ButtonBody = styled.span`
  display: inline-flex;
  gap: var(--gutter-medium);
  align-items: center;

  &[data-compact='true'] {
    gap: 0;
  }
`

const shake = keyframes`
  0% {
    transform: rotate(-10deg);
  }

  20% {
    transform: rotate(8deg);
  }

  40% {
    transform: rotate(-6deg);
  }

  60% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0deg);
  }
`

const ButtonIcon = styled.span`
  display: inline-grid;
  place-items: center;
  font-size: 24px;

  @media not (prefers-reduced-motion: reduce) {
    &[data-animate='true'] {
      animation: 0.3s 0.2s ease-in ${shake};
    }
  }
`

const ButtonLabel = styled.span`
  letter-spacing: 0.1em;
  font-size: 16px;
  line-height: 2;

  user-select: none;
  white-space: nowrap;

  [data-compact='true'] > & {
    display: none;
  }
`
