import styled from '@emotion/styled/macro'
import { format } from 'date-fns'
import * as React from 'react'
import { useMemo } from 'react'

import { OutlineButton } from '~/components/atoms/OutlineButton'
import { Dialog, useDialogState } from '~/components/atoms/Dialog'
import { Icon } from '~/components/atoms/Icon'
import type { StorageItem } from '~/hooks/useCustomizationStorage'
import type { Product } from '~/types/Product'

export interface TempDataProps {
  disabled: boolean

  item: StorageItem

  products: readonly Product[]

  onLoad(): void
  onDelete(): void
}

export const TempData = ({
  disabled,
  item,
  products,
  onDelete,
  onLoad
}: TempDataProps) => {
  const [deleteConfirmDialogProps, deleteConfirmDialog] = useDialogState()

  const product = useMemo(() => {
    return products.find((p) => p.id === item.product.id)
  }, [item.product.id, products])

  const savedAt = useMemo(() => {
    if (!item.savedAt) {
      return null
    }

    const date = new Date(item.savedAt)

    return format(date, 'yyyy/MM/dd hh:mm')
  }, [item.savedAt])

  return (
    <>
      <Container>
        <DeleteButton
          disabled={disabled}
          title="この保存データを削除する"
          onClick={() => {
            deleteConfirmDialog.open()
          }}
        >
          <Icon type="trash" />
        </DeleteButton>
        {product ? (
          <>
            <Body>
              <ProductName>{product.name}</ProductName>
              {savedAt && <MetaText>{savedAt}</MetaText>}
              <MetaText>
                {item.destination === 'cart'
                  ? 'カート未保存'
                  : 'マイデザイン未保存'}
              </MetaText>
            </Body>
            <LinkButton
              disabled={disabled}
              onClick={() => {
                onLoad()
              }}
            >
              再開する
            </LinkButton>
          </>
        ) : (
          <Body>
            <MetaText>現在ご利用できない商品です</MetaText>
            {savedAt && <MetaText>{savedAt}</MetaText>}
            <MetaText>
              {item.destination === 'cart'
                ? 'カート未保存'
                : 'マイデザイン未保存'}
            </MetaText>
          </Body>
        )}
      </Container>
      <PaddedDialog {...deleteConfirmDialogProps} title="削除確認">
        <DialogDescription>
          {product
            ? `${product.name}${savedAt ? ` (${savedAt}に保存) ` : ''}`
            : savedAt
            ? `${savedAt}に保存したデータ`
            : '一時保存データ'}
          を削除してもよろしいでしょうか？
        </DialogDescription>
        <DialogButton
          colorTheme="blue"
          onClick={() => {
            deleteConfirmDialog.close()
            onDelete()
          }}
        >
          削除する
        </DialogButton>
        <DialogButton
          colorTheme="blue-outline"
          onClick={() => {
            deleteConfirmDialog.close()
          }}
        >
          キャンセル
        </DialogButton>
      </PaddedDialog>
    </>
  )
}

const Container = styled.li`
  padding: var(--gutter-medium) var(--gutter-small);
  gap: var(--gutter-small);
  display: flex;
  align-items: center;

  background-color: var(--gray-0);
`

const DeleteButton = styled.button`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: var(--gutter-small);

  border-radius: 4px;
  color: var(--black-200);

  &:hover {
    background-color: var(--gray-400);
    color: var(--black-400);
  }

  &:active {
    background-color: var(--gray-700);
    color: var(--black-400);
  }

  &:focus-visible {
    outline: none;
    background-color: var(--gray-100);
    color: var(--black-400);

    box-shadow: 0 0 0 3px var(--blue-400);
  }

  &:disabled {
    background-color: transparent;
    color: var(--black-200);
  }
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content; flex-start;
  align-items: flex-start;
  gap: var(--gutter-small);
`

const ProductName = styled.span`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.05em;

  color: var(--black-700);
`

const MetaText = styled(ProductName)`
  color: var(--black-200);
`

const LinkButton = styled.button`
  display: inline;
  padding: 0 var(--gutter-small);
  border: none;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;

  background-color: none;
  color: var(--blue-400);
  outline: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    outline: none;
  }

  &:disabled {
    color: var(--black-200);
    text-decoration: none;
  }
`

const PaddedDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gutter-medium);
  gap: var(--gutter-medium);
  width: 90vw;
  max-width: 300px;
`

const DialogDescription = styled.p`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
`

const DialogButton = styled(OutlineButton)`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
  padding: var(--gutter-small);
  align-self: stretch;
`
