import { useEffect } from 'react'

import { useLocalCart } from '~/hooks/useLocalCart'
import { useMutation } from '~/hooks/useMutation'
import type { AnonymousSession } from '~/hooks/useSession'
import type { ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { Product } from '~/types/Product'
import { toRequestPayload } from '~/utils/customization'

interface MutationParams {
  customization: ReadyCustomization
  design: Design
  product: Product

  index: number

  session: AnonymousSession
}

interface UseLocalCartItemUpdateParams {
  onUpdated?(): void

  onError?(error: unknown, ctx: MutationParams): void
}

export function useLocalCartItemUpdate({
  onUpdated,
  onError
}: UseLocalCartItemUpdateParams) {
  const localCart = useLocalCart()

  const mutation = useMutation(
    async ({ customization, design, product, index }: MutationParams) =>
      localCart.update(index, toRequestPayload(customization, design, product))
  )

  // ステータスコールバックを呼ぶ
  useEffect(() => {
    switch (mutation.status) {
      case 'success': {
        onUpdated?.()
        return
      }
      case 'failed': {
        onError?.(mutation.error, mutation.params)
        return
      }
    }
  }, [mutation.status])

  return mutation
}
