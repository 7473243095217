import * as React from 'react'
import { Fragment, useMemo } from 'react'

import { fetchDesign, fetchColors } from '~/api/customization'
import { fetchMyDesign } from '~/api/myDesign'
import { fetchProduct } from '~/api/products'
import { Redirect } from '~/components/atoms/Redirect'
import { CustomizationCreationWarnings } from '~/components/organisms/CustomizationCreationWarnings'
import { OutdatedVersionedItemWarning } from '~/components/organisms/OutdatedVersionedItemWarning'
import {
  BootstrapErrorPage,
  FetchErrorBody,
  MyDesignFetchErrorBody,
  ProductFetchErrorBody
} from '~/components/pages/BootstrapErrorPage'
import { CustomizePage } from '~/components/pages/CustomizePage'
import { useAsyncResult } from '~/hooks/useAsyncResult'
import type { MyDesignItemUpdate as MyDesignItemUpdateMode } from '~/hooks/useBootstrapMode'
import { useLoginPageUrl } from '~/hooks/useLoginPageUrl'
import { LoginSession, useSession } from '~/hooks/useSession'
import type { MyDesign } from '~/types/MyDesign'
import { fromSavedDesign } from '~/utils/customization'

import { Core, CoreProps } from './Core'
import { loadingMessages } from './loadingMessages'

interface MyDesignItemUpdateProps {
  mode: MyDesignItemUpdateMode
}

export const MyDesignItemUpdate = ({ mode }: MyDesignItemUpdateProps) => {
  const session = useSession()

  const loginPageUrl = useLoginPageUrl({
    // そのまま再開させるため現在のURLを丸々渡す
    redirectTo: location.href
  })

  if (!session.isLoggedIn) {
    return <Redirect to={loginPageUrl} />
  }

  return <WithLoginSession mode={mode} session={session} />
}

const WithLoginSession = ({
  mode,
  session
}: MyDesignItemUpdateProps & { session: LoginSession }) => {
  const colors = useAsyncResult(() => fetchColors(), [])
  const myDesign = useAsyncResult(
    () => fetchMyDesign(mode.myDesignItem.id, session.accessToken.token),
    [mode.myDesignItem.id, session.accessToken]
  )

  const productId = myDesign.status === 'ok' ? myDesign.result.product.id : null

  const latestProduct = useAsyncResult(
    () => (productId ? fetchProduct(productId) : Promise.resolve(null)),
    [productId]
  )
  const design = useAsyncResult(
    () => (productId ? fetchDesign(productId) : Promise.resolve(null)),
    [productId]
  )

  if (colors.status === 'error') {
    return (
      <BootstrapErrorPage title="カラーの取得に失敗">
        <FetchErrorBody error={colors.error} onRetry={() => colors.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (myDesign.status === 'error') {
    return (
      <BootstrapErrorPage title="マイデザインの読み込みに失敗">
        <MyDesignFetchErrorBody
          error={myDesign.error}
          onRetry={() => myDesign.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (latestProduct.status === 'error') {
    return (
      <BootstrapErrorPage title="商品データの読み込みに失敗">
        <ProductFetchErrorBody
          error={latestProduct.error}
          onRetry={() => latestProduct.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (design.status === 'error') {
    return (
      <BootstrapErrorPage title="デザインデータの読み込みに失敗">
        <FetchErrorBody error={design.error} onRetry={() => design.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (myDesign.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.myDesign} />
  }

  if (colors.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.colors} />
  }

  if (latestProduct.status !== 'ok' || !latestProduct.result) {
    return <CustomizePage loading message={loadingMessages.product} />
  }

  if (design.status !== 'ok' || !design.result) {
    return <CustomizePage loading message={loadingMessages.design} />
  }

  return (
    <WithCustomization
      mode={mode}
      design={design.result}
      colors={colors.result}
      product={latestProduct.result}
      myDesign={myDesign.result}
    />
  )
}

const WithCustomization = (
  props: Omit<CoreProps, 'initialCustomization' | 'myDesign'> & {
    myDesign: MyDesign
    mode: MyDesignItemUpdateMode
  }
) => {
  const [initialCustomization, warnings] = useMemo(() => {
    return fromSavedDesign(props.myDesign, {
      design: props.design,
      colors: props.colors
    })
  }, [props.myDesign, props.design, props.colors])

  return (
    <Fragment>
      <Core {...props} initialCustomization={initialCustomization} />
      <CustomizationCreationWarnings warnings={warnings} />
      {props.myDesign.product.version !== props.product.version && (
        <OutdatedVersionedItemWarning />
      )}
    </Fragment>
  )
}
