import styled from '@emotion/styled/macro'
import * as React from 'react'

import { Dialog } from '~/components/atoms/Dialog'
import { FloatingButton } from '~/components/atoms/FloatingButton'

interface MyDesignLoginDialogProps<T extends Record<any, any>> {
  data: T | null

  onClose(): void

  onLogin(data: T): void
}

export function MyDesignLoginDialog<T extends Record<any, any>>({
  data,
  onLogin,
  ...rest
}: MyDesignLoginDialogProps<T>) {
  return (
    <PaddedDialog {...rest} opened={!!data} title="デザインを保存">
      <Description>
        デザインを保存するためにはJOGGOアカウントが必要です。
      </Description>
      <Button
        colorTheme="blue"
        onClick={() => {
          if (!data) {
            return
          }

          onLogin(data)
        }}
      >
        ログイン画面へ
      </Button>
    </PaddedDialog>
  )
}

const PaddedDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gutter-medium);
  gap: var(--gutter-medium);
  width: 90vw;
  max-width: 300px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
`

const Button = styled(FloatingButton)`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
  padding: var(--gutter-small) var(--gutter-extra-large);
`
