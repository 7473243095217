import React, { FC, useMemo } from 'react'

import '~/assets/css/reset.css'
import '~/assets/css/PA1GothicStdN.css'
import '~/assets/css/vars.css'
import '~/assets/css/base.css'

import CustomizePageV2Container from '~/containers/CustomizePageContainer'

const App: FC = () => {
  return useMemo(() => {
    return <CustomizePageV2Container />
  }, [])
}

export default App
