import { useCallback, useState } from 'react'

interface UseDialogStateWithContextReturns<T extends Record<any, any>> {
  props: {
    data: T | null
    onClose(): void
  }

  isOpen: boolean

  open(data: T): void
  close(): void
}

interface UseDialogStateWithContextParams<T> {
  defaultValue?: T | null

  shouldClose?(): boolean
}

const shouldCloseDefault = () => true

/**
 * ダイアログを開いた時のコンテキストをダイアログ内に伝えつつ、ダイアログの開閉状態の管理を行う Hooks
 *
 * これを利用する場合はダイアログコンポーネントが `data` プロパティを実装している必要がある。
 */
export function useDialogStateWithContext<Context extends Record<any, any>>({
  defaultValue = null,
  shouldClose = shouldCloseDefault
}: UseDialogStateWithContextParams<Context> = {}): UseDialogStateWithContextReturns<Context> {
  const [data, setData] = useState<Context | null>(defaultValue)

  const open = useCallback((data: Context) => {
    setData(data)
  }, [])

  const close = useCallback(() => {
    if (shouldClose()) {
      setData(null)
    }
  }, [shouldClose])

  return {
    props: {
      data,
      onClose: close
    },
    isOpen: !!data,
    open,
    close
  }
}
