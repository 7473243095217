import { useEffect } from 'react'

import { useLocalCart } from '~/hooks/useLocalCart'
import { useMutation } from '~/hooks/useMutation'
import type { AnonymousSession } from '~/hooks/useSession'
import type { ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { Product } from '~/types/Product'
import { toRequestPayload } from '~/utils/customization'

interface MutationParams {
  customization: ReadyCustomization
  design: Design
  product: Product

  session: AnonymousSession
}

interface UseLocalCartItemCreationParams {
  onCreated?(index: number): void

  onError?(error: unknown, ctx: MutationParams): void
}

export function useLocalCartItemCreation({
  onCreated,
  onError
}: UseLocalCartItemCreationParams) {
  const localCart = useLocalCart()

  const mutation = useMutation(
    async ({ customization, design, product }: MutationParams) =>
      localCart.append(toRequestPayload(customization, design, product))
  )

  // ステータスコールバックを呼ぶ
  useEffect(() => {
    switch (mutation.status) {
      case 'success': {
        onCreated?.(mutation.data.index)
        return
      }
      case 'failed': {
        onError?.(mutation.error, mutation.params)
        return
      }
    }
  }, [mutation.status])

  return mutation
}
