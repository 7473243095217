import React, { FC } from 'react'

export const StampShadow: FC = () => {
  return (
    <>
      <g filter="url(#filter0_ii)">
        <path
          d="M0.892857 14.2856H13.3929V12.4999H0.892857V14.2856ZM11.6071 7.14271H9.75C9.29632 7.14271 8.92857 6.77496 8.92857 6.32128V6.05733C8.92857 5.29365 9.17634 4.56709 9.52735 3.8888C9.78153 3.39745 9.88728 2.81764 9.77986 2.20297C9.59096 1.12093 8.70648 0.230307 7.62416 0.0419691C5.93248 -0.252395 4.46429 1.04141 4.46429 2.67841C4.46429 3.0735 4.55134 3.44683 4.70675 3.78277C5.07868 4.58774 5.35714 5.43233 5.35714 6.31933V6.32128C5.35714 6.77496 4.9894 7.14271 4.53572 7.14271H2.67857C1.19922 7.14271 0 8.34193 0 9.82129V10.7141C0 11.2072 0.399833 11.607 0.892857 11.607H13.3929C13.8859 11.607 14.2857 11.2072 14.2857 10.7141V9.82129C14.2857 8.34193 13.0865 7.14271 11.6071 7.14271Z"
          fill="#F9F9F9"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii"
          x="-1"
          y="-1"
          width="16.2857"
          height="16.2856"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow"
            result="effect2_innerShadow"
          />
        </filter>
      </defs>
    </>
  )
}

export default StampShadow
