/** @jsx jsx */
import * as React from 'react'
import { jsx, css } from '@emotion/react'

import { pixelToPercent } from '~/utils/pixelToPercent'

interface Props {
  className?: string

  width: number

  height: number

  x: number

  y: number

  lebel?: number
}

export const EmbossSVG: React.FC<Props> = ({
  children,
  className,
  width,
  height,
  x,
  y,
  lebel = 10
}) => {
  const filterID = Math.random().toString(36).slice(-8)

  const BASE_IMAGE_SIZE = 1000
  const parcentX = pixelToPercent(x, BASE_IMAGE_SIZE)
  const parcentY = pixelToPercent(y, BASE_IMAGE_SIZE)
  const parcentWidth = pixelToPercent(width, BASE_IMAGE_SIZE)
  const parcentHeight = pixelToPercent(height, BASE_IMAGE_SIZE)

  return (
    <svg
      className={className}
      css={css({
        mixBlendMode: 'soft-light',
        position: 'absolute',
        top: `${parcentY}%`,
        left: `${parcentX}%`
      })}
      width={`${parcentWidth}%`}
      height={`${parcentHeight}%`}
      viewBox={`0 0 ${width} ${height}`}
      fill="#000"
      fillOpacity="0.4"
    >
      <filter id={filterID} filterUnits="userSpaceOnUse">
        {/* InnerShadow: black */}
        <feOffset dx="0" dy="1" />
        <feGaussianBlur stdDeviation="0" result="offset-blur" />
        <feComposite
          operator="out"
          in="SourceGraphic"
          in2="offset-blur"
          result="inverse"
        />
        <feFlood floodColor="rgba(0,0,0,0.1)" result="color" />
        <feComposite operator="in" in="color" in2="inverse" result="shadow" />
        <feComposite
          operator="over"
          in="shadow"
          in2="SourceGraphic"
          result="inset-shadow"
        />

        {/* DropShadow: white */}
        <feGaussianBlur in="SourceAlpha" stdDeviation="0.5" />
        <feOffset dx="0" dy="1" result="offsetblur" />
        <feFlood floodColor="rgba(255,255,255,1)" />
        <feComposite in2="offsetblur" operator="in" result="shadow-white" />

        <feMerge>
          <feMergeNode in="shadow-white" />
          {Array.from({ length: lebel }).map((_, i) => (
            <feMergeNode key={i} in="inset-shadow" />
          ))}
        </feMerge>
      </filter>
      <g filter={`url(#${filterID})`}>{children}</g>
    </svg>
  )
}
