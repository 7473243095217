import type { ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { Product } from '~/types/Product'
import { PrintType } from '~/types/PrintType'
import type { Request as RequestPayload } from '~/types/SavedDesign'

const printTypeMap = {
  [PrintType.Embossing]: 'stamp',
  [PrintType.GoldPressing]: 'gold_emboss',
  [PrintType.SilverPressing]: 'silver_emboss'
} as const

/**
 * カスタマイズ状態をAPIに投げるためのデータに変換する
 */
export function toRequestPayload(
  customization: ReadyCustomization,
  design: Design,
  product: Pick<Product, 'id' | 'version'>
): RequestPayload {
  return {
    product: {
      id: product.id,
      version: product.version
    },
    parts: Object.entries(customization.parts).map(([partId, { color }]) => ({
      part: { id: partId },
      color: { id: color.id }
    })),
    printLogo:
      design.printLogo && customization.printLogo
        ? {
            printType: printTypeMap[customization.printLogo.printType],
            logoType: design.printLogo.logoType
          }
        : undefined,
    printName: customization.printName
      ? {
          type: printTypeMap[customization.printName.printType],
          text: customization.printName.text
        }
      : undefined
  }
}
