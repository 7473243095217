import { useCallback, useState } from 'react'

import type { ShareDialogProps } from './ShareDialog'

interface ShareDialogController {
  open(data: Exclude<ShareDialogProps['shareData'], null>): void

  close(): void
}

export type UseShareDialogStateReturns = [
  Pick<ShareDialogProps, 'shareData' | 'onClose'>,
  ShareDialogController
]

export function useShareDialogState(): UseShareDialogStateReturns {
  const [shareData, setShareData] =
    useState<ShareDialogProps['shareData']>(null)

  const onClose = useCallback(() => {
    setShareData(null)
  }, [])

  const open = useCallback<ShareDialogController['open']>((shareData) => {
    setShareData(shareData)
  }, [])

  return [
    { shareData, onClose },
    { open, close: onClose }
  ]
}
