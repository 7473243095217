import {
  fromString as printTypeFromString,
  possiblePrintTypeToPrintType,
  printTypeToPossiblePrintType
} from '~/types/PrintType'
import type { Color } from '~/types/Color'
import type { Customization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { Product } from '~/types/Product'
import type {
  Response as SavedDesign,
  Request as RequestPayload
} from '~/types/SavedDesign'
import { convertResToStatePrintType } from '~/utils/convertPrintType'
import { isExpired } from '~/utils/isExpired'
import { validate as validatePrintName } from '~/utils/printName'

export { toRequestPayload } from './toRequestPayload'
export { parseReadyCustomization } from './parseReadyCustomization'

/**
 * 名入れ文字列が入力制約を満たしていない
 */
interface InvalidPrintNameText {
  type: 'invalid_print_name_text'
}

/**
 * 利用期間の過ぎた期間限定色が含まれていた
 */
interface ExpiredColor {
  type: 'expired_color'

  /**
   * 利用期間の過ぎた期間限定色
   */
  color: Color
}

/**
 * パーツに色が指定されているが、該当パーツの素材では使えない色だった
 */
interface ColorUnavailableToPart {
  type: 'color_unavailable_to_part'

  color: Color
}

/**
 * 存在しない色ID
 */
interface ColorDoesNotExist {
  type: 'color_does_not_exist'

  id: string
}

/**
 * 存在しないパーツ番号
 */
interface PartDoesNotExist {
  type: 'part_does_not_exist'

  part: { id: string } | { number: string }
}

/**
 * クエリパラメータの値がルールに合致しないものだった
 */
interface InvalidQueryParameter {
  type: 'invalid_query_parameter'
}

/**
 * 名入れの刻印種別がこの商品では使えないものだった
 */
interface InvalidPrintNameType {
  type: 'invalid_print_name_type'
}

/**
 * ロゴの刻印種別がこの商品では使えないものだった
 */
interface InvalidLogoType {
  type: 'invalid_logo_type'
}

export type CustomizationCreationWarning =
  | InvalidPrintNameText
  | ExpiredColor
  | ColorUnavailableToPart
  | ColorDoesNotExist
  | PartDoesNotExist
  | InvalidQueryParameter
  | InvalidPrintNameType
  | InvalidLogoType

/**
 * デザインからまっさらなカスタマイズ状態 (初期状態) を生成する。
 */
export function fromDesign(design: Design): Customization {
  return {
    parts: Object.fromEntries(design.parts.map((part) => [part.id, null])),
    printLogo:
      design.printLogo && design.printLogo.printTypes.length === 1
        ? {
            printType: possiblePrintTypeToPrintType(
              design.printLogo.printTypes[0]
            )
          }
        : null,
    printName: undefined
  }
}

/**
 * クエリパラメータの名入れ部分をパースする
 */
function parsePrintNameSearchParams(
  searchParams: URLSearchParams,
  design: Design
): [Customization['printName'], CustomizationCreationWarning[]] {
  if (!searchParams.has('nt') || !design.printName) {
    return [null, []]
  }

  const warnings: CustomizationCreationWarning[] = []

  const nt = printTypeFromString(searchParams.get('nt') ?? '')
  if (!nt) {
    warnings.push({ type: 'invalid_print_name_type' })
  }

  let printType = nt
    ? printTypeToPossiblePrintType(nt)
    : design.printName.printTypes[0]

  // 商品で利用可能な刻印種別かチェックする
  if (
    !design.printName.printTypes.some(
      (pt) => pt.printType === printType.printType
    )
  ) {
    warnings.push({ type: 'invalid_print_name_type' })
    printType = design.printName.printTypes[0]
  }

  let text = searchParams.get('nm') || ''

  // 名入れ文字列のバリデーション
  if (
    (text && !validatePrintName(text).ok) ||
    text.length > design.printName.maxLength
  ) {
    warnings.push({ type: 'invalid_print_name_text' })
    text = ''
  }

  return [
    {
      printType: possiblePrintTypeToPrintType(printType),
      text
    },
    warnings
  ]
}

/**
 * クエリパラメータのロゴ部分をパースする
 */
function parsePrintLogoSearchParams(
  searchParams: URLSearchParams,
  design: Design
): [Customization['printLogo'], CustomizationCreationWarning[]] {
  if (!searchParams.has('lt') || !design.printLogo) {
    return [null, []]
  }

  const lt = printTypeFromString(searchParams.get('lt') ?? '')
  if (!lt) {
    return [null, [{ type: 'invalid_logo_type' }]]
  }

  const ppt = printTypeToPossiblePrintType(lt)

  if (
    !design.printLogo.printTypes.some((pt) => pt.printType === ppt.printType)
  ) {
    return [null, [{ type: 'invalid_logo_type' }]]
  }

  return [{ printType: lt }, []]
}

/**
 * クエリパラメータのパーツ部分をパースする
 */
function parsePartSearchParams(
  searchParams: URLSearchParams,
  design: Design,
  colors: readonly Color[]
): [Customization['parts'], CustomizationCreationWarning[]] {
  if (!searchParams.has('p')) {
    return [{}, []]
  }

  const warnings: CustomizationCreationWarning[] = []

  const result: Customization['parts'] = {}

  // クエリパラメータのパーツ指定を回していく
  // `empty` には既に全てのパーツが空で初期化されているため、全てのパーツが列挙されていなくても問題ない
  for (const p of searchParams.getAll('p')) {
    const [partNumber, colorId] = p.split(':')

    // 書式がおかしければスキップ
    if (!partNumber || !colorId) {
      warnings.push({ type: 'invalid_query_parameter' })
      continue
    }

    // パーツ番号の合致するパーツがなければスキップ
    const part = design.parts.find((part) => part.number === partNumber)
    if (!part) {
      warnings.push({
        type: 'part_does_not_exist',
        part: { number: partNumber }
      })
      continue
    }

    // IDの合致する色がなければスキップ
    const color = colors.find((color) => color.id === colorId)
    if (!color) {
      warnings.push({ type: 'color_does_not_exist', id: colorId })
      continue
    }

    // 色の有効期限が切れていればスキップ
    if (color.endAt && isExpired(color.endAt)) {
      warnings.push({ type: 'expired_color', color })
      continue
    }

    // 色の素材とパーツの素材が異なる場合はスキップ
    if (part.material.id !== color.material.id) {
      warnings.push({ type: 'color_unavailable_to_part', color })
      continue
    }

    result[part.id] = { color }
  }

  return [result, warnings]
}

/**
 * クエリパラメータからカスタマイズ状態を生成する
 */
export function fromSearchParams(
  searchParams: URLSearchParams,
  { design, colors }: { design: Design; colors: readonly Color[] }
): [Customization, CustomizationCreationWarning[]] {
  const empty = fromDesign(design)

  const [printName, printNameWarnings] = parsePrintNameSearchParams(
    searchParams,
    design
  )

  const [printLogo, printLogoWarnings] = parsePrintLogoSearchParams(
    searchParams,
    design
  )

  const [parts, partsWarnings] = parsePartSearchParams(
    searchParams,
    design,
    colors
  )

  return [
    {
      parts: {
        ...empty.parts,
        ...parts
      },
      printLogo: printLogo ?? empty.printLogo,
      printName: printName ?? empty.printName
    },
    [...printNameWarnings, ...printLogoWarnings, ...partsWarnings]
  ]
}

/**
 * APIレスポンスでのカスタマイズデータからカスタマイズ状態を生成する
 */
export function fromSavedDesign(
  saved: Pick<SavedDesign, 'parts' | 'printLogo' | 'printName'>,
  { design, colors }: { design: Design; colors: readonly Color[] }
): [Customization, readonly CustomizationCreationWarning[]] {
  const customization = fromDesign(design)
  const warnings: CustomizationCreationWarning[] = []

  // パーツカスタマイズの復元
  saved.parts.forEach(({ part, color }) => {
    const matchingPart = design.parts.find((p) => p.id === part.id)
    if (!matchingPart) {
      warnings.push({ type: 'part_does_not_exist', part })
      return
    }

    const matchingColor = colors.find((c) => c.id === color.id)
    if (!matchingColor) {
      warnings.push({ type: 'color_does_not_exist', id: color.id })
      return
    }

    // 有効期限チェック
    if (matchingColor.endAt && isExpired(matchingColor.endAt)) {
      warnings.push({ type: 'expired_color', color: matchingColor })
      return
    }

    // 素材チェック
    if (matchingColor.material.id !== matchingPart.material.id) {
      warnings.push({ type: 'color_unavailable_to_part', color: matchingColor })
      return
    }

    customization.parts[part.id] = {
      color: matchingColor
    }
  })

  // 名入れの復元
  customization.printName =
    design.printName && saved.printName
      ? {
          printType: convertResToStatePrintType(saved.printName.type),
          text: saved.printName.text
        }
      : null

  // ロゴの復元
  customization.printLogo =
    design.printLogo && saved.printLogo
      ? {
          printType: convertResToStatePrintType(saved.printLogo.printType)
        }
      : null

  return [customization, warnings]
}

/**
 * APIにリクエストするカスタマイズデータからフロント表現のカスタマイズデータを生成する
 */
export function fromRequestPayload(
  payload: RequestPayload,
  { design, colors }: Parameters<typeof fromSavedDesign>[1]
): ReturnType<typeof fromSavedDesign> {
  const parts =
    'parts' in payload
      ? payload.parts
      : payload.views
          .map((view) => view.areas)
          .flat()
          .filter(
            (a, i, list) => list.findIndex((b) => a.part.id === b.part.id) === i
          )

  return fromSavedDesign(
    {
      ...payload,
      parts
    },
    { design, colors }
  )
}

/**
 * シェア用のURLを生成する
 *
 * シミュレータ単体ではなく、商品詳細ページのURLをシェアする。
 *
 * https://joggo-docs.netlify.app/designs/urls.html#%E3%83%9B%E3%82%9A%E3%83%BC%E3%82%BF%E3%83%AB
 */
export function toShareUrl(
  customization: Customization,
  {
    design,
    product,
    baseUrl = location.href
  }: {
    product: Pick<Product, 'id' | 'version'>
    design: Pick<Design, 'parts'>
    baseUrl?: URL | string
  }
): URL {
  const searchParams = new URLSearchParams([['v', product.version]])

  Object.entries(customization.parts).forEach(([partId, value]) => {
    const part = design.parts.find((p) => p.id === partId)
    if (!part || !value) {
      return
    }

    searchParams.append('p', `${part.number}:${value.color.id}`)
  })

  if (customization.printLogo) {
    searchParams.set('lt', customization.printLogo.printType)
  }

  if (customization.printName && customization.printName.printType !== null) {
    searchParams.set('nt', customization.printName.printType)
  }

  const url = new URL(`products/detail/${product.id}/`, baseUrl)

  url.search = searchParams.toString()

  return url
}
