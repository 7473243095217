import styled from '@emotion/styled/macro'
import * as React from 'react'

import { Dialog } from '~/components/atoms/Dialog'
import Icon from '~/components/atoms/Icon'
import OutlineButton from '~/components/atoms/OutlineButton'
import { useToast } from '~/hooks/useToast'

function openPopup(url: URL, name: string) {
  window.open(
    url.toString(),
    name,
    'menubar=no,toolbar=no,location=no,noopener'
  )
}

export interface ShareDialogProps {
  className?: string

  shareData: {
    url: URL | string
    title: string
  } | null

  onClose(): void
}

/**
 * Web Share APIが使えないプラットフォーム向けのシェアダイアログ
 *
 * `useShareDialogState` を使って状態管理をすることを推奨。
 *
 * @example
 * import { ShareDialog, useShareDialogState } from "~/components/organisms/ShareDialog";
 *
 * const [shareDialogProps, shareDialog] = useShareDialogState();
 *
 * return <>
 *   <button onClick={() => shareDialog.open({ url: location.href, title: "SAMPLE" })}>
 *     Open
 *   </button>
 *   <ShareDialog
 *     {...shareDialogProps}
 *   />
 * </>
 */
export const ShareDialog = ({ shareData, ...rest }: ShareDialogProps) => {
  const toast = useToast()

  return (
    <PaddedDialog {...rest} opened={!!shareData} title="デザインをシェア">
      <Description>あなたのデザインを友人にシェアしましょう。</Description>
      <List>
        <li>
          <OutlineButton
            shape="circle"
            label="デザインをツイート"
            onClick={() => {
              if (!shareData) {
                return
              }

              const url = new URL('https://twitter.com/intent/tweet')

              url.searchParams.set('url', shareData.url.toString())
              url.searchParams.set('text', shareData.title)
              url.searchParams.set('hashtags', 'JOGGO')

              openPopup(url, 'Twitter')
            }}
            id="share_twitter"
          >
            <Icon type="twitter" />
          </OutlineButton>
        </li>
        <li>
          <OutlineButton
            shape="circle"
            label="デザインをFacebookでシェア"
            onClick={() => {
              if (!shareData) {
                return
              }

              const url = new URL('https://www.facebook.com/sharer/sharer.php')

              url.searchParams.set('u', shareData.url.toString())

              openPopup(url, 'Facebook')
            }}
            id="share_facebook"
          >
            <Icon type="facebook" />
          </OutlineButton>
        </li>
        <li>
          <OutlineButton
            shape="circle"
            label="デザインをLINEでシェア"
            onClick={() => {
              if (!shareData) {
                return
              }

              const url = new URL('https://social-plugins.line.me/lineit/share')

              url.searchParams.set('url', shareData.url.toString())

              openPopup(url, 'LINE')
            }}
            id="share_line"
          >
            <Icon type="line" />
          </OutlineButton>
        </li>
        <li>
          <OutlineButton
            shape="circle"
            label="このデザインのURLをコピー"
            onClick={() => {
              if (!shareData || !navigator.clipboard) {
                return
              }

              navigator.clipboard
                .writeText(shareData.url.toString())
                .then(() => {
                  toast.show({
                    variant: 'info',
                    title: 'クリップボードにコピーしました'
                  })
                })
                .catch(() => {
                  toast.show({
                    variant: 'error',
                    title: 'クリップボードにコピーできませんでした'
                  })
                })
            }}
            id="share_url-copy"
          >
            <Icon type="copy" />
          </OutlineButton>
        </li>
      </List>
    </PaddedDialog>
  )
}

const PaddedDialog = styled(Dialog)`
  padding: var(--gutter-medium);
  max-width: 300px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
`

const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gutter-medium);
  margin-top: var(--gutter-medium);

  font-size: 24px;
`
