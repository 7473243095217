import {
  createContext,
  Fragment,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import * as React from 'react'
import { useMedia } from 'use-media'

import {
  Toast,
  ToastProps,
  ToastClose,
  ToastTitle,
  ToastDescription
} from '~/components/molecules/Toast'

interface ToastMessage {
  id: number

  title: Exclude<ReactNode, null | undefined | false | ''>

  description?: ReactNode

  variant?: ToastProps['variant']
}

type HideFn = () => void

interface ContextValue {
  show(message: Omit<ToastMessage, 'id'>): HideFn
}

const Context = createContext<ContextValue>({
  show() {
    return () => {}
  }
})

export const useImperativeToast = () => useContext(Context)

interface ImperativeToastProviderProps {
  children: ReactNode
}

/**
 * トーストUIを手続き的に表示するための状態管理プロバイダ
 *
 * `ToastProvider` と `ToastViewport` は利用側で設定すること。
 */
export const ImperativeToastProvider = ({
  children
}: ImperativeToastProviderProps) => {
  const [messages, setMessages] = useState<readonly ToastMessage[]>([])
  const counter = useRef(0)

  const isReducedMotion = useMedia('(prefers-reduced-motion: reduce)')
  const toastRefs = useRef<Record<string, HTMLLIElement | null>>({})
  const boundingRects = useRef<Record<string, DOMRect>>({})

  useLayoutEffect(() => {
    if (!messages.length || isReducedMotion) {
      return
    }

    const firstAndLast = Object.entries(boundingRects.current)
      .map(([id, prev]) => {
        const el = toastRefs.current[id]
        if (!el) {
          return null
        }

        return { el, prev, next: el.getBoundingClientRect() }
      })
      .filter((result): result is NonNullable<typeof result> => !!result)

    firstAndLast.forEach(({ el, prev, next }) => {
      el.animate(
        [
          { transform: `translateY(${prev.y - next.y}px)` },
          { transfrom: `translateY(0)` }
        ],
        { duration: 80, easing: 'linear' }
      )
    })

    boundingRects.current = {}
  }, [messages])

  const value = useMemo<ContextValue>(() => {
    return {
      show(opts) {
        const message: ToastMessage = { ...opts, id: counter.current++ }

        setMessages((prev) => [...prev, message])

        if (!isReducedMotion) {
          // 追加する前の要素のレイアウトを記憶しておく
          for (const [id, el] of Object.entries(toastRefs.current)) {
            if (!el) {
              continue
            }

            boundingRects.current[id] = el.getBoundingClientRect()
          }
        }

        return () => {
          setMessages((prev) => prev.filter((m) => m !== message))
        }
      }
    }
  }, [setMessages])

  return (
    <Context.Provider value={value}>
      {children}
      <Fragment>
        {messages.map((message) => (
          <Toast
            ref={(el) => {
              toastRefs.current[message.id] = el
            }}
            key={message.id}
            variant={message.variant}
            onClose={() => {
              if (!isReducedMotion) {
                // 削除する前の要素のレイアウトを記憶しておく
                for (const [id, el] of Object.entries(toastRefs.current)) {
                  if (!el) {
                    continue
                  }

                  boundingRects.current[id] = el.getBoundingClientRect()
                }
              }

              setMessages((prev) => prev.filter((m) => m !== message))
            }}
          >
            <ToastTitle>{message.title}</ToastTitle>
            {message.description && (
              <ToastDescription>{message.description}</ToastDescription>
            )}
            <ToastClose />
          </Toast>
        ))}
      </Fragment>
    </Context.Provider>
  )
}
