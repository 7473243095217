import styled from '@emotion/styled/macro'
import { RadioGroup } from '@radix-ui/react-radio-group'

export const RadioButtonGroup = styled(RadioGroup)`
  display: inline-flex;
  align-items: stretch;
  max-width: 100%;

  border-radius: 24px;
`
