import { DependencyList, useCallback, useEffect, useState } from 'react'

type AsyncResult<T, E> =
  | { status: 'idle' }
  | { status: 'loading' }
  | { status: 'error'; error: E; refetch(): void }
  | { status: 'ok'; result: T; refetch(): void }

/**
 * 非同期関数を実行し、その状態・結果を返す
 *
 * @param fn - 実行する非同期関数
 * @param deps - 依存リスト、これに変更がある都度 `fn` が再実行される
 */
export function useAsyncResult<T, E = unknown>(
  fn: (signal: AbortSignal) => Promise<T>,
  deps: DependencyList
): AsyncResult<T, E> {
  const [state, setState] = useState<AsyncResult<T, E>>({
    status: 'idle'
  })

  const [retryTs, setRetryTs] = useState<number>(0)

  const refetch = useCallback(() => {
    setRetryTs(Date.now())
  }, [])

  useEffect(() => {
    setState({ status: 'loading' })

    const abortController = new AbortController()

    fn(abortController.signal)
      .then((result) => {
        setState({ status: 'ok', result, refetch })
      })
      .catch((error) => {
        if (error instanceof DOMException && error.name === 'AbortError') {
          setState({ status: 'idle' })
          return
        }

        setState({ status: 'error', error, refetch })
      })

    return () => {
      abortController.abort()
    }
  }, [retryTs, ...deps])

  return state
}
