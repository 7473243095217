import { useEffect } from 'react'

import { registerMyDesign } from '~/api/myDesign'
import { useMutation } from '~/hooks/useMutation'
import type { LoginSession } from '~/hooks/useSession'
import { useToast } from '~/hooks/useToast'
import type { ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { MyDesign } from '~/types/MyDesign'
import type { Product } from '~/types/Product'
import { toRequestPayload } from '~/utils/customization'

interface Params {
  customization: ReadyCustomization
  design: Design
  product: Product

  session: LoginSession
}

export function useMyDesignCreation({
  onCreated
}: {
  onCreated?(myDesign: MyDesign): void
} = {}) {
  const toast = useToast()

  const mutation = useMutation(
    async ({ customization, design, product, session }: Params) =>
      registerMyDesign(
        toRequestPayload(customization, design, product),
        session.accessToken.token
      )
  )

  // マイデザイン新規保存が成功・失敗したら、その結果に応じた処理を行う
  useEffect(() => {
    switch (mutation.status) {
      case 'success': {
        onCreated?.(mutation.data)
        toast.show({
          variant: 'success',
          title: 'マイデザインに保存しました'
        })
        return
      }
      case 'failed': {
        toast.show({
          variant: 'error',
          title: 'マイデザインへの保存に失敗しました',
          description:
            mutation.error instanceof Error
              ? mutation.error.message
              : String(mutation.error)
        })
        return
      }
    }
  }, [mutation.status])

  return mutation
}
