import StatePrintType from '~/types/PrintType'
import { PrintType as ResponsePrintType } from '~/types/SavedDesign/PrintType'

/**
 * レスポンスとして返却される刻印タイプを
 * アプリケーション内で使用する値にコンバートする
 * @param {PrintType} type APIのレスポンスとして返却される刻印の種類
 */
export const convertResToStatePrintType = (
  type: ResponsePrintType
): StatePrintType => {
  switch (type) {
    case 'stamp':
      return StatePrintType.Embossing
    case 'gold_emboss':
      return StatePrintType.GoldPressing
    case 'silver_emboss':
      return StatePrintType.SilverPressing
    default:
      return StatePrintType.GoldPressing
  }
}

/**
 * アプリケーション内で使用している刻印タイプを
 * APIのリクエストとして使用する値にコンバートする
 * @param type アプリケーション内で使用している刻印の種類
 */
export const convertStateToReqPrintType = (
  type?: StatePrintType
): ResponsePrintType => {
  switch (type) {
    case StatePrintType.Embossing:
      return 'stamp'
    case StatePrintType.GoldPressing:
      return 'gold_emboss'
    case StatePrintType.SilverPressing:
      return 'silver_emboss'
    default:
      return 'gold_emboss'
  }
}

/**
 * レスポンスとして返却される刻印タイプを参照し、
 * UI上に表示する名前に変換する
 */
export const convertResToPrintName = (type: ResponsePrintType) => {
  switch (type) {
    case 'stamp':
      return '型押し'
    case 'gold_emboss':
      return 'ゴールド'
    case 'silver_emboss':
      return 'シルバー'
  }
}
