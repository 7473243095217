import * as React from 'react'
import { Fragment } from 'react'

const Loupe = () => (
  <Fragment>
    <g clipPath="url(#clip0_224_2519)">
      <path d="M15.8537 15.0405L11.8284 11.0151C11.766 10.9527 11.6724 10.9215 11.5787 10.9215H11.2355C12.3276 9.73574 12.9829 8.20673 12.9829 6.49049C12.9829 2.9332 10.0497 0 6.49245 0C2.90395 0 0.00195312 2.9332 0.00195312 6.49049C0.00195312 10.079 2.90395 12.981 6.49245 12.981C8.17748 12.981 9.73769 12.3257 10.8923 11.2647V11.5768C10.8923 11.7016 10.9235 11.7952 10.9859 11.8576L15.0112 15.883C15.1672 16.039 15.3857 16.039 15.5417 15.883L15.8537 15.5709C16.0098 15.4149 16.0098 15.1965 15.8537 15.0405ZM6.49245 11.9824C3.43443 11.9824 1.00049 9.54851 1.00049 6.49049C1.00049 3.46368 3.43443 0.998537 6.49245 0.998537C9.51926 0.998537 11.9844 3.46368 11.9844 6.49049C11.9844 9.54851 9.51926 11.9824 6.49245 11.9824Z" />
    </g>
    <defs>
      <clipPath id="clip0_224_2519">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Fragment>
)

export default Loupe
