import { Request } from '~/types/SavedDesign'
import Cart from '~/types/Cart'
import { apiURL, HttpStatusError, AuthError } from '../common'

/**
 * カート内アイテムの取得
 * @param {String} id カート内アイテムを識別するID
 * @param {String} token 認証トークン
 */
export const fetchCartItem = async (
  id: string,
  token: string
): Promise<Cart> => {
  const res = await fetch(`${apiURL}/cart/items/${id}`, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (res.status === 401) {
    throw new AuthError()
  }

  if (res.status !== 200) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}

/**
 * カートに追加
 * @param {Request} design デザイン情報
 * @param {String} token 認証トークン
 */
export const registerCart = async (
  design: Request,
  token: string
): Promise<Cart> => {
  const res = await fetch(`${apiURL}/cart/items`, {
    method: 'POST',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(design)
  })

  if (res.status === 401) {
    throw new AuthError()
  }

  if (res.status !== 201) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}

/**
 * カート内アイテムを編集する
 * @param {String} id カート内アイテムを識別するID
 * @param {Request} design デザイン情報
 * @param {String} token 認証トークン
 */
export const updateCart = async (
  id: string,
  design: Request,
  token: string
): Promise<string> => {
  const res = await fetch(`${apiURL}/cart/items/${id}`, {
    method: 'PUT',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(design)
  })

  if (res.status === 401) {
    throw new AuthError()
  }

  if (res.status !== 204) {
    throw new HttpStatusError(res.status)
  }

  return res.text()
}
