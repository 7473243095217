import { useEffect, useState } from 'react'

import { getToken, setToken } from '~/utils/cookie'

export interface AccessToken {
  readonly token: string
  readonly persist: boolean
}

export interface LoginSession {
  readonly isLoggedIn: true
  readonly accessToken: AccessToken
}

export interface AnonymousSession {
  readonly isLoggedIn: false
}

export type Session = AnonymousSession | LoginSession

export function useSession(): Session {
  const [session] = useState<Session>(() => {
    const token = getToken()

    return token
      ? {
          isLoggedIn: true,
          accessToken: token
        }
      : {
          isLoggedIn: false
        }
  })

  // トークン利用時にトークンを同じ内容で上書きし、延命させる
  useEffect(() => {
    if (!session.isLoggedIn) {
      return
    }

    setToken(session.accessToken.token, session.accessToken.persist)
  }, [session.isLoggedIn && session.accessToken])

  return session
}
