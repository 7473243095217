/** @jsx jsx */
import { FC, ReactNode } from 'react'
import { jsx } from '@emotion/react'
import styled from '@emotion/styled/macro'

import { mqHorizontal } from '~/utils/mediaQuery'

interface SimpleTwoSectionLayoutProps {
  /**
   * 操作要素たち
   *
   * コンテナには `position: relative` が設定されている。
   */
  controls: ReactNode

  /**
   * カスタマイズプレビュー領域
   *
   * コンテナには `position: relative` が設定されている。
   */
  preview: ReactNode
}

export const SimpleTwoSectionLayout: FC<SimpleTwoSectionLayoutProps> = ({
  controls,
  preview
}) => {
  return (
    <Container>
      <Preview>{preview}</Preview>
      <Controls>{controls}</Controls>
    </Container>
  )
}

export const Container = styled.main`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: minmax(0, 1fr) max-content;
  grid-template-areas:
    'preview'
    'controls';

  ${mqHorizontal} {
    grid-template-columns: minmax(0, 1fr) 320px;
    grid-template-rows: 100%;
    grid-template-areas: 'preview controls';
  }
`

export const Preview = styled.div`
  grid-area: preview;
  position: relative;

  background-color: var(--gray-200);
`

export const Controls = styled.div`
  --_shadow-colour: hsla(0, 0%, 0%, 0.16);

  grid-area: controls;
  position: relative;

  background-color: var(--gray-100);
  box-shadow: 0 0 4px var(--_shadow-colour);
`
