import { useLayoutEffect, useState } from 'react'

import { FLIP, useFLIPAnimation } from '~/providers/FLIPAnimationProvider'

export interface UseFLIPParams<
  Props extends Record<string, unknown>,
  First,
  Last,
  Invert
> {
  /**
   * 変更の契機となる Props
   */
  props: Props

  /**
   * FLIPの各ステップ
   */
  flip: FLIP<First, Last, Invert>
}

/**
 * FLIP[^0]を使ったアニメーションを実装するためのヘルパ Hook
 *
 * [^0]: https://aerotwist.com/blog/flip-your-animations/
 *
 * @returns 引数オブジェクトの `props` (FLIPでの読み取りのために変更は1フレーム遅延される)
 *
 * @example
 * const Button = styled.button`
 *   margin-left: 0;
 *
 *   &[data-active='true'] {
 *     margin-left: 10px;
 *   }
 * `
 *
 * const ref = useRef<HTMLDivElement>(null)
 *
 * const props = useFLIP({
 *   props: { "data-active": active },
 *
 *   flip: {
 *     first() {
 *       return ref.current!.getBoundingBox();
 *     },
 *     last() {
 *       return ref.current!.getBoundingBox();
 *     },
 *     invert({ first, last }) {
 *       return { tx: first.x - last.x };
 *     },
 *     play({ invert: { tx }}) {
 *       ref.current!.animate(
 *         [
 *           { transform: `translateX(${tx}px)` },
 *           { transform: `translateX(0px)` }
 *         ],
 *         { duration: 200, easing: 'ease' }
 *       );
 *     }
 *   }
 * })
 *
 * <Button {...props} />
 */
export function useFLIP<
  Props extends Record<string, unknown>,
  First,
  Last,
  Invert
>({ props, flip }: UseFLIPParams<Props, First, Last, Invert>): Props {
  // パフォーマンスとアニメーション同期のため、レイアウトの読み取りや変更はバッチでまとめて実行する
  const flipCtx = useFLIPAnimation()

  const [propsToReturn, setPropsToReturn] = useState(() => props)

  const deps = Object.values(props)

  useLayoutEffect(() => {
    // 初回は何もしない
    if (props === propsToReturn) {
      return
    }

    // 無駄なレンダリングを防ぐために即時で First は実行する
    flipCtx.queue({
      first: flip.first(),
      flip
    })

    // Props を更新してレンダリングをスケジュールする
    setPropsToReturn(props)
  }, deps)

  return propsToReturn
}
