import styled from '@emotion/styled/macro'
import { RadioGroupItem } from '@radix-ui/react-radio-group'

export const RadioButton = styled(RadioGroupItem)`
  --_padding-h: var(--radio-button--padding-h, var(--gutter-medium));

  flex: 1;
  width: 84px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gutter-extra-small) var(--_padding-h);
  border: 1px solid var(--gray-700);
  font-size: var(--primary-font-size);
  line-height: 1.33;

  background-color: var(--gray-0);
  cursor: pointer;

  & + & {
    margin-left: -1px;
  }

  &:hover,
  &:focus-visible {
    border-color: var(--primary-color);
  }

  &:hover + &[data-state='unchecked'],
  &:focus-visible + &[data-state='unchecked'] {
    border-left-color: var(--primary-color);
  }

  &[data-state='checked'] {
    border-color: var(--link-color);
    box-shadow: inset 0 0 0 2px var(--link-color);
  }
  &[data-state='checked'] + & {
    // 重ねてボーダーの重複を防いでいるため、隣接した要素のボーダーが上に載っている
    border-left-color: var(--link-color);
  }
  &[data-state='checked']:focus-visible {
    color: var(--primary-color);
  }

  &:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &:last-child {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
`
