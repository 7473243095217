/** @jsx jsx */
import * as React from 'react'
import styled from '@emotion/styled/macro'
import { css, jsx } from '@emotion/react'

// Props
export interface Props {
  id?: string
  className?: string
  label?: string

  /**
   * ボタンの形状
   * @default rounded
   */
  shape?: 'rounded' | 'circle'

  /**
   * カラーテーマ
   * @default white
   */
  colorTheme?: 'white' | 'blue' | 'blue-outline'

  /**
   * ボタンが有効かどうか
   * @default false
   */
  disabled?: boolean

  /** クリックイベント */
  onClick?: () => any
}

// JSX
/**
 * 汎用的に使用するアウトラインスタイルのボタン
 */
export const OutlineButton: React.FC<Props> = ({
  id,
  children,
  label,
  className,
  shape = 'rounded',
  colorTheme = 'white',
  disabled,
  onClick
}) => {
  const StyledButton = shape === 'rounded' ? Rounded : Circle

  const color = () => {
    switch (colorTheme) {
      case 'blue':
        return 'var(--white)'
      case 'white':
        return 'var(--black)'
      case 'blue-outline':
        return 'var(--primary-color-dark)'
    }
  }

  const background = () => {
    switch (colorTheme) {
      case 'blue':
        return 'var(--primary-color)'
      case 'white':
      case 'blue-outline':
        return 'var(--white)'
    }
  }

  const borderColor = () => {
    switch (colorTheme) {
      case 'blue':
        return 'transparent'
      case 'white':
        return '#cfcfcf'
      case 'blue-outline':
        return 'var(--primary-color)'
    }
  }

  const backgroundHover = () => {
    switch (colorTheme) {
      case 'blue':
        return 'var(--primary-color-dark)'
      case 'white':
        return '#ededed'
      case 'blue-outline':
        return '#ededed'
    }
  }

  return (
    <StyledButton
      id={id}
      className={className}
      css={css(base, {
        color: color(),
        background: background(),
        borderColor: borderColor(),
        '&:hover': {
          background: backgroundHover()
        }
      })}
      title={label}
      aria-label={label}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

export default OutlineButton

const base = css`
  appearance: none;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  text-align: center;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
`

const Rounded = styled.button`
  display: inline-block;
  padding: var(--gutter-medium);
  border-radius: 50px;
`

const Circle = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`
