import * as React from 'react'
import { useEffect, useState } from 'react'

import { useBootstrapMode } from '~/hooks/useBootstrapMode'

import { CartItemUpdate } from './CartItemUpdate'
import { ItemCreation } from './ItemCreation'
import { InvalidBootstrapMode } from './InvalidBootstrapMode'
import { LocalCartItemUpdate } from './LocalCartItemUpdate'
import { MyDesignItemUpdate } from './MyDesignItemUpdate'
import { ValidAccessTokenGuard } from './ValidAccessTokenGuard'

const CustomizePage = () => {
  const [url, setUrl] = useState(() => location.href)

  // カート保存時にURLを書き換えているが、 bfcache が使われてしまった場合は
  // URLはカート編集のものだがアプリケーションの状態は別のものとなってしまい、
  // 最後のボタンも `カートに追加` になってしまう。URLとアプリケーションの
  // 整合性をとるために bfcache で復帰した場合はモードを再取得する。
  useEffect(() => {
    const listener = (ev: PageTransitionEvent) => {
      if (!ev.persisted) {
        return
      }

      setUrl(location.href)
    }

    window.addEventListener('pageshow', listener)

    return () => {
      window.removeEventListener('pageshow', listener)
    }
  }, [])

  const mode = useBootstrapMode(url)

  switch (mode.type) {
    case 'item_creation':
      return (
        <ValidAccessTokenGuard>
          <ItemCreation mode={mode} />
        </ValidAccessTokenGuard>
      )
    case 'invalid_bootstrap_mode':
      return (
        <ValidAccessTokenGuard>
          <InvalidBootstrapMode />
        </ValidAccessTokenGuard>
      )
    case 'my_design_item_update':
      return <MyDesignItemUpdate mode={mode} />
    case 'cart_item_update':
      return <CartItemUpdate mode={mode} />
    case 'local_cart_item_update':
      return (
        <ValidAccessTokenGuard>
          <LocalCartItemUpdate mode={mode} />
        </ValidAccessTokenGuard>
      )
  }
}

export default CustomizePage
