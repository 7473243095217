import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import * as React from 'react'
import * as zod from 'zod'

import { useFrameMessageDispatcher } from '~/hooks/useFrameMessageDispatcher'

const incomingMessageSchema = zod.object({
  type: zod.literal('notify_current_url'),
  url: zod.string().url()
})

export type IncomingMessage = zod.infer<typeof incomingMessageSchema>

interface ContextValue {
  readonly messages: readonly IncomingMessage[]
}

const context = createContext<ContextValue>({
  messages: []
})

export function useFrameMessageReceiver() {
  return useContext(context)
}

interface FrameMessageReceiverProviderProps {
  trustedOrigins: readonly string[]

  children: ReactNode
}

export const FrameMessageReceiverProvider = ({
  trustedOrigins,
  children
}: FrameMessageReceiverProviderProps) => {
  const dispatch = useFrameMessageDispatcher()

  const [messages, setMessages] = useState<IncomingMessage[]>([])

  useEffect(() => {
    // 埋め込まれていない場合は何もしない
    if (window.location === window.parent.location) {
      console.debug(
        'スタンドアロンで起動したためメッセージ受信処理をスキップします'
      )
      return
    }

    // メッセージ発行元をチェックせずにメッセージを処理するのはセキュリティとしてアウトなため、
    // 信頼するオリジンが指定されていない場合は何もしない
    if (!trustedOrigins.length) {
      console.debug(
        '信頼するオリジンの指定がないためメッセージ受信処理をスキップします'
      )
      return
    }

    const onMessage = (ev: MessageEvent) => {
      if (!trustedOrigins.includes(ev.origin)) {
        console.warn('信頼できないオリジンからのメッセージを受信しました')
        if (process.env.NODE_ENV === 'development') {
          console.groupCollapsed(
            '[DEV] 信頼できないオリジンからのメッセージ 📥'
          )
          console.log('🛠️ 許可オリジン一覧')
          console.log(trustedOrigins)
          console.log('📝 送信元オリジン')
          console.log(ev.origin)
          console.log('📦 メッセージペイロード')
          console.log(ev.data)
          console.groupEnd()
        }
        return
      }

      const message = incomingMessageSchema.safeParse(ev.data)
      if (!message.success) {
        console.warn('スキーマ違反のメッセージを受信したためスキップします')
        if (process.env.NODE_ENV === 'development') {
          console.groupCollapsed(
            '[DEV] スキーマ違反のフレーム間メッセージ (受信) 📥'
          )
          console.log('📦 メッセージペイロード')
          console.log(ev.data)
          console.log('⚠️ スキーマ検査エラー')
          console.warn(message.error)
          console.groupEnd()
        }
        return
      }

      if (process.env.NODE_ENV === 'development') {
        console.groupCollapsed('[DEV] フレーム間メッセージを受信 📥')
        console.log('📦 メッセージペイロード')
        console.log(message.data)
        console.log('📝 送信元オリジン')
        console.log(ev.origin)
        console.groupEnd()
      }

      setMessages((prev) => [...prev, message.data])
    }

    window.addEventListener('message', onMessage)

    trustedOrigins.forEach((origin) => {
      dispatch(
        {
          type: 'message_receiver_ready'
        },
        origin
      )
    })

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [trustedOrigins])

  const value = useMemo<ContextValue>(() => {
    return { messages }
  }, [messages])

  return <context.Provider value={value}>{children}</context.Provider>
}
