/** @jsx jsx */
import * as React from 'react'
import { jsx, css } from '@emotion/react'

import { pixelToPercent } from '~/utils/pixelToPercent'

interface Props {
  className?: string

  color: 'gold' | 'silver'

  width: number

  height: number

  x: number

  y: number

  lebel?: number
}

export const ColoredSVG: React.FC<Props> = ({
  children,
  className,
  color,
  width,
  height,
  x,
  y,
  lebel = 2
}) => {
  const filterID = Math.random().toString(36).slice(-8)
  const goldID = `gold_${filterID}`
  const silverID = `silver_${filterID}`

  const BASE_IMAGE_SIZE = 1000
  const parcentX = pixelToPercent(x, BASE_IMAGE_SIZE)
  const parcentY = pixelToPercent(y, BASE_IMAGE_SIZE)
  const parcentWidth = pixelToPercent(width, BASE_IMAGE_SIZE)
  const parcentHeight = pixelToPercent(height, BASE_IMAGE_SIZE)

  return (
    <svg
      className={className}
      css={css({
        position: 'absolute',
        top: `${parcentY}%`,
        left: `${parcentX}%`
      })}
      width={`${parcentWidth}%`}
      height={`${parcentHeight}%`}
      viewBox={`0 0 ${width} ${height}`}
      fill={`url(#${color === 'gold' ? goldID : silverID})`}
    >
      {/* Gradient: Gold */}
      <linearGradient id={goldID}>
        <stop offset="0%" stopColor="#FFCB5A" />
        <stop offset="50%" stopColor="#FFF19E" />
        <stop offset="100%" stopColor="#FFCB5A" />
      </linearGradient>

      {/* Gradient: Silver */}
      <linearGradient id={silverID}>
        <stop offset="0%" stopColor="#BFBFBE" />
        <stop offset="50%" stopColor="#FBFBFB" />
        <stop offset="100%" stopColor="#BFBFBE" />
      </linearGradient>

      <filter id={filterID} filterUnits="userSpaceOnUse">
        {/* InnerShadow: black */}
        <feOffset dx="0" dy="1" />
        <feGaussianBlur stdDeviation="0" result="offset-blur" />
        <feComposite
          operator="out"
          in="SourceGraphic"
          in2="offset-blur"
          result="inverse"
        />
        <feFlood floodColor="rgba(0,0,0,0.6)" result="color" />
        <feComposite operator="in" in="color" in2="inverse" result="shadow" />
        <feComposite
          operator="over"
          in="shadow"
          in2="SourceGraphic"
          result="inset-shadow"
        />

        {/* DropShadow: white */}
        <feGaussianBlur in="SourceAlpha" stdDeviation="0" />
        <feOffset dx="0" dy="0" result="offsetblur" />
        <feFlood floodColor="rgba(0,0,0,0.3)" />
        <feComposite in2="offsetblur" operator="in" />

        <feMerge>
          {Array.from({ length: lebel }).map((_, i) => (
            <feMergeNode key={i} />
          ))}
          {Array.from({ length: lebel }).map((_, i) => (
            <feMergeNode key={i} in="inset-shadow" />
          ))}
        </feMerge>
      </filter>
      <g filter={`url(#${filterID})`}>{children}</g>
    </svg>
  )
}
