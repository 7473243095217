import Material from '~/types/Material'

/** カラーの型定義 */
export interface Color {
  /** カラーを識別するID */
  id: string

  /** カラーの種別 */
  type: Type

  /** カラーの名称 */
  name: string

  /** カラーコード */
  colorCode: string

  /** 明度の割合 */
  brightness: number

  /** 素材情報 */
  material: Material

  /** 期間限定の終了日時 */
  endAt?: string
}

export default Color

export enum Type {
  Basic = 'basic',
  Limited = 'limited'
}
