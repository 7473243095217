import styled from '@emotion/styled/macro'
import * as React from 'react'

import { Dialog } from '~/components/atoms/Dialog'
import { FloatingButton } from '~/components/atoms/FloatingButton'

export { useDialogStateWithContext as useLocalCartLimitDialogState } from '~/components/atoms/Dialog'

interface LocalCartLimitDialogProps<T extends Record<any, any>> {
  data: T | null

  onClose(): void

  onLogin(data: T): void
}

export function LocalCartLimitDialog<T extends Record<any, any>>({
  data,
  onLogin,
  ...rest
}: LocalCartLimitDialogProps<T>) {
  return (
    <PaddedDialog {...rest} opened={!!data} title="カート容量上限のお知らせ">
      <Description>
        ログインするとカートに保存できる商品の数を増やせます。
        <br />
        複数デザインの比較に便利ですので、ログインしてのお買い物をおすすめします。
      </Description>
      <Button
        id="local-cart-limit_transition-login"
        colorTheme="blue"
        onClick={() => {
          if (data) {
            onLogin(data)
          }
        }}
      >
        ログイン画面へ
      </Button>
    </PaddedDialog>
  )
}

const PaddedDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gutter-medium);
  gap: var(--gutter-medium);
  width: 90vw;
  max-width: 320px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
`

const Button = styled(FloatingButton)`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
  padding: var(--gutter-small) var(--gutter-extra-large);
`
