import { useEffect } from 'react'

interface RedirectProps {
  to: URL | string
}

/**
 * ページ遷移するコンポーネント
 *
 * 何もレンダリングしない。
 * あくまでもリダイレクトであるため、ユーザの操作による遷移には使わないこと。
 */
export const Redirect = ({ to }: RedirectProps) => {
  useEffect(() => {
    location.href = typeof to === 'string' ? to : to.toString()
  }, [])

  return null
}
