import styled from '@emotion/styled/macro'
import * as React from 'react'

import { OutlineButton } from '~/components/atoms/OutlineButton'
import type { StorageItem } from '~/hooks/useCustomizationStorage'
import type { Product } from '~/types/Product'

import { TempData } from './TempData'

interface TempDesignRestorePageProps {
  disabled?: boolean

  items: readonly StorageItem[]

  /**
   * 一時保存データにはIDとバージョンしか含まれていないため必要
   */
  products: readonly Product[]

  onLoad(item: StorageItem): void
  onCreateNew(): void
  onDelete(item: StorageItem): void
}

/**
 * 一時保存データがある場合に、復元するのかどうか確認する画面
 */
export const TempDesignRestorePage = ({
  disabled = false,
  items,
  products,
  onCreateNew,
  onDelete,
  onLoad
}: TempDesignRestorePageProps) => {
  return (
    <Page>
      <Body>
        <Title>作業中のデザインがあります</Title>
        <Paragraph>
          マイデザインまたはカートに追加されていないデザインがあります。再開ボタンを押してください。
        </Paragraph>
        <List>
          {items.map((item) => (
            <TempData
              key={item.id}
              disabled={disabled}
              item={item}
              products={products}
              onDelete={() => {
                onDelete(item)
              }}
              onLoad={() => {
                onLoad(item)
              }}
            />
          ))}
        </List>
        <Button
          colorTheme="blue-outline"
          disabled={disabled}
          onClick={() => {
            onCreateNew()
          }}
        >
          再開せず最初から始める
        </Button>
      </Body>
    </Page>
  )
}

const Page = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gutter-medium) var(--gutter-large);

  background-color: var(--gray-200);
  overflow-y: auto;
`

const Body = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: var(--gutter-medium);
  max-width: 327px;
  max-height: 100%;
`

const Title = styled.h1`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.05em;

  text-align: center;
`

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1px;

  background-color: hsl(0 0% 0% / 0.12);
  border-radius: 8px;
  overflow-y: auto;
`

const Button = styled(OutlineButton)`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
  padding: var(--gutter-small);
`
