import { debounce } from 'lodash'
import { DependencyList, useEffect, useMemo, useRef, useState } from 'react'

interface UseDebouncedMemoReturns<T> {
  /**
   * 計算結果
   */
  value: T

  /**
   * `value` が最新ではないかどうか
   */
  isStaled: boolean
}

interface UseDebouncedMemoParams {
  /**
   * 再計算のトリガー
   */
  deps: DependencyList

  /**
   * debounceの解除時間 (ms)
   */
  wait: number
}

/**
 * 値の計算をdebounceして返すHooks
 *
 * 初回レンダリング時はdebounceせず即時計算してその結果を返す。
 * それ以降の計算はdebounceを行い、debounce中、つまり値が最新ではない場合は `isStaled` が `true` になる。
 */
export function useDebouncedMemo<T>(
  f: () => T,
  { deps, wait }: UseDebouncedMemoParams
): UseDebouncedMemoReturns<T> {
  const [state, setState] = useState(() => f())
  const isStaled = useRef<boolean>(false)
  const isFirstRender = useRef<boolean>(true)

  const debouncedFn = useMemo(
    () =>
      debounce(
        (fq: () => T) => {
          isStaled.current = false
          setState(fq())
        },
        wait,
        { leading: true }
      ),
    [wait]
  )

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    isStaled.current = true
    debouncedFn(f)
  }, deps)

  return { value: state, isStaled: isStaled.current }
}
