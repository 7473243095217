import styled from '@emotion/styled/macro'
import * as React from 'react'
import { Fragment, ReactNode } from 'react'

import { AuthError, HttpStatusError } from '~/api/common'
import {
  FloatingButton,
  Props as FloatingButtonProps
} from '~/components/atoms/FloatingButton'
import { useCurrentUrl } from '~/hooks/useCurrentUrl'
import { OutOfBoundsIndexAccessError } from '~/hooks/useLocalCart'
import { useCartPageUrl } from '~/hooks/useCartPageUrl'
import { useLoginPageUrl } from '~/hooks/useLoginPageUrl'

export interface BootstrapErrorPageProps {
  title: ReactNode

  children: ReactNode
}

export const BootstrapErrorPage = ({
  title,
  children
}: BootstrapErrorPageProps): JSX.Element | null => {
  return (
    <Page>
      <Container>
        <Title>{title || 'エラーが発生しました'}</Title>
        <Body>{children}</Body>
      </Container>
    </Page>
  )
}

const portalOrigin = process.env.REACT_APP_PORTAL_URL || 'https://joggo.jp'

const contactPageUrl = new URL('contact/', portalOrigin)

const ContactFormNavigationButton = (
  props: Omit<FloatingButtonProps, 'onClick' | 'children'>
) => {
  return (
    <Button
      {...props}
      onClick={() => {
        ;(window.parent || window).location.href = contactPageUrl.toString()
      }}
    >
      お問い合わせ
    </Button>
  )
}

interface FetchErrorBodyProps {
  error: unknown

  onRetry?(): void
}

export const FetchErrorBody = ({
  error,
  onRetry
}: FetchErrorBodyProps): JSX.Element | null => {
  const currentUrl = useCurrentUrl()

  const loginPageUrl = useLoginPageUrl({
    redirectTo: currentUrl
  })

  if (error instanceof TypeError) {
    return (
      <Fragment>
        <p>
          通信処理が行えませんでした。ネットワークに接続されていない、もしくは接続が不安定な可能性があります。
        </p>
        <ul>
          <li>
            通信環境が安定する場所へ移動してから
            {onRetry ? 'リトライ' : 'リロード'}する
          </li>
          <li>
            別のインターネットへの接続方法へ切り替えてから
            {onRetry ? 'リトライ' : 'リロード'}する (WiFi、4G/5Gなど)
          </li>
        </ul>
        {onRetry && (
          <Button colorTheme="blue" onClick={() => onRetry()}>
            リトライ
          </Button>
        )}
        <p>
          上記の方法を試しても解決しない場合は、お手数ですがお問い合わせフォームよりご連絡ください。
        </p>
        <ContactFormNavigationButton colorTheme={!onRetry ? 'blue' : 'white'} />
      </Fragment>
    )
  }

  if (error instanceof SyntaxError) {
    return (
      <Fragment>
        <p>
          サーバーからの応答が不正だったため、処理を続行できませんでした。この画面が繰り返し表示される場合は、お手数ですがお問い合わせフォームよりご連絡ください。
        </p>
        <ContactFormNavigationButton colorTheme="blue" />
      </Fragment>
    )
  }

  if (error instanceof AuthError) {
    return (
      <Fragment>
        <p>認証処理に失敗しました。</p>
        <ul>
          <li>ログインしていない場合はログインしてください</li>
          <li>
            既にログイン済みの場合は、一度ログアウトしてから再度ログインしてください
          </li>
        </ul>
        <Button
          colorTheme="blue"
          onClick={() => {
            ;(window.parent || window).location.href = loginPageUrl.toString()
          }}
        >
          ログイン画面へ
        </Button>
        <p>
          上記の方法を試してもこの画面が表示される場合は、お手数ですがお問い合わせフォームよりご連絡ください。
        </p>
        <ContactFormNavigationButton />
      </Fragment>
    )
  }

  if (error instanceof HttpStatusError) {
    return (
      <Fragment>
        <p>
          サーバーでエラーが発生しました (HTTPステータス{error.status})。
          {onRetry && (
            <Fragment>
              以下の
              <kbd>
                <samp>リトライ</samp>
              </kbd>
              ボタンを押すか、
            </Fragment>
          )}
          ページをリロードすると解消される場合があります。
        </p>
        {onRetry && (
          <Button colorTheme="blue" onClick={() => onRetry()}>
            リトライ
          </Button>
        )}
        <p>
          上記の方法で解決しない場合は、お手数ですがお問い合わせフォームよりご連絡ください。
        </p>
        <ContactFormNavigationButton colorTheme={onRetry ? 'white' : 'blue'} />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <p>予期せぬエラーが発生しました。</p>
      <p>詳細: {error instanceof Error ? error.message : String(error)}</p>
      <p>
        この画面が繰り返し表示される場合は、お手数ですがお問い合わせフォームよりご連絡ください。
      </p>
      <ContactFormNavigationButton colorTheme="blue" />
    </Fragment>
  )
}

const myDesignPageUrl = new URL(
  'saved-designs',
  process.env.REACT_APP_ORDER_URL || 'https://order.joggo.jp'
)

export const MyDesignFetchErrorBody = ({
  error,
  onRetry
}: FetchErrorBodyProps) => {
  if (error instanceof HttpStatusError && error.status === 404) {
    return (
      <Fragment>
        <p>
          指定されたマイデザインが見つかりませんでした。以下の原因が考えられます。
        </p>
        <ul>
          <li>該当のマイデザインが既に削除されている</li>
          <li>URL文字列の一部を変更してしまっている</li>
        </ul>
        <p>
          <a href={myDesignPageUrl.toString()}>マイデザイン一覧画面</a>の
          <kbd>
            <samp>デザインを編集</samp>
          </kbd>
          ボタンを押してもこの画面が表示される場合は、お手数ですがお問い合わせフォームよりご連絡ください。
        </p>
        <ContactFormNavigationButton colorTheme="blue" />
      </Fragment>
    )
  }

  return <FetchErrorBody error={error} onRetry={onRetry} />
}

export const CartItemFetchErrorBody = ({
  error,
  onRetry
}: FetchErrorBodyProps) => {
  const cartPageUrl = useCartPageUrl()

  if (error instanceof HttpStatusError && error.status === 404) {
    return (
      <Fragment>
        <p>
          カート内に指定されたアイテムが存在しません。以下の原因が考えられます。
        </p>
        <ul>
          <li>該当のアイテムを既に削除している</li>
          <li>別のブラウザ、タブ、デバイスなどで既に注文をした</li>
          <li>URLの一部を変更してしまっている</li>
        </ul>
        <p>
          <a href={cartPageUrl.toString()}>カート画面</a>の
          <kbd>
            <samp>デザインを再編集する</samp>
          </kbd>
          ボタンを押してもこの画面が表示される場合は、お手数ですがお問い合わせフォームよりご連絡ください。
        </p>
        <ContactFormNavigationButton colorTheme="blue" />
      </Fragment>
    )
  }

  return <FetchErrorBody error={error} onRetry={onRetry} />
}

const itemsPageUrl = new URL('products/', portalOrigin)

export const ProductFetchErrorBody = ({
  error,
  onRetry
}: FetchErrorBodyProps) => {
  if (error instanceof HttpStatusError && error.status === 404) {
    return (
      <Fragment>
        <p>指定された商品は存在しません。</p>
        <Button
          colorTheme="blue"
          onClick={() => {
            location.href = itemsPageUrl.toString()
          }}
        >
          商品一覧画面へ
        </Button>
      </Fragment>
    )
  }

  return <FetchErrorBody error={error} onRetry={onRetry} />
}

interface LocalCartLoadingErrorBodyProps {
  error: unknown
}

export const LocalCartLoadingErrorBody = ({
  error
}: LocalCartLoadingErrorBodyProps) => {
  const cartPageUrl = useCartPageUrl()

  if (error instanceof OutOfBoundsIndexAccessError) {
    return (
      <Fragment>
        <p>
          カート内に指定されたアイテムが存在しません。以下の原因が考えられます。
        </p>
        <ul>
          <li>該当のアイテムを既に削除している</li>
          <li>別のブラウザ、タブ、端末などで既に注文をした</li>
          <li>別のブラウザ、タブ、端末などでログインした</li>
          <li>ブラウザのCookieがクリアされた</li>
          <li>URLの一部を変更してしまっている</li>
        </ul>
        <p>
          <a href={cartPageUrl.toString()}>カート画面</a>の
          <kbd>
            <samp>デザインを再編集する</samp>
          </kbd>
          ボタンを押してもこの画面が表示される場合は、お手数ですがお問い合わせフォームよりご連絡ください。
        </p>
        <ContactFormNavigationButton colorTheme="blue" />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <p>カート内のアイテムを読み込むことができませんでした。</p>
      <p>詳細: {error instanceof Error ? error.message : String(error)}</p>
      <p>
        この画面が繰り返し表示される場合は、お手数ですがお問い合わせフォームよりご連絡ください。
      </p>
      <ContactFormNavigationButton colorTheme="blue" />
    </Fragment>
  )
}

const Page = styled.main`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--gray-200);
  overflow-y: auto;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gutter-medium);
  width: 100%;
`

const Container = styled(Box)`
  --_padding: var(--gutter-large);

  max-width: calc(400px + var(--_padding) * 2);
  padding: var(--_padding);
  margin: auto;
`

const Title = styled.h1`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  align-self: center;
`

const Body = styled(Box)`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;

  & a {
    color: var(--link-color);

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  & ul {
    padding: var(--gutter-medium);
    padding-left: var(--gutter-extra-large);
    width: 100%;

    background-color: var(--gray-0);
    border-radius: 8px;
    list-style: disc;
  }

  & kbd {
    margin: 0 0.25em;
  }

  & kbd,
  & samp {
    font: inherit;
    font-style: italic;
  }
`

const Button = styled(FloatingButton)`
  width: 100%;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
  padding: var(--gutter-small) var(--gutter-extra-large);
`
