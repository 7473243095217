import * as React from 'react'

export const CartIn = () => (
  <>
    <path
      d="M2.3579 2C2.62529 2 2.86837 2.21877 2.91699 2.48616L4.15671 9.00077H12.2027C12.2027 9.00077 13.1264 4.72252 13.1751 4.33359C13.2237 3.94466 13.2723 3.55573 13.6126 3.55573C13.9529 3.55573 14.0502 3.89604 13.9772 4.26067L12.932 9.31678C12.8591 9.60848 12.6403 9.77863 12.3486 9.77863H4.30256L4.59426 11.3344H11.7895C12.0569 11.3344 12.3 11.5288 12.3486 11.7962C12.6403 12.0636 12.8347 12.4768 12.8347 12.8901C12.8347 13.7652 12.1298 14.4458 11.279 14.4458C10.4039 14.4458 9.72329 13.7652 9.72329 12.8901C9.72329 12.6713 9.79622 12.331 9.91776 12.1122H6.39306C6.51461 12.331 6.61184 12.6713 6.61184 12.8901C6.61184 13.7652 5.9069 14.4458 5.05611 14.4458C4.18102 14.4458 3.50039 13.7652 3.50039 12.8901C3.50039 12.5012 3.64623 12.1365 3.88932 11.8448L2.18774 2.77786H0.291699C0.121541 2.77786 0 2.65632 0 2.48616V2.2917C0 2.14585 0.121541 2 0.291699 2H2.3579Z"
      fill="currentColor"
    />
    <path
      d="M9.8346 4.65625C10.0846 4.40625 9.8971 4 9.5846 4H8.49085V2.375C8.49085 2.1875 8.30335 2 8.11585 2H7.86585C7.6471 2 7.49085 2.1875 7.49085 2.375V4H6.36585C6.05335 4 5.86585 4.40625 6.11585 4.65625L7.7096 6.25C7.86585 6.40625 8.0846 6.40625 8.24085 6.25L9.8346 4.65625Z"
      fill="currentColor"
    />
  </>
)
