import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/react'
import * as ToastPrimitive from '@radix-ui/react-toast'
import * as React from 'react'
import { forwardRef } from 'react'

import { mqHorizontal } from '~/utils/mediaQuery'

export const ToastProvider = ToastPrimitive.Provider

export const ToastViewport = styled(ToastPrimitive.Viewport)`
  --toast-viewport--padding: var(--gutter-medium);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: var(--gutter-small);
  padding: var(--toast-viewport--padding);

  list-style: none;
  z-index: 9999;

  ${mqHorizontal} {
    left: auto;
    align-items: flex-end;
  }
`

export const ToastTitle = styled(ToastPrimitive.Title)`
  grid-area: title;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
`

export const ToastDescription = styled(ToastPrimitive.Description)`
  grid-area: description;
  margin-top: var(--gutter-small);
  font-size: 12px;
  line-height: 1.5;
`

export interface ToastProps extends ToastPrimitive.ToastProps {
  variant?: 'info' | 'success' | 'error' | 'warning'

  /**
   * トーストが閉じられ、アニメーションが完全に終了した際
   */
  onClose?(): void
}

export const Toast = forwardRef<HTMLLIElement, ToastProps>(
  ({ variant = 'info', onClose, onAnimationEnd, ...rest }, ref) => (
    <StyledToast
      ref={ref}
      data-variant={variant}
      {...rest}
      forceMount
      onAnimationEnd={(ev) => {
        onAnimationEnd?.(ev)

        if (
          ev.animationName === fadeOut.name ||
          ev.animationName === slideOut.name ||
          ev.animationName === swipeOut.name
        ) {
          onClose?.()
        }
      }}
    />
  )
)

type ToastCloseProps = Omit<ToastPrimitive.ToastCloseProps, 'children'>

export const ToastClose = (props: ToastCloseProps) => (
  <StyledClose aria-label="閉じる" {...props}>
    <CloseIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_882_11846)">
        <path
          d="M10.0024 0C4.47819 0 0.00238037 4.47581 0.00238037 10C0.00238037 15.5242 4.47819 20 10.0024 20C15.5266 20 20.0024 15.5242 20.0024 10C20.0024 4.47581 15.5266 0 10.0024 0ZM10.0024 18.7097C5.20399 18.7097 1.2927 14.8387 1.2927 10C1.2927 5.24194 5.16367 1.29032 10.0024 1.29032C14.7604 1.29032 18.7121 5.20161 18.7121 10C18.7121 14.7984 14.8008 18.7097 10.0024 18.7097ZM13.7927 7.21774C13.9943 7.01613 13.9943 6.73387 13.7927 6.53226L13.4701 6.20968C13.2685 6.00806 12.9863 6.00806 12.7846 6.20968L10.0024 8.99193L7.1798 6.20968C7.01851 6.00806 6.69593 6.00806 6.49432 6.20968L6.17173 6.53226C5.97012 6.73387 5.97012 7.01613 6.17173 7.21774L8.95399 10L6.17173 12.8226C5.97012 12.9839 5.97012 13.3065 6.17173 13.5081L6.49432 13.8306C6.69593 14.0323 7.01851 14.0323 7.1798 13.8306L10.0024 11.0484L12.7846 13.8306C12.9863 14.0323 13.2685 14.0323 13.4701 13.8306L13.7927 13.5081C13.9943 13.3065 13.9943 12.9839 13.7927 12.8226L11.0104 10L13.7927 7.21774Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_882_11846">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </CloseIcon>
  </StyledClose>
)

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  to {
    transform: translateX(100vw);
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(calc(var(--toast-viewport--padding) + 100%));
  }

  to {
    transform: translateX(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(var(--toast-viewport--padding) + 100%));
  }
`

const StyledToast = styled(ToastPrimitive.Root)`
  --_gutter: calc(var(--gutter-small) * 1.5);

  display: grid;
  grid-template-columns: auto max-content;
  grid-template-areas:
    'title close'
    'description close';
  column-gap: var(--_gutter);
  align-items: center;
  padding: var(--_gutter);
  width: 100%;
  max-width: 340px;
  border: 1px solid var(--border-darker);

  border-radius: 8px;
  box-shadow: 0px 4px 4px hsla(0, 0%, 0%, 0.25);
  background-color: var(--gray-100);
  color: var(--black-400);

  &[data-variant='success'] {
    background-color: var(--teal-400);
    color: var(--gray-0);
  }

  &[data-variant='error'] {
    background-color: var(--red-400);
    color: var(--gray-0);
  }

  &[data-variant='warning'] {
    background-color: var(--yellow-400);
    color: var(--black-400);
  }

  &[data-state='open'] {
    animation: ${slideIn} 200ms ease;
  }

  &[data-state='closed'] {
    animation: ${slideOut} 150ms ease-out;
  }

  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease;
  }

  &[data-swipe='end'] {
    animation: ${swipeOut} 100ms ease-in;
  }

  @media (prefers-reduced-motion: reduce) {
    &[data-state='open'] {
      animation: ${fadeIn} 200ms ease;
    }

    &[data-state='closed'] {
      animation: ${fadeOut} 100ms ease-in;
    }
  }
`

const StyledClose = styled(ToastPrimitive.Close)`
  grid-area: close;
  display: inline-flex;

  color: inherit;
`

const CloseIcon = styled.svg`
  width: 20px;
  height: 20px;

  fill: currentColor;
`
