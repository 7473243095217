import styled from '@emotion/styled/macro'
import * as React from 'react'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { Icon } from '~/components/atoms/Icon'
import { StepMenu, StepMenuItem } from '~/components/molecules/StepMenu'
import type { Color } from '~/types/Color'
import type { Customization } from '~/types/Customization'
import { mqHorizontal } from '~/utils/mediaQuery'
import { validate as validatePrintName } from '~/utils/printName'

import { Buttons } from './Buttons'
import type { Scene } from './Scene'
import type { UseSceneReturns } from './useScene'

export type { Scene } from './Scene'
export { useScene } from './useScene'

interface NavigationProps {
  // TODO: なにかの操作中は、そのアクションを引き起こした要素は `disabled` にせずローディングとして扱うべき
  //       そのため、 `mutation: "myDesign" | "cart"` のようなものにしてフォーカスが宙ぶらりんにならないようにする
  disabled?: boolean
  customization: Customization
  colors: readonly Color[]

  /**
   * カートに追加可能な状態かどうか
   */
  isReady?: boolean

  cartButtonLabel: ReactNode

  scene: UseSceneReturns

  onAddToCart(customization: Customization): void
  onSave(customization: Customization): void
  onShare(customization: Customization): void
}

export const Navigation = ({
  disabled = false,
  cartButtonLabel,
  customization,
  colors,
  isReady,
  scene: { current: currentScene, scenes, change: changeScene },
  onAddToCart,
  onSave,
  onShare
}: NavigationProps) => {
  const wholeStepCount = scenes.length

  const currentStep =
    scenes.findIndex((scene) => scene.id === currentScene.id) + 1

  const scrollContainer = useRef<HTMLDivElement>(null)
  const [sceneEls] = useState<Map<Scene, HTMLElement | null>>(() => new Map())

  // 選択されているものが変わったらスクロールする
  useEffect(() => {
    const container = scrollContainer.current
    if (!container) {
      return
    }

    const el = sceneEls.get(currentScene)
    if (!el) {
      return
    }

    // コンテンツ領域とスクロール領域のサイズが違う = オーバーフローしている
    if (container.scrollWidth !== container.clientWidth) {
      // 横に溢れている場合は横軸でスクロール
      container.scroll({
        left: Math.max(
          0,
          el.offsetLeft + el.clientWidth / 2 - container.clientWidth / 2
        ),
        behavior: 'smooth'
      })
    } else {
      // 縦に溢れている場合は縦軸でスクロール
      container.scroll({
        top: Math.max(
          0,
          el.offsetTop + el.clientHeight / 2 - container.clientHeight / 2
        ),
        behavior: 'smooth'
      })
    }
  }, [currentScene])

  const onNext = useCallback(() => {
    // `currentStep` はインデックスに+1された値なので、そのまま参照すると次の要素になる
    const next = scenes[currentStep]

    if (!next) {
      return
    }

    changeScene(next)
  }, [changeScene, scenes, currentStep])

  return (
    <Container>
      <MenuContainer ref={scrollContainer}>
        <StepMenu>
          {scenes.map((scene) => {
            const ref = (el: HTMLLIElement) => {
              sceneEls.set(scene, el)
            }

            switch (scene.type) {
              case 'part': {
                const partCustomization = customization.parts[scene.part.id]

                const color = colors.find(
                  (c) => c.id === partCustomization?.color.id
                )

                return (
                  <StepMenuItemWithPadding
                    ref={ref}
                    key={scene.id}
                    disabled={disabled}
                    selected={scene.id === currentScene.id}
                    status={
                      color ? `completed:${color.colorCode}` : 'not_completed'
                    }
                    onClick={() => changeScene(scene)}
                  >
                    {scene.part.name}
                  </StepMenuItemWithPadding>
                )
              }
              case 'print_logo':
                return (
                  <StepMenuItemWithPadding
                    ref={ref}
                    key={scene.id}
                    disabled={disabled}
                    selected={scene.id === currentScene.id}
                    status={
                      customization.printLogo === null
                        ? 'not_completed'
                        : 'completed'
                    }
                    onClick={() => changeScene(scene)}
                  >
                    ロゴ
                  </StepMenuItemWithPadding>
                )
              case 'print_name': {
                const isReady =
                  typeof customization.printName !== 'undefined' &&
                  (customization.printName === null ||
                    customization.printName.printType === null ||
                    (customization.printName &&
                      validatePrintName(customization.printName.text).ok))

                return (
                  <StepMenuItemWithPadding
                    ref={ref}
                    key={scene.id}
                    disabled={disabled}
                    selected={scene.id === currentScene.id}
                    status={isReady ? 'completed' : 'not_completed'}
                    onClick={() => changeScene(scene)}
                  >
                    名入れ刻印
                  </StepMenuItemWithPadding>
                )
              }
            }
          })}
        </StepMenu>
      </MenuContainer>
      <Buttons
        state={
          currentStep < wholeStepCount
            ? isReady
              ? 'both'
              : 'next-only'
            : 'cart-only'
        }
        disabled={disabled}
        nextButtonLabel={
          <>
            次へ ({currentStep}/{wholeStepCount})
          </>
        }
        cartButtonLabel={cartButtonLabel}
        onClickNextButton={() => onNext()}
        onClickCartButton={() => onAddToCart(customization)}
      />
      <SubMenuContainer>
        <SubMenu disabled={disabled} onClick={() => onSave(customization)}>
          <SubMenuIcon type="share_link" />
          <span>デザインを保存</span>
        </SubMenu>
        <SubMenu disabled={disabled} onClick={() => onShare(customization)}>
          <SubMenuIcon type="share" />
          <span>デザインをシェア</span>
        </SubMenu>
      </SubMenuContainer>
    </Container>
  )
}

const Container = styled.div`
  --_padding: var(--gutter-medium);
  --_gap: var(--_padding);

  display: flex;
  flex-direction: column;
  padding: var(--_padding);
  gap: var(--_gap);

  ${mqHorizontal} {
    height: 100%;
  }
`

const MenuContainer = styled.div`
  margin: calc(-1 * var(--_padding));
  margin-bottom: calc(-1 * var(--_gap));
  padding: var(--_padding);
  padding-bottom: var(--_gap);
  flex-shrink: 1;
  flex-grow: 1;

  overflow-x: auto;

  ${mqHorizontal} {
    padding: var(--_padding);
    margin: 0;

    overflow-x: hidden;
    overflow-y: auto;
  }
`

const StepMenuItemWithPadding = styled(StepMenuItem)`
  &:last-child::after {
    content: '';

    display: block;
    margin-top: -1px;
    height: 1px;
    width: calc(100% + var(--_padding));

    pointer-events: none;
    visibility: hidden;

    ${mqHorizontal} {
      display: none;
    }
  }
`

const SubMenuContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: max-content;
  gap: 16px;
`

const SubMenu = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
  font-size: var(--primary-font-size);
  line-height: 1.33;
  letter-spacing: 0.05em;

  border-radius: 2px;
  color: var(--link-color);

  &:disabled {
    color: var(--gray-700);
  }
`

const SubMenuIcon = styled(Icon)`
  font-size: 16px;
`
