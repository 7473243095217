import { useMemo } from 'react'

/**
 * スキーマに一致しない、データ不整合等で起動モードが判別できない
 */
export interface InvalidBootstrapMode {
  type: 'invalid_bootstrap_mode'
}

/**
 * デザインをイチから作成し、完了時にはカートに新規追加する
 */
export interface ItemCreation {
  type: 'item_creation'

  product: {
    id: string
    version?: string
  }
}

/**
 * カートに入っているアイテムを編集し、完了時にはそのアイテムを更新する
 */
export interface CartItemUpdate {
  type: 'cart_item_update'

  cartItem: {
    id: string
  }
}

/**
 * ローカルカート (未ログイン時に使うブラウザストレージ) 内のアイテムを編集し、完了時にはそのアイテムを更新する
 */
export interface LocalCartItemUpdate {
  type: 'local_cart_item_update'

  /**
   * ローカルカート配列の何番目のアイテムか
   */
  cartItemIndex: number
}

/**
 * マイデザインを編集し、完了時にはそのマイデザインを更新する
 */
export interface MyDesignItemUpdate {
  type: 'my_design_item_update'

  myDesignItem: {
    id: string
  }
}

/**
 * アプリケーションの起動モード
 *
 * 開始時に何を編集するのかはもちろん、完了時にどういった動作をとるかもこれによって変わる。
 */
export type BootstrapMode =
  | InvalidBootstrapMode
  | ItemCreation
  | CartItemUpdate
  | LocalCartItemUpdate
  | MyDesignItemUpdate

/**
 * アプリケーションの起動モード (何をどう編集してどう終了するか) をURLから計算する
 *
 * @param urlSource - 起動モードの計算に利用するURL
 */
export function useBootstrapMode(
  urlSource: URL | string = location.href
): BootstrapMode {
  const url = useMemo<URL>(() => {
    return typeof urlSource === 'string' ? new URL(urlSource) : urlSource
  }, [urlSource])

  // 仕様: https://joggo-docs.netlify.app/designs/urls.html#%E3%82%B7%E3%83%9F%E3%83%A5%E3%83%AC%E3%83%BC%E3%82%BF
  return useMemo<BootstrapMode>(() => {
    const m = url.searchParams.get('m')
    if (m) {
      return {
        type: 'my_design_item_update',
        myDesignItem: { id: m }
      }
    }

    const i = url.searchParams.get('i')
    if (i) {
      return {
        type: 'cart_item_update',
        cartItem: { id: i }
      }
    }

    const ii = parseInt(url.searchParams.get('ii') ?? '')
    if (isFinite(ii)) {
      return {
        type: 'local_cart_item_update',
        cartItemIndex: ii
      }
    }

    const d = url.searchParams.get('d')
    if (d) {
      return {
        type: 'item_creation',
        product: {
          id: d,
          version: url.searchParams.get('v') ?? undefined
        }
      }
    }

    return { type: 'invalid_bootstrap_mode' }
  }, [url.search])
}
