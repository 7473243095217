import { useEffect } from 'react'

import { updateMyDesign } from '~/api/myDesign'
import { useMutation } from '~/hooks/useMutation'
import type { LoginSession } from '~/hooks/useSession'
import { useToast } from '~/hooks/useToast'
import type { ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { MyDesign } from '~/types/MyDesign'
import type { Product } from '~/types/Product'
import { toRequestPayload } from '~/utils/customization'

interface Params {
  customization: ReadyCustomization
  design: Design
  product: Product
  myDesign: MyDesign

  session: LoginSession
}

export function useMyDesignUpdate({
  onUpdated
}: {
  onUpdated?(): void
} = {}) {
  const toast = useToast()

  const mutation = useMutation(
    async ({ customization, design, product, myDesign, session }: Params) =>
      updateMyDesign(
        myDesign.id,
        toRequestPayload(customization, design, product),
        session.accessToken.token
      )
  )

  // マイデザイン更新処理が成功・失敗したら、その結果に応じた処理を行う
  useEffect(() => {
    switch (mutation.status) {
      case 'success': {
        onUpdated?.()
        toast.show({
          variant: 'success',
          title: 'マイデザインに上書き保存しました'
        })
        return
      }
      case 'failed': {
        toast.show({
          variant: 'error',
          title: 'マイデザインの上書き保存に失敗しました',
          description:
            mutation.error instanceof Error
              ? mutation.error.message
              : String(mutation)
        })
        return
      }
    }
  }, [mutation.status])

  return mutation
}
