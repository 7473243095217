import styled from '@emotion/styled/macro'
import { ClassNames, ClassNamesContent } from '@emotion/react'
import * as React from 'react'
import type { CSSProperties, ReactNode } from 'react'
import ReactModal from 'react-modal'

import { Icon } from '~/components/atoms/Icon'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactModal.setAppElement(document.getElementById('root')!)

export interface DialogProps {
  className?: string
  style?: CSSProperties

  opened: boolean

  children: ReactNode
  title: ReactNode

  variant?: 'dialog' | 'drawer'

  onClose(): void
}

export const Dialog = ({
  opened,
  children,
  title,
  variant = 'dialog',
  onClose,
  className,
  style
}: DialogProps) => {
  return (
    <ClassNames>
      {({ css }) => (
        <ReactModal
          className={{
            base: variant === 'drawer' ? drawerStyle(css) : contentStyle(css),
            afterOpen: contentAfterOpenStyle(css),
            beforeClose: contentBeforeCloseStyle(css)
          }}
          overlayClassName={{
            base: backdropStyle(css),
            afterOpen: backdropAfterOpenStyle(css),
            beforeClose: backdropBeforeCloseStyle(css)
          }}
          style={{
            content: style
          }}
          closeTimeoutMS={1000}
          isOpen={opened}
          onRequestClose={() => onClose()}
        >
          <Title>
            <span>{title}</span>
            <CloseButton
              title="閉じる"
              aria-label="ダイアログを閉じる"
              onClick={() => onClose()}
            >
              <Icon type="close" />
            </CloseButton>
          </Title>
          <Body className={className}>{children}</Body>
        </ReactModal>
      )}
    </ClassNames>
  )
}

const contentStyle = (css: ClassNamesContent['css']) => css`
  --_radius: 8px;
  --_center-transform: translate(-50%, -50%);

  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 120px;
  max-width: calc(100vw - var(--gutter-medium) * 2);
  min-height: 120px;
  max-height: 90vh;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;

  background-color: var(--gray-0);
  border-radius: var(--_radius);
  overflow: hidden;

  opacity: 0;
  transform: var(--_center-transform) translateY(300px);
`

const drawerStyle = (css: ClassNamesContent['css']) => css`
  ${contentStyle(css)}

  --_center-transform: translate(0px, 0px);

  top: 48px;
  margin: 0 auto;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  max-height: 100%;
  width: 100%;
  max-width: 960px;
  align-items: stretch;

  border-radius: var(--_radius) var(--_radius) 0 0;

  @media (max-height: 400px) {
    top: 12px;
  }
`

const contentAfterOpenStyle = (css: ClassNamesContent['css']) => css`
  opacity: 1;
  transform: var(--_center-transform) translateY(0px);
  transition: opacity 0.2s linear, transform 0.4s ease-out;

  @media (prefers-reduced-motion: reduce) {
    transition: opacity 0.1s ease-out;
  }
`

const contentBeforeCloseStyle = (css: ClassNamesContent['css']) => css`
  pointer-events: none;

  opacity: 0;
  transform: var(--_center-transform) translateY(300px);
  transition: opacity 0.1s ease-in, transform 0.1s 0.1s ease-in;

  @media (prefers-reduced-motion: reduce) {
    transform: var(--_center-transform);
    transition: opacity 0.1s ease-in;
  }
`

const backdropStyle = (css: ClassNamesContent['css']) => css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: hsla(0, 0%, 0%, 0.5);

  transition: opacity 0.2s ease-out;
`

const backdropAfterOpenStyle = (css: ClassNamesContent['css']) => css`
  opacity: 1;
`

const backdropBeforeCloseStyle = (css: ClassNamesContent['css']) => css`
  pointer-events: none;

  opacity: 0;
`

const Title = styled.div`
  --_button-padding: var(--gutter-small);
  --_button-margin: var(--gutter-extra-small);
  --_button-size: 16px;

  position: relative;
  padding: var(--gutter-small)
    calc(16px + var(--_button-padding) * 2 + var(--_button-margin));
  font-size: 14px;
  line-height: 1.71;
  border-bottom: 1px solid var(--gray-400);

  text-align: center;
`

const CloseButton = styled.button`
  position: absolute;
  right: var(--_button-margin);
  top: 50%;
  font-size: var(--_button-size);
  padding: var(--_button-padding);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  transform: translateY(-50%);

  &:hover,
  &:focus-visible {
    background-color: var(--gray-200);
  }
`

const Body = styled.div`
  flex: 1;
  overflow-y: auto;
`
