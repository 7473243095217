import Cookies from 'js-cookie'

import { Request } from '~/types/SavedDesign'
import CookieKeys from '~/types/CookieKeys'

const attrs: Cookies.CookieAttributes = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN || 'joggo.jp',
  path: '/',
  secure: process.env.NODE_ENV === 'production',
  sameSite: 'strict'
}

/**
 * Key名を渡して、Cookieに保存されている値を返す
 * @param {String} key Cookieに保存されている値のKey名
 */
export const getCookie = (key: string) => Cookies.get(key)

/**
 * トークンを取得して、保存フラグとトークン値を返す
 */
export const getToken = (): { persist: boolean; token: string } | null => {
  const value = Cookies.get(CookieKeys.AccessToken)

  if (!value) {
    return null
  }

  const head = value.charAt(0)

  if (head !== '0' && head !== '1') {
    return null
  }

  const persist = head === '1'
  const token = value.slice(1)

  return { persist, token }
}

/**
 * Cookieにトークンを保存する
 * @param token トークン
 * @param persist 保存フラグ
 */
export const setToken = (token: string, persist = false) => {
  const head = persist ? '1' : '0'
  const value = head + token

  Cookies.set(CookieKeys.AccessToken, value, {
    expires: persist ? 15 : void 0,
    ...attrs
  })
}

/**
 * Cookieに保存されているトークンを削除する
 */
export function deleteToken(): void {
  Cookies.remove(CookieKeys.AccessToken, {
    ...attrs
  })
}

/**
 * Cookieにカート情報を保存する
 * @param cart カート保存時のリクエスト値
 */
export const setLocalCart = (cart: readonly Request[]) =>
  Cookies.set(CookieKeys.Cart, JSON.stringify(cart), {
    expires: 100,
    ...attrs
  })

/**
 * Cookieにアクセス状況を保存する
 */
export const setVisited = (visited: boolean) =>
  Cookies.set(CookieKeys.Visited, `${visited}`, {
    expires: 100,
    ...attrs
  })
