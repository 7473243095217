import styled from '@emotion/styled/macro'
import * as React from 'react'
import type { FocusEventHandler, HTMLAttributes, ReactNode } from 'react'

export interface TextBoxProps {
  className?: string

  id: string
  value: string

  label: ReactNode

  maxLength: number
  pattern: string
  required?: boolean
  disabled?: boolean
  enterKeyHint?: string
  placeholder?: string
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode']

  'aria-errormessage'?: string
  'aria-describedby'?: string

  state?: 'neutral' | 'invalid'

  onChange(newValue: string): void
  onBlur?(): void
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const TextBox = ({
  id,
  value,
  label,
  maxLength,
  pattern,
  disabled,
  enterKeyHint,
  required,
  placeholder,
  inputMode,
  'aria-errormessage': ariaErrorMessage,
  'aria-describedby': ariaDescribedby,
  state = 'neutral',
  onBlur,
  onChange,
  onFocus,
  ...rest
}: TextBoxProps) => {
  return (
    <Wrapper {...rest}>
      <Input
        id={id}
        value={value}
        maxLength={maxLength}
        pattern={pattern}
        disabled={disabled}
        // @ts-expect-error - React 17で導入されたのでアップデートしたらプロパティに変更する
        enterKeyHint={enterKeyHint}
        required={required}
        placeholder={placeholder}
        inputMode={inputMode}
        aria-invalid={state === 'invalid'}
        aria-errormessage={ariaErrorMessage}
        aria-describedby={ariaDescribedby}
        onBlur={() => onBlur?.()}
        onChange={(ev) => onChange(ev.currentTarget.value)}
        onFocus={onFocus}
      />
      <Label htmlFor={id}>{label}</Label>
      <CharacterCounter>
        {value.length}/{maxLength}
      </CharacterCounter>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  --_padding: var(--gutter-small);
  --_counter-width: 36px;

  display: inline-block;
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.05em;
`

const Input = styled.input`
  display: block;
  width: 100%;
  padding: var(--_padding);
  padding-right: calc(var(--_padding) + var(--_counter-width));
  box-shadow: inset 0 0 0 1px var(--gray-700);
  font-size: inherit;
  line-height: inherit;

  background-color: var(--gray-0);
  border-radius: 2px;

  &::placeholder {
    color: var(--gray-700);
  }

  &[aria-invalid='true'] {
    box-shadow: inset 0 0 0 2px var(--red-400);

    &:focus-visible {
      box-shadow: inset 0 0 0 2px var(--red-400),
        0 0 0 4px hsla(13, 70%, 48%, 0.3);
    }
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px var(--teal-400);
  }
`

const Label = styled.label`
  position: absolute;
  left: calc(var(--_padding) + 0.25em);
  top: 50%;
  font-size: inherit;
  line-height: inherit;

  color: var(--gray-700);
  pointer-events: none;
  user-select: none;

  transform: translateY(-50%);
  opacity: 0;
`

const CharacterCounter = styled.span`
  position: absolute;
  right: var(--_padding);
  top: 50%;
  font-size: 12px;
  line-height: 1.33;

  color: var(--black-200);
  pointer-events: none;
  user-select: none;

  transform: translateY(-50%);
`
