import { useEffect } from 'react'

import { updateCart } from '~/api/cart'
import { useMutation } from '~/hooks/useMutation'
import type { LoginSession } from '~/hooks/useSession'
import type { Cart } from '~/types/Cart'
import type { ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { Product } from '~/types/Product'
import { toRequestPayload } from '~/utils/customization'

interface MutationParams {
  cart: Pick<Cart, 'id'>

  customization: ReadyCustomization
  design: Design
  product: Product

  session: LoginSession
}

interface UseCartItemUpdateParams {
  onUpdated?(): void

  onError?(error: unknown): void
}

export function useCartItemUpdate({
  onError,
  onUpdated
}: UseCartItemUpdateParams = {}) {
  const mutation = useMutation(
    async ({ cart, customization, design, product, session }: MutationParams) =>
      updateCart(
        cart.id,
        toRequestPayload(customization, design, product),
        session.accessToken.token
      )
  )

  // ステータスコールバックを呼ぶ
  useEffect(() => {
    switch (mutation.status) {
      case 'failed': {
        onError?.(mutation.error)
        return
      }
      case 'success': {
        onUpdated?.()
        return
      }
    }
  }, [mutation.status])

  return mutation
}
