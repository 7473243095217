import Design from '~/types/Design'
import Color from '~/types/Color'
import { apiURL, buildURL, HttpStatusError } from '../common'

/**
 * ベースとなるデザイン情報を取得する
 * @param {String} id 商品ID
 */
export const fetchDesign = async (id: string): Promise<Design> => {
  const res = await fetch(`${apiURL}/products/${id}/design`, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'omit'
  })

  if (res.status !== 200) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}

/**
 * カラー一覧を取得する
 * @param material カラーが属している素材
 */
export const fetchColors = async (material?: string): Promise<Color[]> => {
  const url = buildURL(`${apiURL}/customization/colors`, { material })

  const res = await fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'omit'
  })

  if (res.status !== 200) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}
