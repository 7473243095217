import { keyframes } from '@emotion/react'
import styled from '@emotion/styled/macro'
import * as React from 'react'
import { useCallback, useMemo, useState, TouchEvent } from 'react'

import { Dialog, UseDialogStateReturns } from '~/components/atoms/Dialog'
import { Icon } from '~/components/atoms/Icon'
import { PreviewRenderer } from '~/components/organisms/PreviewRenderer'
import type { Color } from '~/types/Color'
import type { Customization } from '~/types/Customization'
import type { Design } from '~/types/Design'

import { useFlick } from '~/hooks/useFlick'

type CustomizeOverviewDialogProps = UseDialogStateReturns[0] & {
  className?: string

  colors: readonly Color[]
  customization: Customization
  design: Design
}

export const CustomizeOverviewDialog = ({
  colors,
  customization,
  design,
  ...rest
}: CustomizeOverviewDialogProps) => {
  const flick = useFlick()

  const [viewIndex, setViewIndex] = useState(0)
  const clampedViewIndex = useMemo(
    () => Math.max(0, Math.min(design.views.length, viewIndex)),
    [viewIndex, design.views.length]
  )

  const toNext = useCallback(() => {
    setViewIndex(
      clampedViewIndex + 1 === design.views.length ? 0 : clampedViewIndex + 1
    )
  }, [clampedViewIndex, design.views.length])

  const toPrev = useCallback(() => {
    setViewIndex(
      clampedViewIndex === 0 ? design.views.length - 1 : clampedViewIndex - 1
    )
  }, [clampedViewIndex, design.views.length])

  const view = design.views[clampedViewIndex]

  return (
    <GrayDialog {...rest} title="スワイプして完成イメージを確認">
      <PreviewContainer
        onTouchStart={flick.touchStart}
        onTouchMove={flick.touchMove}
        onTouchEnd={() => flick.touchEnd(toNext, toPrev)}
      >
        <Preview
          colors={colors}
          design={design}
          view={view}
          customization={customization}
          imageHeight="100%"
        />
      </PreviewContainer>
      <Controls>
        <Button
          onClick={() => toPrev()}
          title="前の画像へ"
          aria-label="前の画像を表示する"
        >
          <Icon type="left" />
        </Button>
        <div>
          <Label>{`${clampedViewIndex + 1} / ${design.views.length}`}</Label>
          <Label>{view.description}</Label>
        </div>
        <Button
          onClick={() => toNext()}
          title="次の画像へ"
          aria-label="次の画像を表示する"
        >
          <Icon type="right" />
        </Button>
      </Controls>
    </GrayDialog>
  )
}

const GrayDialog = styled(Dialog)`
  width: min(700px, min(100vw, 100vh));
  max-width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: minmax(0, 100%) max-content;
  align-items: stretch;
  justify-items: stretch;

  background-color: var(--gray-200);
`

const PreviewContainer = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  max-width: 100%;
  max-height: 100%;
  height: min(700px, min(100vw, 100vh));
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const Preview = styled(PreviewRenderer)`
  animation: 0.6s 0s ease-out 1 both ${fadeIn};

  align-self: center;
  justify-self: center;
  aspect-ratio: 1 / 1;
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 100%;

  &[data-hidden='true'] {
    visibility: hidden;
  }
`

const Controls = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) max-content;
  align-items: center;
  gap: var(--gutter-medium);
  padding: var(--gutter-medium);
`

const Button = styled.button`
  display: flex;
  font-size: 16px;
  padding: var(--gutter-small);
  border: 1px solid var(--gray-0);

  background-color: var(--gray-0);
  border-radius: 50%;
  color: var(--black-400);

  &:hover {
    background-color: var(--gray-400);
    border-color: var(--border-darker);
  }

  &:active {
    background-color: var(--gray-700);
    border-color: var(--border-darker);
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px var(--link-color);
  }
`

const Label = styled.span`
  display: block;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.05em;

  color: var(--black-700);
  text-align: center;

  & + & {
    margin-top: var(--gutter-extra-small);
  }
`
