import { useCallback } from 'react'

type OutgoingMessage = SaveStateChange | MessageReceiverReady

/**
 * 現在編集中のデザインが保存済みかどうかが変わった際に発火されるイベント
 *
 * フラグが変わった際に加えて初回読み込み時にも発火される。
 */
interface SaveStateChange {
  type: 'save_state_change'

  /**
   * サーバやストレージにデザインを保存済みかどうか
   */
  saved: boolean
}

/**
 * 埋め込み元からのメッセージを受信できる状態になった際に発火されるイベント
 *
 * これがシミュレータから届く前にメッセージを送信しても、それが処理される保証はない。
 */
interface MessageReceiverReady {
  type: 'message_receiver_ready'
}

/**
 * 埋め込み元のウィンドウに対してメッセージを送信する関数を返す
 */
export function useFrameMessageDispatcher(): (
  msg: OutgoingMessage,
  origin?: string
) => void {
  return useCallback(
    (msg, origin = process.env.REACT_APP_PORTAL_URL || 'https://joggo.jp') => {
      if (window.location === window.parent.location) {
        // 単独のページで動作中
        return
      }

      if (process.env.NODE_ENV === 'development') {
        console.groupCollapsed('[DEV] 親フレームに対してメッセージ送信 📤')
        console.log('📝 送信対象オリジン')
        console.log(origin)
        console.log('📦 送信メッセージ')
        console.log(msg)
        console.groupEnd()
      }

      window.parent.postMessage(msg, origin)
    },
    []
  )
}
