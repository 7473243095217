/**
 * 拡大率を一定間隔で増加・減少させる
 *
 * 1.0 を基準とし、それぞれの区画の差が全て `|amount|` となる階段関数に
 * `current` を当てはめ、`amount` が正の場合は一つ右の、負の場合は一つ左の
 * 段の数を返す関数。
 *
 * 挙動に関しては単体テストを参照するとわかりやすい。
 *
 * @param current - 現在の拡大率
 * @param amount - ステップの幅、負数の場合は減少する
 */
export function snapZoom(current: number, amount: number): number {
  return (
    (Math.floor(current / Math.abs(amount)) + (amount > 0 ? 1 : -1)) *
    Math.abs(amount)
  )
}
