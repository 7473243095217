import styled from '@emotion/styled/macro'
import { RadioGroupItem } from '@radix-ui/react-radio-group'
import * as React from 'react'
import { Fragment, memo, useRef } from 'react'

import { Color, Type as ColorType } from '~/types/Color'
import { useFLIP } from '~/hooks/useFLIP'

import { StarBadge } from './StarBadge'
import { PlaceholderImage } from './PlaceholderImage'

const LAYOUT_ANIM_DURATION = 150

export interface ColorSelectorItemProps {
  color: Color

  selected: Pick<Color, 'id'> | null

  disabled?: boolean
}

export const ColorSelectorItem = memo(
  ({ color, disabled, selected }: ColorSelectorItemProps) => {
    return (
      <Option value={'c:' + color.id} disabled={disabled}>
        <ColorSelectorItemInternal
          color={color}
          selected={selected}
          checked={color.id === selected?.id}
        />
      </Option>
    )
  },
  (prev, next) => {
    return (
      prev.color.id === next.color.id && prev.selected?.id === next.selected?.id
    )
  }
)

interface ColorSelectorItemInternalProps {
  checked: boolean

  color: Color | null
  selected: Pick<Color, 'id'> | null
}

const ColorSelectorItemInternal = ({
  checked,
  color,
  selected
}: ColorSelectorItemInternalProps) => {
  const boxRef = useRef<HTMLDivElement>(null)
  const badgeRef = useRef<SVGSVGElement>(null)

  // eslint-disable-next-line @typescript-eslint/naming-convention -- `_` は未使用変数の命名として一般的
  const { _, ...boxProps } = useFLIP({
    props: {
      'data-checked': checked || undefined,
      // 他のアイテムの選択状態が変わった場合も位置が変わるためアニメーションを行う
      _: selected
    },
    flip: {
      first() {
        return boxRef.current?.getBoundingClientRect()
      },
      last() {
        return boxRef.current?.getBoundingClientRect()
      },
      invert({ first, last }) {
        // 0除算を防ぐ
        if (!first?.width || !last?.height) {
          return {
            sx: 1,
            sy: 1,
            tx: 0
          }
        }

        return {
          sx: first.width / last.width,
          sy: first.height / last.height,
          tx: first.x - last.x
        }
      },
      play({ invert: { sx, sy, tx } }) {
        boxRef.current?.animate(
          [
            { transform: `translateX(${tx}px) scale(${sx}, ${sy})` },
            { transform: `translateX(0px) scale(1, 1)` }
          ],
          {
            duration: LAYOUT_ANIM_DURATION,
            easing: 'ease'
          }
        )

        // バッジも一緒に変形してしまうため、逆変形を適用する
        if (badgeRef.current) {
          badgeRef.current.animate(
            [
              { transform: `scale(${1 / sx}, ${1 / sy})` },
              { transform: `scale(1, 1)` }
            ],
            {
              duration: LAYOUT_ANIM_DURATION,
              easing: 'ease'
            }
          )
        }
      }
    }
  })

  return (
    <Fragment>
      <LabelPositioner>
        {!color ? (
          <Label>色を選んでください</Label>
        ) : color.type === ColorType.Basic ? (
          <Label>{color.name}</Label>
        ) : (
          <Label>
            {color.name}
            <AccentText>(期間限定)</AccentText>
          </Label>
        )}
      </LabelPositioner>
      {color ? (
        <Box
          {...boxProps}
          ref={boxRef}
          style={{ backgroundColor: color.colorCode }}
          data-box=""
        >
          {color.type === ColorType.Limited && (
            <LimitedColorBadge ref={badgeRef} />
          )}
        </Box>
      ) : (
        <NullBox {...boxProps} ref={boxRef} data-box="">
          <NullBoxImage />
        </NullBox>
      )}
    </Fragment>
  )
}

interface NullValueItemProps {
  checked: boolean

  disabled: boolean
}

export const NullValueItem = memo(
  ({ checked, disabled }: NullValueItemProps) => {
    return (
      <Option value="null" disabled={disabled}>
        <ColorSelectorItemInternal
          color={null}
          selected={null}
          checked={checked}
        />
      </Option>
    )
  }
)

const Option = styled(RadioGroupItem)`
  display: flex;
  flex-direction: column;
  height: calc(1em + var(--gutter-medium) + var(--_box-active-height));
  align-items: center;
  justify-content: space-between;

  pointer-events: none;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
`

const Box = styled.div`
  position: relative;
  width: var(--_box-width);
  height: var(--_box-height);
  border: 1px solid var(--border-darker);
  box-sizing: border-box;
  margin-bottom: calc((var(--_box-active-height) - var(--_box-height)) * 0.5);

  cursor: pointer;
  pointer-events: all;

  transition: border-radius ${LAYOUT_ANIM_DURATION}ms ease,
    border-color 0.1s ease;

  &[data-checked] {
    width: var(--_box-active-width);
    height: var(--_box-active-height);
    border-radius: 4px;
    margin: 0 var(--_box-gap);
  }
`

const NullBox = styled(Box)`
  position: relative;
  border: none;

  transition: opacity 0.1s ease;

  [data-state='unchecked'] > & {
    opacity: 0;
  }
`

const NullBoxImage = styled(PlaceholderImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const LabelPositioner = styled.span`
  display: block;
  width: 0;

  overflow: visible;

  opacity: 0;
  transition: opacity 0.2s ease-out;

  [data-state='checked'] > & {
    opacity: 1;
  }

  [data-state='checked']:focus-visible > & {
    color: var(--link-color);
  }
`

const Label = styled.span`
  display: inline-block;
  white-space: nowrap;
  text-align: center;

  user-select: none;

  transform: translateX(-50%);
`

const AccentText = styled.b`
  margin-left: var(--gutter-extra-small);

  color: var(--red);
  font-weight: var(--default-weight);
`

const LimitedColorBadge = styled(StarBadge)`
  position: absolute;
  top: 3px;
  left: 3px;
  font-size: 12px;

  transform-origin: top left;
`
