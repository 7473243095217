import styled from '@emotion/styled/macro'
import * as React from 'react'
import { forwardRef } from 'react'

interface StarBadgeProps {
  className?: string
}

// eslint-disable-next-line react/display-name
export const StarBadge = forwardRef<SVGSVGElement, StarBadgeProps>(
  (props, ref) => (
    <Svg
      {...props}
      ref={ref}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label=""
    >
      <path
        d="M5.01978 1.29355L5.01976 1.29354L5.01777 1.29761L3.91845 3.54699L1.43148 3.90228L1.43148 3.90225L1.42616 3.90307C0.512688 4.0436 0.201955 5.15066 0.830707 5.77941L0.830691 5.77943L0.834123 5.78279L2.61069 7.52552L2.18768 9.97897L2.1876 9.97896L2.18623 9.9879C2.04973 10.8751 2.99369 11.5589 3.79069 11.1431L3.79241 11.1422L6.00846 9.97495L8.20619 11.1414L8.20618 11.1414L8.20932 11.1431C9.00631 11.5589 9.95027 10.8751 9.81377 9.9879L9.81385 9.98789L9.81232 9.97897L9.38931 7.52552L11.1659 5.78279L11.1659 5.78281L11.1693 5.77941C11.798 5.15066 11.4873 4.0436 10.5738 3.90307L10.5738 3.90304L10.569 3.90234L8.09847 3.547L6.98227 1.29768C6.59664 0.498879 5.43114 0.470836 5.01978 1.29355Z"
        fill="#CF4925"
        stroke="white"
      />
    </Svg>
  )
)

const Svg = styled.svg`
  width: 1em;
  height: auto;
`
