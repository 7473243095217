/** @jsx jsx */
import * as React from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'

// Props
export interface Props {
  id?: string
  className?: string

  /**
   * ボタンの形状
   * @default rounded
   */
  shape?: 'rounded' | 'circle'

  /**
   * カラーテーマ
   * @default white
   */
  colorTheme?: 'white' | 'blue' | 'red'

  /**
   * ボタンが有効かどうか
   * @default false
   */
  disabled?: boolean

  /** クリックイベント */
  onClick?: () => any
}

// Themes
const whiteTheme = {
  color: 'var(--black)',
  background: 'var(--white)',
  hoverBackground: '#ededed'
}
const blueTheme = {
  color: 'var(--white)',
  background: 'var(--primary-color)',
  hoverBackground: 'var(--primary-color-dark)'
}
const redTheme = {
  color: 'var(--white)',
  background: 'var(--red-400)',
  hoverBackground: 'var(--red-100)'
}

// JSX
/**
 * 汎用的に使用するフローティングスタイルのボタン
 */
export const FloatingButton: React.FC<Props> = ({
  id,
  children,
  className,
  shape = 'rounded',
  colorTheme = 'white',
  disabled = false,
  onClick
}) => {
  const StyledButton = shape === 'rounded' ? Rounded : Circle
  const theme =
    colorTheme === 'white'
      ? whiteTheme
      : colorTheme === 'red'
      ? redTheme
      : blueTheme

  return (
    <StyledButton
      id={id}
      className={className}
      css={base(theme)}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

export default FloatingButton

// Style
const base = (theme: any) => css`
  appearance: none;
  color: ${theme.color};
  background: ${theme.background};
  border: 0;
  box-sizing: border-box;
  box-shadow: 4px 4px 8px rgba(160, 160, 160, 0.35);
  text-align: center;
  transition-property: box-shadow, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  &:hover {
    background: ${theme.hoverBackground};
  }

  &:active {
    box-shadow: 1px 1px 3px rgba(160, 160, 160, 0.35);
  }

  &:disabled {
    color: var(--text-sub);
    background: #e1e1e1;
  }
`

const Rounded = styled.button`
  display: inline-block;
  padding: var(--gutter-medium);
  border-radius: 50px;
`

const Circle = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`
