import { keyframes } from '@emotion/react'
import styled from '@emotion/styled/macro'
import * as React from 'react'
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { useDialogState } from '~/components/atoms/Dialog'
import { Loading } from '~/components/atoms/Loading'
import { RadioButton, RadioButtonGroup } from '~/components/atoms/RadioButton'
import { ColorSelector } from '~/components/molecules/ColorSelector'
import { CustomizeOverviewDialog } from '~/components/organisms/CustomizeOverviewDialog'
import { Navigation, useScene } from '~/components/organisms/Navigation'
import { PreviewRenderer } from '~/components/organisms/PreviewRenderer'
import { PrintName } from '~/components/organisms/PrintName'
import {
  ShareDialog,
  useShareDialogState
} from '~/components/organisms/ShareDialog'
import { SimpleTwoSectionLayout } from '~/components/templates/SimpleTwoSectionLayout'
import type { BootstrapMode } from '~/hooks/useBootstrapMode'
import { useFeatureFlags } from '~/hooks/useFeatureFlags'
import { useFLIP } from '~/hooks/useFLIP'
import { useToast } from '~/hooks/useToast'
import { useViewportState } from '~/hooks/useViewportState'
import type { Color } from '~/types/Color'
import type { Customization, ReadyCustomization } from '~/types/Customization'
import { Design, DirectionX, View } from '~/types/Design'
import type { Product } from '~/types/Product'
import { PrintType } from '~/types/PrintType'
import { isExpired } from '~/utils/isExpired'
import { toShareUrl, parseReadyCustomization } from '~/utils/customization'
import { mqHorizontal } from '~/utils/mediaQuery'

import { ZoomButton } from './ZoomButton'

const PRINT_NAME_PLACEHOLDER = 'NAME'

interface LoadingStateProps {
  loading: true

  message?: ReactNode
}

interface LoadedStateProps {
  loading?: false

  /**
   * 起動モード
   */
  mode: BootstrapMode

  /**
   * 変更系の操作を行っているかどうか
   *
   * 文字列や Element を指定した場合はメッセージとして表示する。
   */
  mutating?: boolean | ReactNode

  colors: readonly Color[]
  customization: Customization
  design: Design
  product: Product

  onCustom(customization: Customization): void

  /**
   * カスタマイズが完了した状態でマイデザインに保存するボタンが押された
   */
  onSaveAsMyDesign(customization: ReadyCustomization): void

  /**
   * カスタマイズが完了した状態でカートに入れるボタンが押された
   */
  onAddToCart(customization: ReadyCustomization): void
}

type CustomizePageProps = LoadingStateProps | LoadedStateProps

const VIEWPORT_SCALE_MAX = 3

export const CustomizePage = (props: CustomizePageProps) => {
  if (props.loading) {
    return <Loading visible message={props.message} />
  }

  return <LoadedState {...props} />
}

const LoadedState = ({
  mode,
  colors,
  customization,
  design,
  product,
  mutating = false,
  onCustom,
  onSaveAsMyDesign,
  onAddToCart
}: LoadedStateProps) => {
  const featureFlags = useFeatureFlags()

  const scene = useScene({ design })

  // 表示する視点のID (型的には `null` になりえるが、基本はない)
  const viewId = useMemo<string | null>(() => {
    switch (scene.current.type) {
      case 'part': {
        // TSはコールバックがいつ実行されるかわからないため、同期的に実行されるコールバックでも
        // プロパティ参照は Narrowing されないためキャスト/アサーションが必要
        const part = design.parts.find(
          (p) => p.id === (scene.current as typeof scene.current).part.id
        )

        return part?.viewId ?? null
      }
      case 'print_logo': {
        return design.printLogo?.id ?? null
      }
      case 'print_name': {
        return design.printName?.id ?? null
      }
    }
  }, [design.parts, design.printLogo, design.printName, scene.current])

  // 表示する視点
  const view = useMemo<View>(() => {
    return design.views.find((view) => view.id === viewId) ?? design.views[0]
  }, [design.views, viewId])

  const rendererRef = useRef<HTMLDivElement>(null)

  const [overviewDialogProps, overviewDialog] = useDialogState()
  const [shareDialogProps, shareDialog] = useShareDialogState()

  // シーン種別が変わるとアクション部分の高さが変わるため、プレビューをFLIPアニメーションする
  const { currentScene } = useFLIP({
    props: { currentScene: scene.current },
    flip: {
      first() {
        return rendererRef.current?.getBoundingClientRect()
      },
      last() {
        return rendererRef.current?.getBoundingClientRect()
      },
      invert({ first, last }) {
        if (!first?.height || !last?.height) {
          return { s: 1, ty: 0 }
        }

        return {
          s: first.height / last.height,
          ty: first.y - last.y
        }
      },
      play({ invert: { s, ty } }) {
        rendererRef.current?.animate(
          [
            { transform: `translateY(${ty}px) scale(${s})` },
            { transform: `translateY(0px) scale(1)` }
          ],
          {
            duration: 200,
            easing: 'ease-out'
          }
        )
      }
    }
  })

  const viewport = useViewportState<HTMLDivElement>({
    disabled: !!mutating,
    scaleMax: VIEWPORT_SCALE_MAX,
    touchDragGestureFingers: featureFlags.requireTwoFingersForDragGesture
      ? 2
      : 1
  })

  const [viewImageSize, setViewImageSize] = useState<Readonly<{
    width: number
    height: number
  }> | null>(null)

  // シーンが変わったらビューポートの状態をリセットする
  useEffect(() => {
    viewport.reset()
  }, [currentScene.id])

  // ビュー画像が変わったらサイズをリセットする
  useEffect(() => {
    setViewImageSize(null)
  }, [view.baseImage])

  const viewportOffset = useMemo<{
    x: number
    y: number
    scale: number
  }>(() => {
    switch (currentScene.type) {
      case 'part':
        return {
          x: 0,
          y: 0,
          scale: 1
        }
      case 'print_logo': {
        if (!design.printLogo || !viewImageSize) {
          return {
            x: 0,
            y: 0,
            scale: 1
          }
        }

        const {
          position: { x, y },
          anchor,
          width,
          height
        } = design.printLogo

        const cx =
          anchor.x === DirectionX.Left
            ? x + width / 2
            : anchor.x === DirectionX.Right
            ? x - width / 2
            : x
        const cy = y - height / 2

        const scale =
          width > height
            ? viewImageSize.width / 2 / width
            : viewImageSize.height / 2 / height

        return {
          x: 0.5 - cx / viewImageSize.width,
          y: 0.5 - cy / viewImageSize.height,
          scale
        }
      }
      case 'print_name': {
        if (!design.printName || !viewImageSize) {
          return {
            x: 0,
            y: 0,
            scale: 1
          }
        }

        const {
          position: { x, y },
          anchor,
          fontSize,
          maxLength
        } = design.printName

        const width = fontSize * maxLength
        const height = fontSize

        const cx =
          anchor.x === DirectionX.Left
            ? x + width / 2
            : anchor.x === DirectionX.Right
            ? x - width / 2
            : x
        const cy = y - height / 2

        const scale =
          width > height
            ? viewImageSize.width / 2 / width
            : viewImageSize.height / 2 / height

        return {
          x: 0.5 - cx / viewImageSize.width,
          y: 0.5 - cy / viewImageSize.height,
          scale
        }
      }
    }
  }, [currentScene.type, design.printLogo, design.printName, viewImageSize])

  // オフセットによるスケールが必要な場合はスケールする
  useEffect(() => {
    viewport.scale(viewportOffset.scale)
  }, [viewportOffset.scale])

  const printLogoOptions = useMemo<Record<PrintType, boolean>>(() => {
    if (!design.printLogo) {
      return {
        [PrintType.Embossing]: false,
        [PrintType.SilverPressing]: false,
        [PrintType.GoldPressing]: false
      }
    }

    return {
      [PrintType.Embossing]: design.printLogo.printTypes.some(
        (t) => t.printType === 'stamp'
      ),
      [PrintType.SilverPressing]: design.printLogo.printTypes.some(
        (t) => t.printType === 'silver_emboss'
      ),
      [PrintType.GoldPressing]: design.printLogo.printTypes.some(
        (t) => t.printType === 'gold_emboss'
      )
    }
  }, [design.printLogo?.printTypes])

  const availableColors = useMemo<readonly Color[]>(() => {
    if (currentScene.type !== 'part') {
      return []
    }

    const part = design.parts.find((p) => p.id === currentScene.part.id)
    if (!part) {
      return []
    }

    return colors.filter(
      (color) =>
        color.material.id === part.material.id &&
        (!color.endAt || !isExpired(color.endAt))
    )
  }, [
    colors,
    design.parts,
    currentScene.type === 'part' && currentScene.part.id
  ])

  const toast = useToast()

  const readyCustomization = useMemo(
    () => parseReadyCustomization(customization, design),
    [customization, design]
  )

  const parseThen = useCallback(
    (callback: (readyData: ReadyCustomization) => void) => {
      if (!readyCustomization.ok) {
        toast.show({
          variant: 'info',
          title: 'カスタマイズがまだ終わっていません',
          description: (
            <ErrorList>
              {readyCustomization.error.map((error, i) => {
                switch (error.type) {
                  case 'logo_not_selected':
                    return <li key={i}>ロゴを選んでください</li>
                  case 'name_not_selected':
                    return <li key={i}>名入れ刻印の種類を選んでください</li>
                  case 'name_text_empty':
                    return <li key={i}>名入れ刻印する文字を入力してください</li>
                  case 'name_text_invalid':
                    return (
                      <li key={i}>
                        名入れ刻印に利用できない文字が含まれています
                      </li>
                    )
                  case 'parts_not_selected':
                    return (
                      <li key={i}>全てのパーツにカラーを設定してください</li>
                    )
                }
              })}
            </ErrorList>
          )
        })
        return
      }

      callback(readyCustomization.data)
    },
    [readyCustomization, toast.show]
  )

  return (
    <Fragment>
      <SimpleTwoSectionLayout
        controls={
          <Navigation
            disabled={!!mutating}
            cartButtonLabel={
              mode.type === 'cart_item_update' ||
              mode.type === 'local_cart_item_update'
                ? 'カートを更新'
                : 'カートに入れる'
            }
            colors={colors}
            customization={customization}
            isReady={readyCustomization.ok}
            scene={scene}
            onAddToCart={() => {
              parseThen((payload) => {
                onAddToCart(payload)
              })
            }}
            onSave={() => {
              parseThen((payload) => {
                onSaveAsMyDesign(payload)
              })
            }}
            onShare={() => {
              const url = toShareUrl(customization, {
                design,
                product,
                baseUrl: process.env.REACT_APP_PORTAL_URL || 'https://joggo.jp'
              })

              const shareData = {
                url: url.toString(),
                title: product.name
              }

              if (typeof navigator.share === 'function') {
                navigator.share(shareData).catch((err) => {
                  // ユーザによるキャンセル、もしくはシェア対象が存在しない
                  // 明らかに別の理由であり混ぜるべきではないが、仕様レベルでおかしいため仕方がない
                  if (
                    err instanceof DOMException &&
                    err.name === 'AbortError'
                  ) {
                    toast.show({
                      title:
                        'シェアがキャンセルされたか、シェアできる手段が見つかりませんでした',
                      description: err.message,
                      variant: 'warning'
                    })
                    return
                  }

                  toast.show({
                    title: 'シェア機能の呼び出しに失敗しました',
                    description:
                      err instanceof DOMException
                        ? err.name
                        : err instanceof Error
                        ? err.message
                        : String(err),
                    variant: 'error'
                  })
                })
              } else {
                shareDialog.open(shareData)
              }
            }}
          />
        }
        preview={
          <PreviewContainer>
            <PreviewRenderResultViewport ref={viewport.gestureTarget}>
              <PreviewRenderResultSprite
                data-gesture-state={viewport.isGesturing ? 'gesture' : 'none'}
                style={{
                  transform: [
                    `translate(${viewport.value.tx}px, ${viewport.value.ty}px)`,
                    `scale(${viewport.value.scale})`,
                    `translate(${viewportOffset.x * 100}%, ${
                      viewportOffset.y * 100
                    }%)`
                  ].join(' ')
                }}
              >
                <PreviewRendererWithAnimation
                  key={view.id}
                  ref={rendererRef}
                  colors={colors}
                  design={design}
                  view={view}
                  currentScene={scene.current}
                  customization={customization}
                  printNamePlaceholder={PRINT_NAME_PLACEHOLDER}
                  onLoadBaseImage={(img) => {
                    setViewImageSize({
                      width: img.naturalWidth,
                      height: img.naturalHeight
                    })
                  }}
                />
              </PreviewRenderResultSprite>
            </PreviewRenderResultViewport>
            <PreviewActions>
              <PreviewZoomButton
                disabled={!!mutating}
                scale={viewport.value.scale}
                min={1}
                max={VIEWPORT_SCALE_MAX}
                step={0.5}
                onScaleChange={viewport.scale}
              />
              {featureFlags.overviewDialog && (
                <PreviewActionButton onClick={() => overviewDialog.open()}>
                  完成イメージ
                </PreviewActionButton>
              )}
            </PreviewActions>
            <PreviewControlSection>
              {currentScene.type === 'part' ? (
                <ColorSelector
                  disabled={!!mutating}
                  value={
                    customization.parts[currentScene.part.id]?.color ?? null
                  }
                  colors={availableColors}
                  onChange={(color) => {
                    if (currentScene.type !== 'part') {
                      return
                    }

                    onCustom({
                      ...customization,
                      parts: {
                        ...customization.parts,
                        [currentScene.part.id]: { color: { id: color.id } }
                      }
                    })
                  }}
                />
              ) : currentScene.type === 'print_logo' ? (
                <LogoAction>
                  <PlaceholderLabel
                    data-hidden={!!customization.printLogo || undefined}
                  >
                    ロゴの種類を選んでください
                  </PlaceholderLabel>
                  <RadioButtonGroup
                    value={customization.printLogo?.printType}
                    onValueChange={(printType: PrintType) => {
                      onCustom({
                        ...customization,
                        printLogo: { printType }
                      })
                    }}
                  >
                    {printLogoOptions[PrintType.GoldPressing] && (
                      <RadioButton
                        value={PrintType.GoldPressing}
                        disabled={!!mutating}
                      >
                        ゴールド
                      </RadioButton>
                    )}
                    {printLogoOptions[PrintType.SilverPressing] && (
                      <RadioButton
                        value={PrintType.SilverPressing}
                        disabled={!!mutating}
                      >
                        シルバー
                      </RadioButton>
                    )}
                    {printLogoOptions[PrintType.Embossing] && (
                      <RadioButton
                        value={PrintType.Embossing}
                        disabled={!!mutating}
                      >
                        型押し
                      </RadioButton>
                    )}
                  </RadioButtonGroup>
                </LogoAction>
              ) : currentScene.type === 'print_name' ? (
                <PrintNameWithSpaces
                  disabled={!!mutating}
                  design={design}
                  customization={customization}
                  placeholder={PRINT_NAME_PLACEHOLDER}
                  onCustom={onCustom}
                />
              ) : null}
            </PreviewControlSection>
          </PreviewContainer>
        }
      />
      <CustomizeOverviewDialog
        colors={colors}
        design={design}
        customization={customization}
        {...overviewDialogProps}
      />
      <ShareDialog {...shareDialogProps} />
      <MutationLoading
        visible={!!mutating}
        message={typeof mutating === 'string' ? mutating : undefined}
      />
    </Fragment>
  )
}

const PreviewContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  overflow: hidden;
`

const PreviewRenderResultViewport = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
  min-width: 0;

  overflow: visible;
  touch-action: pan-y;
`

const PreviewRenderResultSprite = styled.div`
  aspect-ratio: 1 / 1;
  max-width: 100%;
  max-height: 100%;

  &[data-gesture-state='none'] {
    transition: transform 0.25s ease-out;
  }
`

const PreviewControlSection = styled.div`
  position: relative;
  padding: 16px 0;

  background-color: hsla(0, 0%, 93%, 0.2);
  backdrop-filter: blur(4px);

  ${mqHorizontal} and (min-height: 600px) {
    padding-bottom: 64px;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const PreviewRendererWithAnimation = styled(PreviewRenderer)`
  animation: 0.6s 0s ease-out 1 both ${fadeIn};
`

const PreviewActions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--gutter-medium);
  display: flex;
  justify-content: space-between;
  align-items: center;

  pointer-events: none;
`

const PreviewZoomButton = styled(ZoomButton)`
  pointer-events: all;
`

const PreviewActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: var(--gutter-extra-small);
  padding: var(--gutter-small) var(--gutter-medium);
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.05em;

  background-color: var(--gray-0);
  border-radius: 24px;
  color: var(--black-700);
  pointer-events: all;

  &:focus-visible {
    outline: 1px dotted currentColor;
  }
`

const LogoAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gutter-medium);
`

const PlaceholderLabel = styled.span`
  &[data-hidden] {
    visibility: hidden;
  }
`

const PrintNameWithSpaces = styled(PrintName)`
  max-width: 375px;
  padding: 0 var(--gutter-medium);
  margin: 0 auto;
`

const ErrorList = styled.ul`
  padding-left: 1em;

  list-style: disc;
  text-align: left;
  text-align: start;
`

const MutationLoading = styled(Loading)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: hsla(0, 0%, 100%, 0.9);

  z-index: 999;
`
