import styled from '@emotion/styled/macro'
import * as React from 'react'
import type { ReactNode } from 'react'
import PuffLoader from 'react-spinners/PuffLoader'

interface LoadingProps {
  className?: string

  /**
   * 表示するかどうか
   */
  visible?: boolean

  /**
   * 大きさ (px)
   */
  size?: number

  /**
   * 何を読み込んでいるか伝えるメッセージ
   */
  message?: ReactNode
}

export const Loading = ({
  visible = false,
  size = 60,
  message = null,
  ...rest
}: LoadingProps) => (
  <Wrapper
    {...rest}
    aria-hidden={!visible}
    aria-label={typeof message === 'string' ? undefined : '読込中です'}
  >
    {message && <Message>{message}</Message>}
    <PuffLoader size={size} color="var(--primary-color)" loading={visible} />
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gutter-small);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: var(--gray-0);
  z-index: 999;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s linear;

  &[aria-hidden='false'] {
    opacity: 1;
    pointer-events: auto;
  }
`

const Message = styled.span`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
`
