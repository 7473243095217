export interface FeatureFlags {
  /**
   * プレビューのジェスチャーを行う際に2本指でのタッチを必要とするかどうか
   */
  requireTwoFingersForDragGesture: boolean

  /**
   * 全体を確認するダイアログを利用可能にするかどうか
   */
  overviewDialog: boolean
}

export const defaultFeatureFlags: Readonly<FeatureFlags> = {
  requireTwoFingersForDragGesture: true,
  overviewDialog: true
}
