/** @jsx jsx */
import { Fragment, ReactNode, Children } from 'react'
import styled from '@emotion/styled/macro'
import { jsx } from '@emotion/react'

import { Icon } from '~/components/atoms/Icon'
import { mqHorizontal } from '~/utils/mediaQuery'

interface StepMenuProps {
  children: ReactNode[]
}

export const StepMenu = ({ children }: StepMenuProps) => {
  const validChildren = Children.toArray(children).filter(Boolean)

  return (
    <List
      style={{
        counterReset: `current total ${validChildren.length}`
      }}
    >
      {validChildren.map((child, i) =>
        i === 0 ? (
          child
        ) : (
          <Fragment key={i}>
            <ArrowIcon type="long-arrow-down" />
            {child}
          </Fragment>
        )
      )}
    </List>
  )
}

const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin: 0;
  gap: var(--gutter-small);

  overflow: visible;

  ${mqHorizontal} {
    flex-direction: column;
    align-items: stretch;
  }
`

const ArrowIcon = styled(Icon)`
  transform: rotate(-90deg);

  ${mqHorizontal} {
    transform: rotate(0deg);
  }
`
