import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import {
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

const useGesture = createUseGesture([dragAction, pinchAction])

interface ViewportState {
  scale: number
  tx: number
  ty: number
}

interface UseViewportStateParams {
  disabled?: boolean

  defaultValue?: Partial<ViewportState>

  scaleMin?: number
  scaleMax?: number

  touchDragGestureFingers?: number
}

interface UseViewportStateReturns<T extends HTMLElement | SVGSVGElement> {
  value: ViewportState

  isGesturing: boolean

  scale(value: SetStateAction<number>): void
  move(tx: number, ty: number): void
  reset(): void

  gestureTarget: RefObject<T>
}

export function useViewportState<T extends HTMLElement | SVGSVGElement>({
  disabled = false,
  defaultValue: { scale = 1, tx = 0, ty = 0 } = {},
  scaleMin = 1,
  scaleMax = 2,
  touchDragGestureFingers = 1
}: UseViewportStateParams = {}): UseViewportStateReturns<T> {
  const [value, setValue] = useState<ViewportState>(() => ({ scale, tx, ty }))
  const [isDragging, setIsDragging] = useState(false)
  const [isPinching, setIsPinching] = useState(false)

  const clipScale = useCallback(
    (v: number) => Math.max(scaleMin, Math.min(scaleMax, v)),
    [scaleMin, scaleMax]
  )

  const setScale = useCallback(
    (scale: SetStateAction<number>) => {
      setValue((prev) => ({
        ...prev,
        scale: clipScale(
          typeof scale === 'function' ? scale(prev.scale) : scale
        )
      }))
    },
    [clipScale]
  )

  const move = useCallback((tx: number, ty: number) => {
    setValue((prev) => ({ ...prev, tx, ty }))
  }, [])

  const gestureTarget = useRef<T>(null)

  useGesture(
    {
      onDragStart() {
        setIsDragging(true)
      },
      onDrag({ event, type, touches, delta: [dx, dy] }) {
        const isTouch = /touch/.test(type)

        // `touches` が `0` のときにも呼ばれることがあるため、 `0` の場合はスキップさせない
        if (isTouch && touches > 0 && touches < touchDragGestureFingers) {
          return
        }

        event.preventDefault()
        event.stopPropagation()

        setValue(({ scale, tx, ty }) => ({
          scale,
          tx: tx + dx,
          ty: ty + dy
        }))
      },
      onDragEnd() {
        setIsDragging(false)
      },
      onPinchStart() {
        setIsPinching(true)
      },
      onPinch({ event, memo, offset: [scale] }) {
        event.preventDefault()
        event.stopPropagation()

        if (typeof memo === 'number') {
          setValue((prev) => ({
            ...prev,
            scale: clipScale(prev.scale * (scale / memo))
          }))
        }

        return scale
      },
      onPinchEnd() {
        setIsPinching(false)
      }
    },
    {
      enabled: !disabled,
      target: gestureTarget,
      drag: {
        pointer: {
          touch: true
        }
      },
      eventOptions: { passive: false }
    }
  )

  useEffect(() => {
    const preventDefault = (e: Event) => {
      e.preventDefault()
    }

    document.addEventListener('gesturestart', preventDefault)
    document.addEventListener('gesturechange', preventDefault)

    return () => {
      document.removeEventListener('gesturestart', preventDefault)
      document.removeEventListener('gesturechange', preventDefault)
    }
  }, [])

  return {
    value,
    scale: setScale,
    move,
    reset: useCallback(() => {
      setValue({ scale, tx, ty })
    }, [scale, tx, ty]),
    gestureTarget,
    isGesturing: isDragging || isPinching
  }
}
