import { useCallback, useEffect, useRef } from 'react'

interface UseLeaveConfirmationParams {
  /**
   * 離脱時に確認ポップアップを出すかどうか
   */
  enabled?: boolean

  /**
   * ユーザに表示するメッセージ
   *
   * このメッセージがユーザに表示されるという保証はない。
   * 殆どのプラットフォームでは表示されない。
   */
  message?: string
}

interface UseLeaveConfirmationReturns {
  /**
   * 確認ポップアップ表示を一時的に無効にして処理を行う
   */
  passThrough(callback: () => Promise<void> | void): void
}

/**
 * ページ離脱前に本当に離脱していいかの確認をとる
 */
export function useLeaveConfirmation({
  enabled = true,
  message = '離脱してよろしいですか？'
}: UseLeaveConfirmationParams = {}): UseLeaveConfirmationReturns {
  const isSuppressing = useRef(false)

  useEffect(() => {
    if (!enabled) {
      return
    }

    const callback = (ev: BeforeUnloadEvent) => {
      if (isSuppressing.current) {
        return
      }

      ev.preventDefault()
      ev.returnValue = message

      return message
    }

    window.addEventListener('beforeunload', callback)

    return () => {
      window.removeEventListener('beforeunload', callback)
    }
  }, [enabled, message])

  const passThrough = useCallback<UseLeaveConfirmationReturns['passThrough']>(
    (cb) => {
      isSuppressing.current = true

      const ret = cb()
      if (!ret) {
        isSuppressing.current = false
        return
      }

      ret
        .catch((error) => {
          console.error(error)
        })
        .then(() => {
          isSuppressing.current = false
        })
    },
    []
  )

  return { passThrough }
}
