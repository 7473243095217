import * as React from 'react'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { FeatureFlags, defaultFeatureFlags } from '~/featureFlags'

const Context = createContext<Readonly<FeatureFlags>>(defaultFeatureFlags)

export const useFeatureFlags = () => useContext(Context)

function parseValue(
  value: string
): { ok: true; value: boolean } | { ok: false } {
  switch (value) {
    case 'true':
      return { ok: true, value: true }
    case 'false':
      return { ok: true, value: false }
    default:
      return { ok: false }
  }
}

interface HTMLElementFeatureFlagsProviderProps {
  children: ReactNode

  element: HTMLElement | string | null | undefined

  defaultValue?: Readonly<Partial<FeatureFlags>>
}

export const HTMLElementFeatureFlagsProvider = ({
  element: elementOrSelector,
  defaultValue = {},
  children
}: HTMLElementFeatureFlagsProviderProps) => {
  const [value, setValue] = useState<FeatureFlags>(() => ({
    ...defaultFeatureFlags,
    ...defaultValue
  }))

  const element = useMemo(() => {
    if (!elementOrSelector) {
      return null
    }

    if (typeof elementOrSelector === 'string') {
      return document.querySelector(elementOrSelector)
    }

    return elementOrSelector
  }, [elementOrSelector])

  useEffect(() => {
    if (!element) {
      return
    }

    try {
      const observer = new MutationObserver(() => {
        const children = Array.from(
          element.querySelectorAll('div[data-key][data-value]')
        )

        const newValue: FeatureFlags = {
          ...defaultFeatureFlags,
          ...defaultValue
        }

        for (const child of children) {
          const key = child.getAttribute('data-key')
          if (!key || !Object.prototype.hasOwnProperty.call(newValue, key)) {
            // 不正なキー名
            continue
          }

          const value = parseValue(child.getAttribute('data-value') || '')
          if (!value.ok) {
            continue
          }

          newValue[key as keyof FeatureFlags] = value.value
        }

        setValue(newValue)
      })

      observer.observe(element, {
        attributes: true,
        attributeFilter: ['data-key', 'data-value'],
        subtree: true
      })

      return () => {
        observer.disconnect()
      }
    } catch (err) {
      console.group('Failed to listen feature flags changes.')
      console.warn(err)
      console.groupEnd()
    }
  }, [element])

  return <Context.Provider value={value}>{children}</Context.Provider>
}
