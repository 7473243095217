import * as React from 'react'
import { useEffect, useState } from 'react'

import { verifyAccessToken } from '~/api/account'
import {
  BootstrapErrorPage,
  FetchErrorBody
} from '~/components/pages/BootstrapErrorPage'
import { CustomizePage } from '~/components/pages/CustomizePage'
import { useAsyncResult } from '~/hooks/useAsyncResult'
import { useSession } from '~/hooks/useSession'
import { deleteToken } from '~/utils/cookie'

const enum StateKind {
  NoAccessToken,
  Verifying,
  AccessTokenValid,
  AccessTokenObsolete,
  VerifyError
}

type State =
  | { type: StateKind.NoAccessToken }
  | { type: StateKind.Verifying }
  | { type: StateKind.AccessTokenValid }
  | { type: StateKind.AccessTokenObsolete }
  | { type: StateKind.VerifyError; error: Error }

interface ValidAccessTokenGuardProps {
  children: JSX.Element
}

/**
 * アクセストークンがちゃんと使えることを確認してからアプリケーションを起動するためのコンポーネント
 *
 * - アクセストークンが保存されていない場合は `children` をそのままレンダリングする
 * - アクセストークンが保存されている場合、APIの要認証エンドポイントを叩いてから `children` をそのままレンダリングする
 * - APIコール時に想定されないエラーが返ってきた場合はエラー画面を表示する
 *
 * もしもAPIコールの結果認証エラーが返ってきた場合はアクセストークンを削除してから
 * `children` をレンダリングする。
 */
export const ValidAccessTokenGuard = ({
  children
}: ValidAccessTokenGuardProps) => {
  const session = useSession()

  const [retry, setRetry] = useState(0)

  const [state, setState] = useState<State>(() => {
    if (!session.isLoggedIn) {
      return { type: StateKind.NoAccessToken }
    }

    return { type: StateKind.Verifying }
  })

  useEffect(() => {
    if (!session.isLoggedIn) {
      return
    }

    setState({ type: StateKind.Verifying })

    verifyAccessToken(session.accessToken.token)
      .then(({ valid }) => {
        if (!valid) {
          deleteToken()
        }

        setState(
          valid
            ? { type: StateKind.AccessTokenValid }
            : { type: StateKind.AccessTokenObsolete }
        )
      })
      .catch((err) => {
        setState({
          type: StateKind.VerifyError,
          error: err instanceof Error ? err : new Error(String(err))
        })
      })
  }, [session.isLoggedIn, retry])

  switch (state.type) {
    case StateKind.Verifying:
      return (
        <CustomizePage loading message="ログイン状態をチェックしています" />
      )
    case StateKind.NoAccessToken:
    case StateKind.AccessTokenObsolete:
    case StateKind.AccessTokenValid:
      return children
    case StateKind.VerifyError:
      return (
        <BootstrapErrorPage title="ログイン状態の取得に失敗しました">
          <FetchErrorBody
            error={state.error}
            onRetry={() => {
              setRetry((n) => n + 1)
            }}
          />
        </BootstrapErrorPage>
      )
  }
}
