import type { Customization, ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import { validate as validatePrintName } from '~/utils/printName'

import { Result, ok, err } from './Result'

type ParseError =
  | { type: 'logo_not_selected' }
  | { type: 'name_not_selected' }
  | { type: 'name_text_empty' }
  | { type: 'name_text_invalid' }
  | { type: 'parts_not_selected' }

function parseLogo(
  customization: Customization['printLogo'],
  design: Design
): Result<ReadyCustomization['printLogo'], ParseError> {
  if (!design.printLogo) {
    return ok(null)
  }

  if (!customization) {
    return err({ type: 'logo_not_selected' })
  }

  return ok(customization)
}

function parseName(
  customization: Customization['printName'],
  design: Design
): Result<ReadyCustomization['printName'], ParseError> {
  if (!design.printName || customization === null) {
    return ok(null)
  }

  if (typeof customization === 'undefined') {
    return err({ type: 'name_not_selected' })
  }

  if (customization.printType === null) {
    return ok(null)
  }

  if (!customization.text) {
    return err({ type: 'name_text_empty' })
  }

  if (!validatePrintName(customization.text).ok) {
    return err({ type: 'name_text_invalid' })
  }

  return ok(customization)
}

function parseParts(
  customization: Customization['parts'],
  design: Design
): Result<ReadyCustomization['parts'], ParseError> {
  const ret: ReadyCustomization['parts'] = {}

  for (const part of design.parts) {
    const c = customization[part.id]

    if (!c) {
      return err({ type: 'parts_not_selected' })
    }

    ret[part.id] = c
  }

  return ok(ret)
}

/**
 * カスタマイズ状態を受け取り、注文可能な状態であればカスタマイズデータを、そうでなければエラーを返す
 *
 * なぜバリデーションではなくパースをしているかについては以下の記事を参照。
 * [Parse, don’t validate](https://lexi-lambda.github.io/blog/2019/11/05/parse-don-t-validate/)
 */
export function parseReadyCustomization(
  customization: Customization,
  design: Design
): Result<ReadyCustomization, ParseError[]> {
  const parts = parseParts(customization['parts'], design)
  const logo = parseLogo(customization['printLogo'], design)
  const name = parseName(customization['printName'], design)

  if (!parts.ok || !logo.ok || !name.ok) {
    return err(
      [
        logo.ok ? null : logo.error,
        name.ok ? null : name.error,
        parts.ok ? null : parts.error
      ].filter((err): err is Exclude<typeof err, null> => !!err)
    )
  }

  return ok({
    parts: parts.data,
    printLogo: logo.data,
    printName: name.data
  })
}
