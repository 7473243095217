import styled from '@emotion/styled/macro'
import * as React from 'react'
import { useEffect } from 'react'

import { useToast } from '~/hooks/useToast'
import type { CustomizationCreationWarning } from '~/utils/customization'

interface CustomizationCreationWarningsProps {
  warnings: readonly CustomizationCreationWarning[]
}

function warningToLabel(warning: CustomizationCreationWarning): string | null {
  switch (warning.type) {
    case 'expired_color':
      return `利用期間を過ぎたカラーの選択を解除しました: ${warning.color.name}`
    case 'color_unavailable_to_part':
      return `復元できないカラーがありました: ${warning.color.name}`
    case 'invalid_print_name_text':
      return `名入れに使用できない文字があったため、復元できませんでした。`
    default:
      return null
  }
}

/**
 * カスタマイズデータ生成処理から吐かれた警告メッセージをユーザに伝えるコンポーネント
 */
export const CustomizationCreationWarnings = ({
  warnings
}: CustomizationCreationWarningsProps) => {
  const toast = useToast()

  useEffect(() => {
    if (!warnings.length) {
      return
    }

    const messages = warnings
      .map((warning) => warningToLabel(warning))
      .filter((label): label is string => !!label)

    if (!messages.length) {
      return
    }

    toast.show({
      variant: 'warning',
      title: 'カスタマイズ内容を完全に復元できませんでした',
      description: (
        <ErrorList>
          {messages.map((message, i) => (
            <li key={i}>{message}</li>
          ))}
        </ErrorList>
      )
    })
  }, [warnings])

  return null
}

const ErrorList = styled.ul`
  padding-left: 1em;

  list-style: disc;
  text-align: left;
  text-align: start;
`
