import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'

import { fetchDesign, fetchColors } from '~/api/customization'
import { fetchProduct, fetchProductList } from '~/api/products'
import { OutdatedVersionedItemWarning } from '~/components/organisms/OutdatedVersionedItemWarning'
import {
  BootstrapErrorPage,
  FetchErrorBody,
  ProductFetchErrorBody
} from '~/components/pages/BootstrapErrorPage'
import { CustomizePage } from '~/components/pages/CustomizePage'
import { TempDesignRestorePage } from '~/components/pages/TempDesignRestorePage'
import { useAsyncResult } from '~/hooks/useAsyncResult'
import {
  useCustomizationStorage,
  StorageItem
} from '~/hooks/useCustomizationStorage'
import { useToast } from '~/hooks/useToast'

import { Core } from './Core'
import { loadingMessages } from './loadingMessages'

interface StorageSelectionGuardProps {
  children: JSX.Element
}

/**
 * 一時保存データがあればユーザに確認をとる復元フローを追加するコンポーネント
 *
 * ユーザが復元せずに開始することを選んだ場合は `children` がレンダリングされる。
 */
export const StorageSelectionGuard = ({
  children
}: StorageSelectionGuardProps) => {
  const toast = useToast()
  const storage = useCustomizationStorage()

  const saveData = useAsyncResult(() => storage.getAll(), [])

  // 保存データ読み込み失敗時にはエラーをユーザに通知する
  useEffect(() => {
    if (saveData.status !== 'error') {
      return
    }

    console.error(saveData.error)
    toast.show({
      variant: 'error',
      title: '一時保存データの読み込みに失敗しました',
      description: String(saveData.error)
    })
  }, [saveData.status === 'error'])

  // 読み込みエラー時は選択画面を表示できない (表示しても仕方ない) ため、
  // 復元せずに開始する場合の内容を表示する。
  if (saveData.status === 'error') {
    return children
  }

  if (saveData.status !== 'ok') {
    return <CustomizePage loading message="一時保存データを読み込んでいます" />
  }

  if (saveData.result.length > 0) {
    return <WithItems items={saveData.result}>{children}</WithItems>
  }

  return children
}

interface WithItemsProps extends StorageSelectionGuardProps {
  items: readonly StorageItem[]
}

const WithItems = ({ items, children }: WithItemsProps) => {
  const toast = useToast()

  const [savedItems, setSavedItems] = useState(items)
  const storage = useCustomizationStorage()

  const [state, setState] = useState<
    | { state: 'selected'; item: StorageItem }
    | { state: 'create_new' }
    | { state: 'selecting'; isDeleting: boolean }
  >(() => ({ state: 'selecting', isDeleting: false }))

  const products = useAsyncResult(() => fetchProductList(), [])

  if (products.status === 'error') {
    return (
      <BootstrapErrorPage title="商品データを読み込めませんでした">
        <FetchErrorBody
          error={products.error}
          onRetry={() => products.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (products.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.product} />
  }

  switch (state.state) {
    case 'selecting': {
      if (!savedItems.length) {
        return children
      }

      return (
        <TempDesignRestorePage
          disabled={state.isDeleting}
          products={products.result}
          items={savedItems}
          onCreateNew={() => {
            setState({ state: 'create_new' })
          }}
          onDelete={async ({ id }) => {
            try {
              setState({
                state: 'selecting',
                isDeleting: true
              })

              const updated = await storage.delete({ id })

              setSavedItems(updated)

              setState({
                state: 'selecting',
                isDeleting: false
              })
            } catch (err) {
              toast.show({
                variant: 'error',
                title: '一時保存データを削除できませんでした',
                description: err instanceof Error ? err.message : String(err)
              })
            }
          }}
          onLoad={(item) => {
            setState({ state: 'selected', item })
          }}
        />
      )
    }
    case 'create_new': {
      return children
    }
    case 'selected': {
      return <SavedItem saveData={state.item} />
    }
  }
}

const SavedItem = ({ saveData }: { saveData: StorageItem }) => {
  const toast = useToast()
  const storage = useCustomizationStorage()

  const colors = useAsyncResult(() => fetchColors(), [])
  const product = useAsyncResult(
    () => fetchProduct(saveData.product.id),
    [saveData.product.id]
  )
  const design = useAsyncResult(
    () => fetchDesign(saveData.product.id),
    [saveData.product.id]
  )

  // 正常にカスタマイズできるようになったタイミングでストレージに保存してあるデータを削除する
  useEffect(() => {
    if (
      colors.status !== 'ok' ||
      product.status !== 'ok' ||
      design.status !== 'ok'
    ) {
      return
    }

    storage.delete(saveData).catch((err) => {
      console.warn(err)

      toast.show({
        variant: 'warning',
        title: '一時保存データの削除に失敗しました',
        description:
          '復元が完了したため保存済みのデータを削除しようとしましたが、エラーが発生して削除が完了できませんでした。' +
          '次回起動時に残っている場合は手動で削除してください。'
      })
    })
  }, [
    colors.status === 'ok',
    product.status === 'ok',
    design.status === 'ok',
    saveData.id
  ])

  if (colors.status === 'error') {
    return (
      <BootstrapErrorPage title="カラーの取得に失敗">
        <FetchErrorBody error={colors.error} onRetry={() => colors.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (product.status === 'error') {
    return (
      <BootstrapErrorPage title="商品データの読み込みに失敗">
        <ProductFetchErrorBody
          error={product.error}
          onRetry={() => product.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (design.status === 'error') {
    return (
      <BootstrapErrorPage title="デザインデータの読み込みに失敗">
        <FetchErrorBody error={design.error} onRetry={() => design.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (colors.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.colors} />
  }

  if (product.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.product} />
  }

  if (design.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.design} />
  }

  return (
    <Fragment>
      <Core
        mode={
          // FIXME: ローカル復元用のモードを作成する (セマンティクス的に "invalid" はおかしいので)
          { type: 'invalid_bootstrap_mode' }
        }
        initialCustomization={saveData.customization}
        colors={colors.result}
        product={product.result}
        design={design.result}
        restoredFromLocalBackup
      />
      {saveData.product.version !== product.result.version && (
        <OutdatedVersionedItemWarning />
      )}
    </Fragment>
  )
}
