// CSSで使用するMediaQuery
import { DeviceSize } from '~/utils/deviceSize'

export const breakpoints = [DeviceSize.MOBILE, DeviceSize.DESKTOP]
export const mqUp = breakpoints.map((bp) => `@media (min-width: ${bp}px)`)
export const mqMiddle = `@media screen and (min-width: ${breakpoints[0]}px) and (max-width: ${breakpoints[1]}px)`
export const mqDown = breakpoints.map((bp) => `@media (max-width: ${bp}px)`)

/**
 * 横並びにUIを並べても余裕があるビューポートかどうか
 */
export const mqHorizontal = `@media (orientation: landscape) and (min-width: 600px)`
