import * as React from 'react'

import { fetchDesign, fetchColors } from '~/api/customization'
import { fetchProductList } from '~/api/products'
import {
  BootstrapErrorPage,
  FetchErrorBody
} from '~/components/pages/BootstrapErrorPage'
import { CustomizePage } from '~/components/pages/CustomizePage'
import { useAsyncResult } from '~/hooks/useAsyncResult'
import type { InvalidBootstrapMode as Mode } from '~/hooks/useBootstrapMode'
import { fromDesign } from '~/utils/customization'

import { Core, CoreProps } from './Core'
import { loadingMessages } from './loadingMessages'
import { StorageSelectionGuard } from './StorageSelectionGuard'

const mode: Mode = {
  type: 'invalid_bootstrap_mode'
}

// パラメータが無効な場合は商品一覧の先頭のものを新規編集する
export const InvalidBootstrapMode = () => {
  return (
    <StorageSelectionGuard>
      <FirstItem />
    </StorageSelectionGuard>
  )
}

const FirstItem = () => {
  const colors = useAsyncResult(() => fetchColors(), [])
  const products = useAsyncResult(() => fetchProductList({ limit: 1 }), [])
  const design = useAsyncResult(
    () =>
      products.status === 'ok'
        ? fetchDesign(products.result[0].id)
        : Promise.resolve(null),
    [products.status]
  )

  if (colors.status === 'error') {
    return (
      <BootstrapErrorPage title="カラーの取得に失敗">
        <FetchErrorBody error={colors.error} onRetry={() => colors.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (products.status === 'error') {
    // コールするのは商品一覧APIのため、404が返ってくる = 異常事態なので商品単体取得用のエラーUIは利用しない
    return (
      <BootstrapErrorPage title="商品データの読み込みに失敗">
        <FetchErrorBody
          error={products.error}
          onRetry={() => products.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (design.status === 'error') {
    return (
      <BootstrapErrorPage title="デザインデータの読み込みに失敗">
        <FetchErrorBody error={design.error} onRetry={() => design.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (colors.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.colors} />
  }

  if (products.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.product} />
  }

  if (design.status !== 'ok' || !design.result) {
    return <CustomizePage loading message={loadingMessages.design} />
  }

  return (
    <WithCustomization
      mode={mode}
      colors={colors.result}
      design={design.result}
      product={products.result[0]}
    />
  )
}

const WithCustomization = (props: Omit<CoreProps, 'initialCustomization'>) => {
  const customization = fromDesign(props.design)

  return <Core initialCustomization={customization} {...props} />
}
