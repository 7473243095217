import styled from '@emotion/styled/macro'
import * as React from 'react'
import { CSSProperties, forwardRef, useLayoutEffect, useRef } from 'react'

import type { Color } from '~/types/Color'
import type { Customization } from '~/types/Customization'
import type { Design, View } from '~/types/Design'

import highlightImage from '~/assets/images/parts-highlight.png'

import { PrintLogo } from './PrintLogo'
import { PrintName } from './PrintName'

export interface PreviewRendererProps {
  className?: string
  style?: CSSProperties

  colors: readonly Color[]
  design: Design

  view: View

  customization: Customization

  currentScene?:
    | { type: 'part'; part: { id: string } }
    | { type: 'print_name' }
    | { type: 'print_logo' }

  printNamePlaceholder?: string

  imageHeight?: CSSProperties['height']

  onLoadBaseImage?(image: HTMLImageElement): void
}

// eslint-disable-next-line react/display-name
export const PreviewRenderer = forwardRef<HTMLDivElement, PreviewRendererProps>(
  (
    {
      colors,
      design,
      view,
      customization,
      currentScene,
      printNamePlaceholder,
      imageHeight,
      onLoadBaseImage,
      ...rest
    },
    ref
  ) => {
    return (
      <Container {...rest} ref={ref}>
        <Canvas
          role="img"
          aria-label={`シミュレーションのプレビュー、${view.description}`}
        >
          <BaseImage
            style={{ height: imageHeight || 'auto' }}
            src={view.baseImage}
            onLoad={(ev) => {
              onLoadBaseImage?.(ev.currentTarget)
            }}
          />
          {view.areas.map((area) => {
            const partCustomization = customization.parts[area.id]

            const isSelected =
              currentScene?.type === 'part' && currentScene.part.id === area.id

            return (
              <AreaLayer
                key={area.id}
                colors={colors}
                selected={isSelected}
                area={area}
                customization={partCustomization || null}
              />
            )
          })}
          {design.printName &&
            design.printName.id === view.id &&
            !!customization.printName &&
            !!customization.printName.printType && (
              <PrintName
                design={design.printName}
                customization={customization.printName}
                placeholder={printNamePlaceholder}
              />
            )}
          {design.printLogo &&
            design.printLogo.id === view.id &&
            customization.printLogo && (
              <PrintLogo
                design={design.printLogo}
                customization={customization.printLogo}
              />
            )}
        </Canvas>
      </Container>
    )
  }
)

interface AreaLayerProps {
  area: View['areas'][number]

  colors: readonly Color[]
  customization: Customization['parts'][number]

  selected: boolean
}

const AreaLayer = ({
  selected,
  customization,
  colors,
  area
}: AreaLayerProps) => {
  const animationTarget = useRef<HTMLDivElement>(null)

  // CSSのアニメーションをそのまま使うと、未選択のアニメーションの後に色を選択すると、
  // 色選択済みでシーンを表示した際のアニメーションがキックされてしまう。
  // これを防ぐために選択時に Web Animation API でアニメーションを再生している
  useLayoutEffect(() => {
    if (!selected || !animationTarget.current) {
      return
    }

    const anim = animationTarget.current.animate(
      // 色を選択しているかによって底の値が異なるが、ここで判定して値を設定すると
      // アニメーション中に色が選択された際に未選択の値を使い続けてしまうため、
      // CSS変数にして途中で切り替えた場合は選択済みの底の値を使うようにしている。
      [{ opacity: 1 }, { opacity: 'var(--_opacity-min)' }, { opacity: 1 }],
      {
        easing: 'linear',
        duration: 500,
        iterations: 3,
        direction: customization ? 'alternate' : 'normal'
      }
    )

    return () => {
      anim.cancel()
    }
  }, [selected])

  const commonStyle: CSSProperties = {
    WebkitMaskImage: `url(${area.maskImage})`,
    maskImage: `url(${area.maskImage})`
  }

  const color = colors.find((c) => c.id === customization?.color.id)

  return (
    <ColorLayer
      ref={animationTarget}
      style={
        color
          ? {
              ...commonStyle,
              backgroundColor: color.colorCode,
              filter: `brightness(${color.brightness * 100}%)`
            }
          : commonStyle
      }
      data-empty={(selected && !customization) || undefined}
    />
  )
}

const Container = styled.div`
  display: inline-flex;

  overflow: visible;
`

const Canvas = styled.div`
  position: relative;
`

const BaseImage = styled.img`
  filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.4))
    drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.15));
`

const ColorLayer = styled.div`
  --_opacity-min: 0.5;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  mask-size: contain;
  mask-repeat: no-repeat;
  mix-blend-mode: multiply;

  transition: background-color 0.3s linear, filter 0.3s linear;
  will-change: background-color, filter;
  // フィルタがコロコロ変わるため、明示的にGPUパワーを引き出す
  transform: translate3D(0, 0, 0);

  &[data-empty] {
    --_opacity-min: 0;

    background-image: url(${highlightImage});
    background-size: cover;
  }
`
