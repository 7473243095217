import React from 'react'

export const ShareLink: React.FC = () => {
  return (
    <React.Fragment>
      <path d="M12.502 16L3.50195 16C2.6582 16 2.00195 15.3125 2.00195 14.5L2.00195 10.375C2.00195 10.1562 2.1582 10 2.37695 10L2.62695 10C2.81445 10 3.00195 10.1562 3.00195 10.375L3.00195 14.5C3.00195 14.75 3.2207 15 3.50195 15L12.502 15C12.752 15 13.002 14.75 13.002 14.5L13.002 10.375C13.002 10.1562 13.1582 10 13.377 10L13.627 10C13.8145 10 14.002 10.1562 14.002 10.375L14.002 14.5C14.002 15.3125 13.3145 16 12.502 16Z" />
      <path d="M2.5957 5.24219C2.43945 5.39844 2.43945 5.64844 2.5957 5.77344L2.81445 5.99219C2.9707 6.14844 3.18945 6.14844 3.3457 5.99219L7.43945 1.89844L7.43945 10.6172C7.43945 10.8359 7.62695 10.9922 7.81445 10.9922L8.12695 10.9922C8.3457 10.9922 8.50195 10.8359 8.50195 10.6172L8.50195 1.89844L12.627 6.02344C12.7832 6.14844 13.002 6.14844 13.1582 6.02344L13.377 5.77344C13.5332 5.64844 13.5332 5.39844 13.377 5.24219L8.25195 0.117188C8.0957 -0.0390618 7.87695 -0.0390618 7.7207 0.117188L2.5957 5.24219Z" />
    </React.Fragment>
  )
}

export default ShareLink
