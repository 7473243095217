/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useMemo } from 'react'

import type { Customization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import { DirectionX } from '~/types/Design/Anchor'
import { PrintType } from '~/types/PrintType'
import { forbiddenChars } from '~/utils/printName'

import { ColoredSVG } from './ColoredSVG'
import { EmbossSVG } from './EmbossSVG'

interface PrintNameProps {
  customization: Customization['printName']

  design: NonNullable<Design['printName']>

  placeholder?: string
}

export const PrintName = ({
  customization,
  design,
  placeholder
}: PrintNameProps) => {
  const { fontSize, position, maxLength, anchor } = design

  const Component =
    customization?.printType === PrintType.Embossing ? EmbossSVG : ColoredSVG

  const filterWidth = fontSize * maxLength

  const filterX = useMemo(() => {
    switch (anchor.x) {
      case DirectionX.Left:
        return position.x
      case DirectionX.Center:
        return position.x - filterWidth / 2
      case DirectionX.Right:
        return position.x - filterWidth
    }
  }, [anchor.x, position.x, filterWidth])

  const filterY = useMemo(() => {
    return position.y - fontSize
  }, [position.y, fontSize])

  const [textX, textAnchor] = useMemo(() => {
    switch (anchor.x) {
      case DirectionX.Left:
        return [0, 'start']
      case DirectionX.Center:
        return [50, 'middle']
      case DirectionX.Right:
        return [100, 'end']
    }
  }, [anchor.x])

  return (
    <Component
      color={
        customization?.printType === PrintType.GoldPressing ? 'gold' : 'silver'
      }
      width={filterWidth}
      height={fontSize}
      x={filterX}
      y={filterY}
    >
      {customization?.printType && (
        <text
          x={`${textX}%`}
          y="50%"
          fontFamily="futura-pt"
          fontWeight="500"
          fontStyle="normal"
          fontSize={fontSize}
          textAnchor={textAnchor}
          dominantBaseline="central"
        >
          {(customization?.text || placeholder || '')
            .replace(forbiddenChars, '')
            .toLocaleUpperCase()}
        </text>
      )}
    </Component>
  )
}
