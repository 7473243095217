import { useCallback, useState } from 'react'

import type { DialogProps } from './Dialog'

interface DialogController {
  open(): void
  close(): void
}

export type UseDialogStateReturns = [
  Pick<DialogProps, 'opened' | 'onClose'>,
  DialogController
]

interface UseDialogStateParams {
  defaultOpened?: boolean

  shouldClose?(): boolean
}

const shouldCloseDefault = () => true

export function useDialogState({
  defaultOpened = false,
  shouldClose = shouldCloseDefault
}: UseDialogStateParams = {}): UseDialogStateReturns {
  const [opened, setOpened] = useState(defaultOpened)

  const close = useCallback(() => {
    if (shouldClose()) {
      setOpened(false)
    }
  }, [shouldClose])

  const open = useCallback(() => {
    setOpened(true)
  }, [])

  return [
    { opened, onClose: close },
    { open, close }
  ]
}
