import * as React from 'react'
import { useMemo } from 'react'

import type { Customization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import { DirectionX } from '~/types/Design/Anchor'
import { LogoType } from '~/types/LogoType'
import { PrintType } from '~/types/PrintType'

import logo from '~/assets/images/logo.svg'
import enishi from '~/assets/images/logo-enishi.svg'

import { ColoredSVG } from './ColoredSVG'
import { EmbossSVG } from './EmbossSVG'

interface PrintLogoProps {
  customization: NonNullable<Customization['printLogo']>

  design: NonNullable<Design['printLogo']>
}

export const PrintLogo = ({ customization, design }: PrintLogoProps) => {
  const { width, height, position, anchor } = design
  const { printType } = customization

  const Component = printType === PrintType.Embossing ? EmbossSVG : ColoredSVG

  const filterX = useMemo(() => {
    switch (anchor.x) {
      case DirectionX.Left:
        return position.x
      case DirectionX.Center:
        return position.x - width / 2
      case DirectionX.Right:
        return position.x - width
    }
  }, [position.x, width, anchor.x])

  const filterY = useMemo(() => {
    return position.y - height
  }, [position.y, height])

  const imageSource = design.logoType === LogoType.WithUnroof ? enishi : logo

  return (
    <Component
      color={printType === PrintType.GoldPressing ? 'gold' : 'silver'}
      width={width}
      height={height}
      x={filterX}
      y={filterY}
    >
      <use xlinkHref={`${imageSource}#logo`} />
    </Component>
  )
}
