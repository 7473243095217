import { stringify } from 'query-string'

export const apiURL = process.env.REACT_APP_API_URL

interface Query {
  [key: string]: any
}

/**
 * URLとクエリパラメーターを組み立てる
 * @param {string} url
 * @param {Query} query
 */
export const buildURL = (url: string, query: Query) => {
  const qs = stringify(query, { arrayFormat: 'bracket' })

  return `${url}?${qs}`
}

/**
 * 想定したステータスコードと異なる場合のエラー
 */
export class HttpStatusError extends Error {
  public status: number

  constructor(status: number) {
    super('処理中にエラーが発生しました')

    this.status = status
  }
}

/**
 * 認証エラー
 */
export class AuthError extends Error {
  constructor() {
    super('ログインが必要です')
    Object.setPrototypeOf(this, AuthError.prototype)
  }
}
