import { useCallback } from 'react'

import CookieKeys from '~/types/CookieKeys'
import { Request } from '~/types/SavedDesign'
import { getCookie, setLocalCart } from '~/utils/cookie'

/**
 * 存在しないインデックスが指定された
 */
export class OutOfBoundsIndexAccessError extends Error {}

/**
 * ストレージの容量が上限に達してこれ以上追加できない
 */
export class StorageCapacityExceedsError extends Error {}

interface UseLocalCartReturns {
  /**
   * 保存されているアイテムの一覧を返す
   *
   * @returns 保存されているアイテムの一覧
   */
  getItems(): Promise<readonly Request[]>

  /**
   * 指定インデックスのアイテムを取得する
   *
   * @param index - 取得するアイテムの配列インデックス
   * @returns 指定インデックスに存在するアイテム
   * @throws {OutOfBoundsIndexAccessError} - `index` が配列の範囲外だった場合
   */
  getItem(index: number): Promise<Request>

  /**
   * 指定インデックスのアイテムを上書きする
   *
   * @param index - 更新するアイテムの配列インデックス
   * @param payload - 上書きする内容
   * @throws {OutOfBoundsIndexAccessError} - `index` が配列の範囲外だった場合
   * @throws {StorageCapacityExceedsError} - 更新後の配列の内容がブラウザの Cookie 保存上限を超えた場合
   */
  update(index: number, payload: Request): Promise<void>

  /**
   * アイテムを追加する
   *
   * @param payload - 追加するアイテム
   * @throws {StorageCapacityExceedsError} - 更新後の配列の内容がブラウザの Cookie 保存上限を超えた場合
   */
  append(payload: Request): Promise<{
    /**
     * 追加した要素の配列インデックス
     */
    index: number
  }>
}

/**
 * 未ログイン時に利用するブラウザ保存のカートを操作する
 */
export function useLocalCart(): UseLocalCartReturns {
  const getItems = useCallback<UseLocalCartReturns['getItems']>(async () => {
    const cookieValue = getCookie(CookieKeys.Cart)
    if (!cookieValue) {
      return []
    }

    return JSON.parse(cookieValue) || []
  }, [])

  const getItem = useCallback<UseLocalCartReturns['getItem']>(
    async (index) => {
      const items = await getItems()

      if (!items[index]) {
        throw new OutOfBoundsIndexAccessError('範囲外のインデックスです')
      }

      return items[index]
    },
    [getItems]
  )

  const setItems = useCallback(
    async (items: readonly Request[]) => {
      const beforeSave = getCookie(CookieKeys.Cart)

      // 変更がなければ何もしない
      if (beforeSave === JSON.stringify(items)) {
        return
      }

      setLocalCart(items)

      const afterSave = getCookie(CookieKeys.Cart)

      if (beforeSave === afterSave) {
        throw new StorageCapacityExceedsError()
      }

      return
    },
    [getItems]
  )

  const update = useCallback<UseLocalCartReturns['update']>(
    async (index, payload) => {
      const items = await getItems()

      if (index < 0 || index >= items.length) {
        throw new OutOfBoundsIndexAccessError(
          '更新対象に存在しないインデックスが指定されました'
        )
      }

      await setItems(items.map((item, i) => (i === index ? payload : item)))
    },
    [getItems, setItems]
  )

  const append = useCallback<UseLocalCartReturns['append']>(
    async (payload) => {
      const items = await getItems()

      await setItems([...items, payload])

      return { index: items.length }
    },
    [getItems, setItems]
  )

  return { getItems, getItem, update, append }
}
