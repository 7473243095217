import {
  createContext,
  FC,
  useContext,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import * as React from 'react'

interface FLIPComputationContext<First, Last, Invert> {
  /**
   * `first()` が返した値
   */
  first: First

  /**
   * `last()` が返した値
   */
  last: Last

  /**
   * `invert()` が返した値
   */
  invert: Invert
}

export interface FLIP<First, Last, Invert> {
  /**
   * 変更する前の状態を取得する
   *
   * @returns 取得した状態、 `invert() / play()` に `context.first` として渡される
   */
  first(): First

  /**
   * 変更された後の状態を取得する
   *
   * @returns 取得した状態、 `invert() / play()` に `context.last` として渡される
   */
  last(): Last

  /**
   * 変更前と後の状態を比較して、差分を算出する (この関数は利用せずに `play` でまとめて行うことも可能)
   *
   * @returns 算出した差分、 `play()` に `context.invert` として渡される
   */
  invert(
    context: Pick<
      FLIPComputationContext<First, Last, unknown>,
      'first' | 'last'
    >
  ): Invert

  /**
   * 算出した差分を元にアニメーションを再生する
   */
  play(context: FLIPComputationContext<First, Last, Invert>): void
}

export interface BeforeMutationState<First, Last, Invert> {
  first: First

  flip: FLIP<First, Last, Invert>
}

interface AfterMutationState<First, Last, Invert> {
  first: First
  last: Last

  flip: FLIP<First, Last, Invert>
}

interface FLIPAnimationContextValue {
  queue<First, Last, Invert>(
    state: BeforeMutationState<First, Last, Invert>
  ): void
}

const Context = createContext<FLIPAnimationContextValue>({
  queue() {}
})

export const FLIPAnimationProvider: FC = ({ children }) => {
  const [beforeMutationStates, setBeforeMutationStates] = useState<
    readonly BeforeMutationState<unknown, unknown, unknown>[]
  >(() => [])

  // `First` が終わり変更が加えられたら残りのステップをまとめて実行する
  // 利用側で Props や State の変更を行ってレンダリングが走る前提となっている
  useLayoutEffect(() => {
    if (!beforeMutationStates.length) {
      return
    }

    // レイアウトの読み取りはまとめる
    const afterMutationStates = beforeMutationStates.map<
      AfterMutationState<unknown, unknown, unknown>
    >(({ first, flip }) => {
      const last = flip.last()

      return {
        first,
        last,
        flip
      }
    })

    // Invert ではレイアウトやアニメーションに関することは行わないため、
    // Play と同タイミングで順序を気にせず実行してしまって問題ない
    afterMutationStates.forEach(({ first, last, flip }) => {
      const invert = flip.invert({ first, last })

      return flip.play({ first, last, invert })
    })

    // 次のアニメーションが走れるようにキューを空にする
    setBeforeMutationStates([])
  }, [beforeMutationStates])

  const contextValue = useMemo<FLIPAnimationContextValue>(() => {
    return {
      queue(state) {
        setBeforeMutationStates((prev) => [...prev, state])
      }
    }
  }, [])

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export function useFLIPAnimation(): FLIPAnimationContextValue {
  return useContext(Context)
}
