import * as React from 'react'
import { Fragment, useMemo } from 'react'

import { fetchDesign, fetchColors } from '~/api/customization'
import { fetchCartItem } from '~/api/cart'
import { fetchProduct } from '~/api/products'
import { Redirect } from '~/components/atoms/Redirect'
import { CustomizationCreationWarnings } from '~/components/organisms/CustomizationCreationWarnings'
import { OutdatedVersionedItemWarning } from '~/components/organisms/OutdatedVersionedItemWarning'
import {
  BootstrapErrorPage,
  ProductFetchErrorBody,
  CartItemFetchErrorBody,
  FetchErrorBody
} from '~/components/pages/BootstrapErrorPage'
import { CustomizePage } from '~/components/pages/CustomizePage'
import { useAsyncResult } from '~/hooks/useAsyncResult'
import type { CartItemUpdate as CartItemUpdateMode } from '~/hooks/useBootstrapMode'
import { useLoginPageUrl } from '~/hooks/useLoginPageUrl'
import { LoginSession, useSession } from '~/hooks/useSession'
import type { Cart } from '~/types/Cart'
import { fromSavedDesign } from '~/utils/customization'

import { Core, CoreProps } from './Core'
import { loadingMessages } from './loadingMessages'

interface CartItemUpdateProps {
  mode: CartItemUpdateMode
}

export const CartItemUpdate = ({ mode }: CartItemUpdateProps) => {
  const session = useSession()

  const loginPageUrl = useLoginPageUrl({
    // そのまま再開させるため現在のURLを丸々渡す
    redirectTo: location.href
  })

  if (!session.isLoggedIn) {
    return <Redirect to={loginPageUrl} />
  }

  return <WithLoginSession mode={mode} session={session} />
}

const WithLoginSession = ({
  mode,
  session
}: CartItemUpdateProps & { session: LoginSession }) => {
  const colors = useAsyncResult(() => fetchColors(), [])
  const cartItem = useAsyncResult(
    () => fetchCartItem(mode.cartItem.id, session.accessToken.token),
    [mode.cartItem.id, session.accessToken]
  )

  const productId = cartItem.status === 'ok' ? cartItem.result.product.id : null

  const latestProduct = useAsyncResult(
    () => (productId ? fetchProduct(productId) : Promise.resolve(null)),
    [productId]
  )
  const design = useAsyncResult(
    () => (productId ? fetchDesign(productId) : Promise.resolve(null)),
    [productId]
  )

  if (colors.status === 'error') {
    return (
      <BootstrapErrorPage title="カラーの取得に失敗">
        <FetchErrorBody error={colors.error} onRetry={() => colors.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (latestProduct.status === 'error') {
    return (
      <BootstrapErrorPage title="商品データの読み込みに失敗">
        <ProductFetchErrorBody
          error={latestProduct.error}
          onRetry={() => latestProduct.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (design.status === 'error') {
    return (
      <BootstrapErrorPage title="デザインデータの読み込みに失敗">
        <FetchErrorBody error={design.error} onRetry={() => design.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (cartItem.status === 'error') {
    return (
      <BootstrapErrorPage title="カートアイテムの読み込みに失敗">
        <CartItemFetchErrorBody
          error={cartItem.error}
          onRetry={() => cartItem.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (cartItem.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.cartItem} />
  }

  if (colors.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.colors} />
  }

  if (latestProduct.status !== 'ok' || !latestProduct.result) {
    return <CustomizePage loading message={loadingMessages.product} />
  }

  if (design.status !== 'ok' || !design.result) {
    return <CustomizePage loading message={loadingMessages.design} />
  }

  return (
    <WithCustomization
      mode={mode}
      cartItem={cartItem.result}
      colors={colors.result}
      design={design.result}
      product={latestProduct.result}
    />
  )
}

const WithCustomization = ({
  cartItem,
  ...props
}: Omit<CoreProps, 'initialCustomization'> & {
  cartItem: Cart
  mode: CartItemUpdateMode
}) => {
  const [initialCustomization, warnings] = useMemo(() => {
    return fromSavedDesign(cartItem, {
      design: props.design,
      colors: props.colors
    })
  }, [cartItem, props.design, props.colors])

  return (
    <Fragment>
      <Core {...props} initialCustomization={initialCustomization} />
      <CustomizationCreationWarnings warnings={warnings} />
      {cartItem.product.version !== props.product.version && (
        <OutdatedVersionedItemWarning />
      )}
    </Fragment>
  )
}
