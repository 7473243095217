import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// Providers
import { ToastProvider, ToastViewport } from '~/components/molecules/Toast'
import { FLIPAnimationProvider } from '~/providers/FLIPAnimationProvider'
import { FrameMessageReceiverProvider } from '~/providers/FrameMessageReceiverProvider'
import { HTMLElementFeatureFlagsProvider } from '~/providers/FeatureFlagsProvider'
import { ImperativeToastProvider } from '~/providers/ImperativeToastProvider'

import TagManager from 'react-gtm-module'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()]
  })
}

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID || ''
})

const featureFlagsElement = document.getElementById('feature_flags')

const trustedOrigins = [process.env.REACT_APP_PORTAL_URL || 'https://joggo.jp']

const GlovalProvider: FC = ({ children }) => {
  return (
    <HTMLElementFeatureFlagsProvider element={featureFlagsElement}>
      <FrameMessageReceiverProvider trustedOrigins={trustedOrigins}>
        <FLIPAnimationProvider>
          <ToastProvider>
            <ImperativeToastProvider>{children}</ImperativeToastProvider>
            <ToastViewport />
          </ToastProvider>
        </FLIPAnimationProvider>
      </FrameMessageReceiverProvider>
    </HTMLElementFeatureFlagsProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <GlovalProvider>
      <App />
    </GlovalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
