import { apiURL, HttpStatusError } from './common'

/**
 * アクセストークンが有効かどうかをサーバ側で検証する
 */
export async function verifyAccessToken(
  accessToken: string
): Promise<{ valid: boolean }> {
  const url = new URL('./me', apiURL)

  const res = await fetch(url, {
    cache: 'no-store',
    mode: 'cors',
    credentials: 'include',
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  })

  if (res.status === 401) {
    return { valid: false }
  }

  if (res.status !== 200) {
    throw new HttpStatusError(res.status)
  }

  return { valid: true }
}
