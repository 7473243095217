import { SetStateAction, useCallback, useMemo, useState } from 'react'

import type { Design } from '~/types/Design'

import type { Scene } from './Scene'

interface UseSceneParams {
  design: Design

  /**
   * シーンが変更される直前のタイミングで実行されるコールバック
   *
   * @param nextScene - 変更先のシーン
   * @param prevScene - 変更前 (現在) のシーン
   * @returns 変更するかどうか、 `false` の場合は変更をキャンセルして現在のシーンに留まる
   */
  shouldChange?(nextScene: Scene, prevScene: Scene): boolean
}

export interface UseSceneReturns {
  scenes: readonly Scene[]

  current: Scene

  change(nextScene: SetStateAction<Scene>): void
}

const defaultShouldChange = () => true

export function useScene({
  design,
  shouldChange = defaultShouldChange
}: UseSceneParams): UseSceneReturns {
  const scenes = useMemo<readonly Scene[]>(() => {
    return [
      ...design.parts.map<Scene>((part) => {
        return {
          id: `part.${part.id}`,
          type: 'part',
          part: { id: part.id, name: part.name }
        }
      }),
      design.printLogo ? { id: 'print_logo', type: 'print_logo' } : null,
      design.printName ? { id: 'print_name', type: 'print_name' } : null
    ].filter((scene): scene is Scene => !!scene)
  }, [design])

  const [current, setCurrent] = useState<Scene>(scenes[0])

  const change = useCallback(
    (nextScene: SetStateAction<Scene>) => {
      setCurrent((prev) => {
        const next =
          typeof nextScene === 'function' ? nextScene(prev) : nextScene

        return shouldChange(next, prev) ? next : prev
      })
    },
    [shouldChange]
  )

  return { scenes, current, change }
}
