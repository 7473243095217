import { useRef, useState } from 'react'

interface IdleMutation {
  status: 'idle'
}

interface LoadingMutation<P> {
  status: 'loading'

  /**
   * この Mutation をトリガーした際のパラメータ
   */
  params: P
}

interface FailedMutation<E, P> {
  status: 'failed'

  error: E

  /**
   * この Mutation をトリガーした際のパラメータ
   */
  params: P
}

interface SuccessMutation<T, P> {
  status: 'success'

  data: T

  /**
   * この Mutation をトリガーした際のパラメータ
   */
  params: P
}

type Mutation<T, P, E> =
  | IdleMutation
  | LoadingMutation<P>
  | FailedMutation<E, P>
  | SuccessMutation<T, P>

type UseMutationReturns<T, P, E> = Mutation<T, P, E> & {
  /**
   * 渡されたコールバックを実行する
   */
  mutate(payload: P): void

  /**
   * 結果 (`result`) をリセットする
   *
   * 実行中 (`"loading"`) の場合は何も起きない。
   */
  clear(): void

  /**
   * 現在処理が実行中であればキャンセルし、 `"idle"` 状態に戻す
   *
   * 実行中ではない場合は何も起きない。
   */
  cancel(): void
}

/**
 * 非同期処理を宣言的に扱うための Hooks
 *
 * @example
 * async function fetchTodos(signal: AbortSignal): Promise<Todo[]> {
 *   // ...
 * }
 *
 * const todos = useMutation((signal) => fetchTodos(signal))
 *
 * if (todos.status === "failed") {
 *   return <ErrorScreen error={todos.result.error} />
 * }
 *
 * if (todos.status !== "success") {
 *   return <Loading />
 * }
 *
 * return <TodoList todos={todos.data} />
 */
export function useMutation<T, P, E = unknown>(
  callback: (payload: P, signal: AbortSignal) => Promise<T>
): UseMutationReturns<T, P, E> {
  const [result, setResult] = useState<Mutation<T, P, E>>(() => ({
    status: 'idle'
  }))

  const abortController = useRef<AbortController | null>(null)

  return {
    ...result,
    mutate(payload) {
      if (abortController.current) {
        abortController.current.abort()
      }

      const ac = new AbortController()
      abortController.current = ac

      setResult({ status: 'loading', params: payload })

      callback(payload, ac.signal)
        .then(async (data) => {
          setResult({
            status: 'success',
            data,
            params: payload
          })
        })
        .catch(async (err) => {
          if (err instanceof DOMException && err.name === 'AbortError') {
            return
          }

          setResult({
            status: 'failed',
            error: err,
            params: payload
          })
        })
        .then(() => {
          abortController.current = null
        })
    },
    clear() {
      setResult((prev) =>
        prev.status === 'loading' ? prev : { status: 'idle' }
      )
    },
    cancel() {
      if (result.status !== 'loading') {
        return
      }

      if (abortController.current) {
        abortController.current.abort()
        abortController.current = null
      }

      setResult({ status: 'idle' })
    }
  }
}
