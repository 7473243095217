import React from 'react'

export const Slash: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7803 0.21967C16.0732 0.512563 16.0732 0.987437 15.7803 1.28033L1.28033 15.7803C0.987437 16.0732 0.512563 16.0732 0.21967 15.7803C-0.0732233 15.4874 -0.0732233 15.0126 0.21967 14.7197L14.7197 0.21967C15.0126 -0.0732233 15.4874 -0.0732233 15.7803 0.21967Z"
    />
  )
}

export default Slash
