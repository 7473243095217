/** 名入れ、ロゴの刻印の種類 */
export enum PrintType {
  Embossing = 'st',
  GoldPressing = 'gl',
  SilverPressing = 'sv'
}

export default PrintType

export function fromString(str: string): PrintType | null {
  switch (str) {
    case PrintType.Embossing:
    case PrintType.GoldPressing:
    case PrintType.SilverPressing:
      return str
    default:
      return null
  }
}

/** 刻印可能な印刷方法 */
export interface PossiblePrintType {
  printType: 'stamp' | 'gold_emboss' | 'silver_emboss'
}

export function possiblePrintTypeToPrintType(t: PossiblePrintType): PrintType {
  switch (t.printType) {
    case 'stamp':
      return PrintType.Embossing
    case 'gold_emboss':
      return PrintType.GoldPressing
    case 'silver_emboss':
      return PrintType.SilverPressing
  }
}

export function printTypeToPossiblePrintType(t: PrintType): PossiblePrintType {
  switch (t) {
    case PrintType.Embossing:
      return { printType: 'stamp' }
    case PrintType.GoldPressing:
      return { printType: 'gold_emboss' }
    case PrintType.SilverPressing:
      return { printType: 'silver_emboss' }
  }
}

export enum PrintDisplayText {
  Embossing = '型押し',
  GoldPressing = 'ゴールド',
  SilverPressing = 'シルバー'
}

export enum PrintColor {
  GoldPressing = 'linear-gradient(135deg, #DC9F1B 0%, #F6DE54 100%)',
  SilverPressing = 'linear-gradient(135deg, #BFBFBE 0%, #EBEBEB 100%)'
}
