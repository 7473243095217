import { useState, TouchEvent, useCallback } from 'react'

interface UseFlickRetunrs {
  touchStart: (e: TouchEvent<HTMLDivElement>) => void

  touchMove: (e: TouchEvent<HTMLDivElement>) => void

  touchEnd: (onLeftFlick: () => void, onRightFlick: () => void) => void
}

// [x, y]
type Position = [number, number]

export function useFlick(): UseFlickRetunrs {
  const MIN_DISTANCE = 50

  const [startPosition, setStartPosition] = useState<Position | null>(null)
  const [endPosition, setEndPosition] = useState<Position | null>(null)

  const touchEnd: UseFlickRetunrs['touchEnd'] = useCallback(
    (onLeftFlick, onRightFlick) => {
      if (!endPosition || !startPosition) {
        return
      }

      const distanceX = endPosition[0] - startPosition[0]
      const distanceY = endPosition[1] - startPosition[1]

      if (
        Math.abs(distanceX) > Math.abs(distanceY) &&
        Math.abs(distanceX) > MIN_DISTANCE
      ) {
        if (0 > distanceX) {
          onLeftFlick()
        } else {
          onRightFlick()
        }
      }

      setStartPosition(null)
      setEndPosition(null)
    },
    [endPosition, startPosition]
  )

  return {
    touchStart(e) {
      setStartPosition([e.touches[0].pageX, e.touches[0].pageY])
    },
    touchMove(e) {
      setEndPosition([e.changedTouches[0].pageX, e.changedTouches[0].pageY])
    },
    touchEnd
  }
}
