import { isEqual } from 'lodash'
import * as React from 'react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'

import {
  LocalCartLimitDialog,
  useLocalCartLimitDialogState
} from '~/components/organisms/LocalCartLimitDialog'
import {
  MyDesignLoginDialog,
  useMyDesignLoginDialogState
} from '~/components/organisms/MyDesignLoginDialog'
import {
  MyDesignSaveMethodDialog,
  useMyDesignSaveMethodDialogState
} from '~/components/organisms/MyDesignSaveMethodDialog'
import { CustomizePage } from '~/components/pages/CustomizePage'
import type { BootstrapMode } from '~/hooks/useBootstrapMode'
import { useCartPageUrl } from '~/hooks/useCartPageUrl'
import { useCurrentUrl } from '~/hooks/useCurrentUrl'
import { useCustomizationStorage } from '~/hooks/useCustomizationStorage'
import { useDebouncedMemo } from '~/hooks/useDebouncedMemo'
import { useFrameMessageDispatcher } from '~/hooks/useFrameMessageDispatcher'
import { useLeaveConfirmation } from '~/hooks/useLeaveConfirmation'
import * as LocalCart from '~/hooks/useLocalCart'
import { useLoginPageUrl } from '~/hooks/useLoginPageUrl'
import { LoginSession, useSession } from '~/hooks/useSession'
import { useToast } from '~/hooks/useToast'
import type { Color } from '~/types/Color'
import type { Customization, ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { Product } from '~/types/Product'
import type { MyDesign } from '~/types/MyDesign'
import { fromDesign } from '~/utils/customization'

import { useCartItemCreation } from './useCartItemCreation'
import { useCartItemUpdate } from './useCartItemUpdate'
import { useLocalCartItemCreation } from './useLocalCartItemCreation'
import { useLocalCartItemUpdate } from './useLocalCartItemUpdate'
import { useMyDesignCreation } from './useMyDesignCreation'
import { useMyDesignUpdate } from './useMyDesignUpdate'

export interface CoreProps {
  mode: BootstrapMode

  colors: readonly Color[]
  design: Design
  product: Product

  initialCustomization: Customization

  myDesign?: MyDesign | null

  restoredFromLocalBackup?: boolean
}

export const Core = ({
  mode,
  colors,
  design,
  product,
  initialCustomization,
  myDesign: givenMyDesign = null,
  restoredFromLocalBackup = false
}: CoreProps) => {
  const session = useSession()
  const storage = useCustomizationStorage()
  const toast = useToast()
  const currentUrl = useCurrentUrl()
  const dispatchFrameMessage = useFrameMessageDispatcher()

  // サーバ、もしくはCookieに保存されているカスタマイズ内容
  // LocalStorageのバックアップは揮発性が高いため、それから復元した場合はデザインの初期データを
  // 復元前のセッションの保存内容として扱う
  const [savedCustomization, setSavedCustomization] = useState<
    Readonly<Customization>
  >(() => (restoredFromLocalBackup ? fromDesign(design) : initialCustomization))

  const [customization, setCustomization] =
    useState<Customization>(initialCustomization)

  const isSaved = useDebouncedMemo(
    () => isEqual(savedCustomization, customization),
    {
      deps: [savedCustomization, customization],
      wait: 200
    }
  )

  // 保存済みかどうかが変わった際に埋め込み元のポータルサイトにイベントを投げる
  useEffect(() => {
    dispatchFrameMessage({
      type: 'save_state_change',
      saved: isSaved.value
    })
  }, [isSaved.value])

  const leaveConfirmation = useLeaveConfirmation({
    enabled: !isSaved.value
  })

  // マイデザインや上限超過時のローカルカートを保存しようとしてログインする際のURL
  const loginPageUrl = useLoginPageUrl({
    // 埋め込まれている場合は埋め込んでいるページに戻る
    redirectTo: currentUrl
  })

  // 現在編集しているマイデザイン
  const [myDesign, setMyDesign] = useState<MyDesign | null>(givenMyDesign)

  const myDesignCreation = useMyDesignCreation({
    onCreated(myDesign) {
      setSavedCustomization(customization)

      setMyDesign(myDesign)

      // GTMデータレイヤーにデザイン保存成功イベントを登録
      TagManager.dataLayer({
        dataLayer: {
          event: 'saveDesignSucceeded'
        }
      })
    }
  })

  const myDesignUpdate = useMyDesignUpdate({
    onUpdated() {
      setSavedCustomization(customization)

      // GTMデータレイヤーにデザイン更新成功イベントを登録
      TagManager.dataLayer({
        dataLayer: {
          event: 'updateDesignSucceeded'
        }
      })
    }
  })

  const cartPageUrl = useCartPageUrl()

  const cartItemCreation = useCartItemCreation({
    onCreated(item) {
      setSavedCustomization(customization)

      // GTMデータレイヤーへ「カートに追加」イベント登録
      TagManager.dataLayer({
        dataLayer: {
          event: 'addToCartSucceeded'
        }
      })

      const url = new URL(location.href)

      url.search = ''
      url.searchParams.set('i', item.id)

      history.replaceState({}, '', url)

      leaveConfirmation.passThrough(() => {
        ;(window.parent || window).location.href = cartPageUrl.toString()
      })
    },
    onError(err) {
      toast.show({
        variant: 'error',
        title: 'カートへの追加に失敗しました',
        description: err instanceof Error ? err.message : String(err)
      })
    }
  })

  const cartItemUpdate = useCartItemUpdate({
    onUpdated() {
      setSavedCustomization(customization)

      // GTMデータレイヤーへカートアイテム更新イベント登録
      TagManager.dataLayer({
        dataLayer: {
          event: 'updateCartItemSucceeded'
        }
      })

      leaveConfirmation.passThrough(() => {
        ;(window.parent || window).location.href = cartPageUrl.toString()
      })
    },
    onError(err) {
      toast.show({
        variant: 'error',
        title: 'カートアイテムの更新に失敗しました',
        description: err instanceof Error ? err.message : String(err)
      })
    }
  })

  const localCartLimitDialog = useLocalCartLimitDialogState<{
    customization: ReadyCustomization
    product: Product
  }>()

  const localCartItemCreation = useLocalCartItemCreation({
    onCreated(index) {
      setSavedCustomization(customization)

      const url = new URL(location.href)

      url.search = ''
      url.searchParams.set('ii', index.toString(10))

      history.replaceState({}, '', url)

      leaveConfirmation.passThrough(() => {
        ;(window.parent || window).location.href = cartPageUrl.toString()
      })
    },
    onError(err, { customization, product }) {
      if (err instanceof LocalCart.StorageCapacityExceedsError) {
        localCartLimitDialog.open({ customization, product })
        return
      }

      toast.show({
        variant: 'error',
        title: 'カートへの追加に失敗しました',
        description: err instanceof Error ? err.message : String(err)
      })
    }
  })

  const localCartItemUpdate = useLocalCartItemUpdate({
    onUpdated() {
      setSavedCustomization(customization)

      leaveConfirmation.passThrough(() => {
        ;(window.parent || window).location.href = cartPageUrl.toString()
      })
    },
    onError(err, { customization, product }) {
      if (err instanceof LocalCart.StorageCapacityExceedsError) {
        localCartLimitDialog.open({ customization, product })
        return
      }

      if (err instanceof LocalCart.OutOfBoundsIndexAccessError) {
        toast.show({
          variant: 'error',
          title: '更新対象のカートアイテムが見つかりません'
        })
        return
      }

      toast.show({
        variant: 'error',
        title: 'カートアイテムの更新に失敗しました',
        description: err instanceof Error ? err.message : String(err)
      })
    }
  })

  const myDesignLoginDialog = useMyDesignLoginDialogState<{
    customization: ReadyCustomization
    product: Product
  }>()

  const myDesignSaveMethodDialog = useMyDesignSaveMethodDialogState<{
    customization: ReadyCustomization
    session: LoginSession
    myDesign: MyDesign
  }>()

  return (
    <Fragment>
      <CustomizePage
        mode={mode}
        mutating={
          myDesignCreation.status === 'loading'
            ? 'マイデザインに保存しています'
            : myDesignUpdate.status === 'loading'
            ? 'マイデザインを更新しています'
            : cartItemCreation.status === 'loading'
            ? 'カートに追加しています'
            : cartItemUpdate.status === 'loading'
            ? 'カートを更新しています'
            : localCartItemCreation.status === 'loading'
            ? 'カートに追加しています'
            : localCartItemUpdate.status === 'loading'
            ? 'カートを更新しています'
            : false
        }
        customization={customization}
        colors={colors}
        design={design}
        product={product}
        onCustom={setCustomization}
        onSaveAsMyDesign={(customization) => {
          // GTMデータレイヤーへ保存ボタンクリックイベント登録
          TagManager.dataLayer({
            dataLayer: {
              event: 'designSaveButtonClicked'
            }
          })

          if (!session.isLoggedIn) {
            myDesignLoginDialog.open({ customization, product })
            return
          }

          // 既に保存されている場合は上書きか新規か確認する
          if (myDesign) {
            myDesignSaveMethodDialog.open({ customization, session, myDesign })
            return
          }

          myDesignCreation.mutate({
            customization,
            product,
            design,
            session
          })
        }}
        onAddToCart={(customization) => {
          // GTMデータレイヤーへ「カートへ入れる」ボタンクリックイベント登録
          TagManager.dataLayer({
            dataLayer: {
              event: 'addToCartButtonClicked'
            }
          })

          if (!session.isLoggedIn) {
            if (mode.type === 'local_cart_item_update') {
              localCartItemUpdate.mutate({
                customization,
                design,
                product,
                index: mode.cartItemIndex,
                session
              })
              return
            }

            localCartItemCreation.mutate({
              customization,
              design,
              product,
              session
            })
            return
          }

          // カートID指定で起動した場合は追加せず対象のカートアイテムを更新する
          if (mode.type === 'cart_item_update') {
            cartItemUpdate.mutate({
              cart: mode.cartItem,
              customization,
              design,
              product,
              session
            })
            return
          }

          cartItemCreation.mutate({
            customization,
            design,
            product,
            session
          })
        }}
      />
      <MyDesignLoginDialog
        {...myDesignLoginDialog.props}
        onLogin={async ({ customization, product }) => {
          try {
            await storage.save({
              destination: 'myDesign',
              product: { id: product.id, version: product.version },
              customization
            })

            leaveConfirmation.passThrough(() => {
              ;(window.parent || window).location.href = loginPageUrl.toString()
            })
          } catch (err) {
            toast.show({
              variant: 'error',
              title: 'ログイン準備処理に失敗しました',
              description: err instanceof Error ? err.message : String(err)
            })
          }
        }}
      />
      <MyDesignSaveMethodDialog
        {...myDesignSaveMethodDialog.props}
        onSelectNew={({ customization, session }) => {
          myDesignSaveMethodDialog.close()

          myDesignCreation.mutate({
            customization,
            product,
            design,
            session
          })
        }}
        onSelectOverride={({ customization, session, myDesign }) => {
          myDesignSaveMethodDialog.close()

          myDesignUpdate.mutate({
            customization,
            design,
            product,
            session,
            myDesign
          })
        }}
      />
      <LocalCartLimitDialog
        {...localCartLimitDialog.props}
        onLogin={async ({ customization, product }) => {
          try {
            await storage.save({
              destination: 'cart',
              product: { id: product.id, version: product.version },
              customization
            })

            leaveConfirmation.passThrough(() => {
              ;(window.parent || window).location.href = loginPageUrl.toString()
            })
          } catch (err) {
            toast.show({
              variant: 'error',
              title: 'ログイン準備処理に失敗しました',
              description: err instanceof Error ? err.message : String(err)
            })
          }
        }}
      />
    </Fragment>
  )
}
