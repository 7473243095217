import Product from '~/types/Product'
import { apiURL, buildURL, HttpStatusError } from '../common'

/**
 * 商品一覧を取得する
 * @param {String} category 商品カテゴリー
 */
export const fetchProductList = async ({
  category,
  priceLine,
  series,
  limit
}: {
  category?: string
  priceLine?: string
  series?: string
  limit?: number
} = {}): Promise<Product[]> => {
  const url = buildURL(`${apiURL}/products`, {
    category,
    price_line: priceLine,
    series,
    limit
  })

  const res = await fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'omit'
  })

  if (res.status !== 200) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}

/**
 * 商品情報を取得する
 * @param {String} id 商品ID
 */
export const fetchProduct = async (id: string): Promise<Product> => {
  const res = await fetch(`${apiURL}/products/${id}`, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'omit'
  })

  if (res.status !== 200) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}
