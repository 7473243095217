import * as React from 'react'
import { Fragment, useMemo } from 'react'

import { fetchDesign, fetchColors } from '~/api/customization'
import { fetchProduct } from '~/api/products'
import { CustomizationCreationWarnings } from '~/components/organisms/CustomizationCreationWarnings'
import { OutdatedVersionedItemWarning } from '~/components/organisms/OutdatedVersionedItemWarning'
import {
  BootstrapErrorPage,
  LocalCartLoadingErrorBody,
  ProductFetchErrorBody,
  FetchErrorBody
} from '~/components/pages/BootstrapErrorPage'
import { CustomizePage } from '~/components/pages/CustomizePage'
import { useAsyncResult } from '~/hooks/useAsyncResult'
import type { LocalCartItemUpdate as LocalCartItemUpdateMode } from '~/hooks/useBootstrapMode'
import { useLocalCart } from '~/hooks/useLocalCart'
import type { Color } from '~/types/Color'
import type { Design } from '~/types/Design'
import type { Product } from '~/types/Product'
import type { Request as SavedDesign } from '~/types/SavedDesign'
import { fromRequestPayload } from '~/utils/customization'

import { Core } from './Core'
import { loadingMessages } from './loadingMessages'

interface LocalCartItemUpdateProps {
  mode: LocalCartItemUpdateMode
}

export const LocalCartItemUpdate = ({ mode }: LocalCartItemUpdateProps) => {
  const colors = useAsyncResult(() => fetchColors(), [])
  const localCart = useLocalCart()

  const item = useAsyncResult(
    () => localCart.getItem(mode.cartItemIndex),
    [mode.cartItemIndex]
  )

  if (colors.status === 'error') {
    return (
      <BootstrapErrorPage title="カラーの取得に失敗">
        <FetchErrorBody error={colors.error} onRetry={() => colors.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (item.status === 'error') {
    return (
      <BootstrapErrorPage title="カートアイテムの読み込みに失敗">
        <LocalCartLoadingErrorBody error={item.error} />
      </BootstrapErrorPage>
    )
  }

  if (item.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.cartItem} />
  }

  if (colors.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.colors} />
  }

  return (
    <WithColorsAndLocalCart
      mode={mode}
      colors={colors.result}
      localCartItem={item.result}
    />
  )
}

interface WithColorsAndLocalCartProps extends LocalCartItemUpdateProps {
  colors: readonly Color[]
  localCartItem: SavedDesign
}

const WithColorsAndLocalCart = ({
  localCartItem,
  ...rest
}: WithColorsAndLocalCartProps) => {
  const product = useAsyncResult(
    () => fetchProduct(localCartItem.product.id),
    [localCartItem.product.id]
  )
  const design = useAsyncResult(
    () => fetchDesign(localCartItem.product.id),
    [localCartItem.product.id]
  )

  if (product.status === 'error') {
    return (
      <BootstrapErrorPage title="商品データの読み込みに失敗">
        <ProductFetchErrorBody
          error={product.error}
          onRetry={() => product.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (design.status === 'error') {
    return (
      <BootstrapErrorPage title="デザインデータの読み込みに失敗">
        <FetchErrorBody error={design.error} onRetry={() => design.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (product.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.product} />
  }

  if (design.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.design} />
  }

  return (
    <ReadyCore
      {...rest}
      localCartItem={localCartItem}
      design={design.result}
      product={product.result}
    />
  )
}

interface ReadyCoreProps extends WithColorsAndLocalCartProps {
  design: Design
  product: Product
}

const ReadyCore = ({
  colors,
  localCartItem,
  design,
  product,
  ...rest
}: ReadyCoreProps) => {
  const [initialCustomization, warnings] = useMemo(() => {
    return fromRequestPayload(localCartItem, { design, colors })
  }, [localCartItem, design, colors])

  return (
    <Fragment>
      <Core
        {...rest}
        design={design}
        colors={colors}
        product={product}
        initialCustomization={initialCustomization}
      />
      <CustomizationCreationWarnings warnings={warnings} />
      {localCartItem.product.version !== product.version && (
        <OutdatedVersionedItemWarning />
      )}
    </Fragment>
  )
}
