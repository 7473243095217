import { Request } from '~/types/SavedDesign'
import MyDesign from '~/types/MyDesign'
import { apiURL, HttpStatusError, AuthError, buildURL } from '../common'

/**
 * マイデザイン一覧取得
 * @param token 認証トークン
 * @param price_line 商品価格ライン
 * @param series 商品のシリーズ
 */
export const fetchMyDesignList = async (
  token: string,
  priceLine?: string,
  series?: string
): Promise<MyDesign[]> => {
  const url = buildURL(`${apiURL}/me/designs`, {
    price_line: priceLine,
    series
  })

  const res = await fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (res.status === 401) {
    throw new AuthError()
  }

  if (res.status !== 200) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}

/**
 * マイデザイン取得
 * @param id 識別する一意のID
 * @param token 認証トークン
 */
export const fetchMyDesign = async (
  id: string,
  token: string
): Promise<MyDesign> => {
  const res = await fetch(`${apiURL}/me/designs/${id}`, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (res.status === 401) {
    throw new AuthError()
  }

  if (res.status !== 200) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}

/**
 * マイデザインに登録する
 * @param design デザイン情報
 * @param token 認証トークン
 */
export const registerMyDesign = async (
  design: Request,
  token: string
): Promise<MyDesign> => {
  const res = await fetch(`${apiURL}/me/designs`, {
    method: 'POST',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(design)
  })

  if (res.status === 401) {
    throw new AuthError()
  }

  if (res.status !== 201) {
    throw new HttpStatusError(res.status)
  }

  return res.json()
}

/**
 * マイデザインを編集する
 * @param id 識別する一意のID
 * @param design デザイン情報
 * @param token 認証トークン
 */
export const updateMyDesign = async (
  id: string,
  design: Request,
  token: string
): Promise<string> => {
  const res = await fetch(`${apiURL}/me/designs/${id}`, {
    method: 'PUT',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(design)
  })

  if (res.status === 401) {
    throw new AuthError()
  }

  if (res.status !== 204) {
    throw new HttpStatusError(res.status)
  }

  return res.text()
}
