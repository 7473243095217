import styled from '@emotion/styled/macro'
import * as React from 'react'

import { Dialog } from '~/components/atoms/Dialog'
import { OutlineButton } from '~/components/atoms/OutlineButton'

export interface MyDesignSaveMethodDialogProps<T extends Record<any, any>> {
  data: T | null

  onClose(): void

  /**
   * 新規保存を選んだ
   */
  onSelectNew(data: T): void

  /**
   * 上書き保存を選んだ
   */
  onSelectOverride(data: T): void
}

export function MyDesignSaveMethodDialog<T extends Record<any, any>>({
  data,
  onSelectNew,
  onSelectOverride,
  ...rest
}: MyDesignSaveMethodDialogProps<T>) {
  return (
    <PaddedDialog {...rest} opened={!!data} title="保存方法の選択">
      <Description>
        現在カスタマイズ中のマイデザインに上書き保存するか、新しく保存するかを選択してください。
      </Description>
      <Button
        colorTheme="blue-outline"
        onClick={() => {
          if (data) {
            onSelectOverride(data)
          }
        }}
      >
        上書き保存
      </Button>
      <Button
        colorTheme="blue-outline"
        onClick={() => {
          if (data) {
            onSelectNew(data)
          }
        }}
      >
        新しく保存
      </Button>
    </PaddedDialog>
  )
}

const PaddedDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gutter-medium);
  gap: var(--gutter-medium);
  width: 90vw;
  max-width: 300px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05em;
`

const Button = styled(OutlineButton)`
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.1em;
  padding: var(--gutter-small);
  align-self: stretch;
`
