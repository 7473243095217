import { useMemo } from 'react'

import { useFrameMessageReceiver } from '~/providers/FrameMessageReceiverProvider'

/**
 * ユーザからした現在のURLを取得する
 *
 * 通常起動されている場合は `location.href` を、フレームとして埋め込まれている場合は
 * 親フレームの URL を返す。
 */
export function useCurrentUrl(): URL {
  const { messages } = useFrameMessageReceiver()

  return useMemo(() => {
    const currentUrlMessage = messages.filter(
      ({ type }) => type === 'notify_current_url'
    )

    const lastMessage = currentUrlMessage[currentUrlMessage.length - 1]

    return new URL(lastMessage?.url || location.href)
  }, [messages])
}
