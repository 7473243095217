import { useEffect } from 'react'

import { registerCart } from '~/api/cart'
import { useMutation } from '~/hooks/useMutation'
import type { LoginSession } from '~/hooks/useSession'
import type { Cart } from '~/types/Cart'
import type { ReadyCustomization } from '~/types/Customization'
import type { Design } from '~/types/Design'
import type { Product } from '~/types/Product'
import { toRequestPayload } from '~/utils/customization'

interface MutationParams {
  customization: ReadyCustomization
  design: Design
  product: Product

  session: LoginSession
}

interface UseCartItemCreationParams {
  onCreated?(item: Cart): void

  onError?(error: unknown): void
}

export function useCartItemCreation({
  onCreated,
  onError
}: UseCartItemCreationParams = {}) {
  const mutation = useMutation(
    async ({ customization, design, product, session }: MutationParams) =>
      registerCart(
        toRequestPayload(customization, design, product),
        session.accessToken.token
      )
  )

  // ステータスコールバックを呼ぶ
  useEffect(() => {
    switch (mutation.status) {
      case 'success': {
        onCreated?.(mutation.data)
        return
      }
      case 'failed': {
        onError?.(mutation.error)
        return
      }
    }
  }, [mutation.status])

  return mutation
}
