import styled from '@emotion/styled/macro'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import * as React from 'react'
import { memo, useMemo } from 'react'

import { Icon } from '~/components/atoms/Icon'
import { snapZoom } from '~/utils/viewport'

function toLabel(scale: number): string {
  return `${Math.round(scale * 100)}%`
}

interface ZoomButtonProps {
  className?: string

  disabled?: boolean

  /**
   * 現在の拡大率
   */
  scale: number

  /**
   * 初期拡大率
   */
  defaultScale?: number

  /**
   * 最小拡大率
   */
  min?: number

  /**
   * 最大拡大率
   */
  max?: number

  /**
   * 拡大率を変更する際の増加値
   */
  step?: number

  /**
   * 拡大率が変更された際のイベント
   */
  onScaleChange(newValue: number): void
}

export const ZoomButton = memo<ZoomButtonProps>(
  ({
    className,
    disabled,
    scale,
    defaultScale = 1,
    min = 1,
    max = 2,
    step = 0.5,
    onScaleChange
  }) => {
    const mainButtonLabel = useMemo<string>(() => {
      if (scale === defaultScale) {
        return '拡大表示'
      }

      return toLabel(scale)
    }, [scale, defaultScale])

    const zoomPresets = useMemo<readonly number[]>(() => {
      const lower = Array.from(
        { length: Math.max(0, (defaultScale - min) / step) },
        (_, i) => defaultScale - (i + 1) * step
      )
      const upper = Array.from(
        { length: Math.max(0, (max - defaultScale) / step) },
        (_, i) => defaultScale + (i + 1) * step
      )

      return [...lower, defaultScale, ...upper]
    }, [defaultScale, min, max, step])

    const onMainButtonClick = () => {
      if (scale >= max) {
        onScaleChange(defaultScale)
        return
      }

      onScaleChange(snapZoom(scale, step))
    }

    return (
      <Container className={className}>
        <Button disabled={disabled} onClick={onMainButtonClick}>
          {mainButtonLabel}
        </Button>
        <Dropdown.Root>
          <Dropdown.Trigger asChild>
            <Button disabled={disabled} aria-label="ズームメニュー">
              <CaretIcon type="right" />
            </Button>
          </Dropdown.Trigger>

          <Dropdown.Portal>
            <DropdownContent sideOffset={2}>
              <DropdownItem
                disabled={scale <= min}
                onSelect={() => onScaleChange(snapZoom(scale, -step))}
              >
                縮小
              </DropdownItem>
              <DropdownItem
                disabled={scale >= max}
                onSelect={() => onScaleChange(snapZoom(scale, step))}
              >
                拡大
              </DropdownItem>
              <DropdownSeparator />
              <DropdownLabel>直接指定</DropdownLabel>
              {zoomPresets.map((value) => {
                return (
                  <DropdownItem
                    key={value}
                    onSelect={() => onScaleChange(value)}
                  >
                    {toLabel(value)}
                  </DropdownItem>
                )
              })}
            </DropdownContent>
          </Dropdown.Portal>
        </Dropdown.Root>
      </Container>
    )
  }
)

const Container = styled.div`
  display: inline-flex;
`

const Button = styled.button`
  display: block;
  padding: var(--gutter-small);
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.05em;

  background-color: var(--gray-0);
  border-radius: 24px;
  color: var(--black-700);
  text-align: center;

  &:first-of-type {
    min-width: 6.5em;
    padding-left: var(--gutter-medium);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-of-type {
    font-size: 16px;
    line-height: 1;
    padding-right: var(--gutter-medium);
    border-left: 1px solid var(--border-darker);

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    color: var(--teal-400);
  }

  &:disabled {
    color: var(--gray-400);
  }
`

const CaretIcon = styled(Icon)`
  transform: rotate(90deg);
`

const DropdownContent = styled(Dropdown.Content)`
  margin: 2px;
  padding: var(--gutter-extra-small);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;

  background-color: var(--gray-0);
  border-radius: 4px;
  box-shadow: 0 4px 4px var(--shadow-l1);
  color: var(--black-400);
`

const DropdownItem = styled(Dropdown.Item)`
  padding: var(--gutter-extra-small) var(--gutter-small);
  border-radius: 4px;
  cursor: pointer;

  &[data-highlighted] {
    background-color: var(--teal-400);
    color: var(--gray-0);
  }

  &[data-disabled] {
    color: var(--gray-400);
    pointer-events: none;
  }
`

const DropdownSeparator = styled(Dropdown.Separator)`
  height: 1px;
  background-color: var(--border-darker);
  margin: var(--gutter-extra-small);
`

const DropdownLabel = styled(Dropdown.Label)`
  font-size: 10px;
  padding: var(--gutter-extra-small);

  color: var(--black-200);
`
