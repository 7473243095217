import styled from '@emotion/styled/macro'
import { RadioGroup } from '@radix-ui/react-radio-group'
import { keyframes } from '@emotion/react'
import { createUseGesture, dragAction, wheelAction } from '@use-gesture/react'
import * as React from 'react'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'

import { Icon } from '~/components/atoms/Icon'
import type { Color } from '~/types/Color'

import { ColorSelectorItem, NullValueItem } from './ColorSelectorItem'

const useGesture = createUseGesture([dragAction, wheelAction])

const BOX_WIDTH = 40

interface ColorSelectorProps {
  disabled?: boolean

  value?: Pick<Color, 'id'> | null

  colors: readonly Color[]

  onChange?(color: Color): void
}

export const ColorSelector = ({
  disabled,
  value = null,
  colors,
  onChange
}: ColorSelectorProps) => {
  const groupRef = useRef<HTMLDivElement>(null)

  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollToCenter = useCallback(() => {
    return requestAnimationFrame(() => {
      if (!groupRef.current) {
        return
      }

      const options = Array.from(
        groupRef.current.querySelectorAll('[data-state]')
      )

      setSelectedIndex(
        options.findIndex(
          (el) => ((el as HTMLElement).dataset.state || '') === 'checked'
        )
      )
    })
  }, [])

  const containerRef = useRef<HTMLDivElement>(null)
  const gestureXRef = useRef<number>(0)
  const [gestureX, setGestureX] = useState<number>(0)
  const [isDragging, setIsDragging] = useState(false)
  const [isWheeling, setIsWheeling] = useState(false)
  const isGesturing = isDragging || isWheeling

  // ジェスチャー中は頻繁にイベントが発生するため、コンポーネントの再レンダリングを防ぐため
  // Ref + raf を使った手動のレンダリング制御を行っている
  useLayoutEffect(() => {
    if (!isGesturing) {
      return
    }

    let frameId: number | null = null

    const loop = () => {
      setGestureX(gestureXRef.current)

      frameId = requestAnimationFrame(loop)
    }

    loop()

    return () => {
      if (typeof frameId === 'number') {
        cancelAnimationFrame(frameId)
      }
    }
  }, [isGesturing])

  useEffect(() => {
    if (isGesturing) {
      return
    }

    const frameId = scrollToCenter()

    return () => {
      cancelAnimationFrame(frameId)
    }
  }, [value])

  const isFirst = selectedIndex <= (!value ? 0 : 1)

  // 未選択状態のプレースホルダが含まれているため +1 となる
  const isLast = selectedIndex >= colors.length

  const updateGestureX = (delta: number) => {
    const prev = gestureXRef.current

    gestureXRef.current = Math.max(
      Math.min(prev + delta, isLast ? prev : Infinity),
      isFirst ? prev : -Infinity
    )

    if (Math.abs(gestureXRef.current) > BOX_WIDTH && groupRef.current) {
      moveSelection(gestureXRef.current > 0 ? 1 : -1)

      // 端の状態で長くドラッグした場合でも、少し戻れば前の選択肢を選べるようにするため、
      // 選択肢が見つからなくてもリセットは行う
      gestureXRef.current = 0
    }
  }

  useGesture(
    {
      onDragStart() {
        setIsDragging(true)
        setGestureX(0)
        gestureXRef.current = 0
      },
      onDrag({ event, delta: [dx] }) {
        event.preventDefault()
        event.stopPropagation()

        // ドラッグでのスクロール動作となるため符号は反転する
        updateGestureX(-dx)
      },
      onDragEnd() {
        setIsDragging(false)
        setGestureX(0)
        gestureXRef.current = 0
      },
      onWheelStart() {
        setIsWheeling(true)
        setGestureX(0)
        gestureXRef.current = 0
      },
      onWheel({ event, delta: [dx] }) {
        event.preventDefault()

        updateGestureX(dx)
      },
      onWheelEnd() {
        setIsWheeling(false)
        setGestureX(0)
        gestureXRef.current = 0
      }
    },
    {
      enabled: !disabled,
      target: containerRef,
      eventOptions: { passive: false },
      drag: {
        axis: 'x',
        filterTaps: true,
        keys: false
      },
      wheel: { axis: 'x' }
    }
  )

  const moveSelection = useCallback(
    (amount: number, focus: boolean = false) => {
      if (!groupRef.current) {
        return
      }

      const options = Array.from(
        groupRef.current.querySelectorAll('[data-state]')
      ) as readonly HTMLElement[]

      const nextIndex = selectedIndex + amount

      if (!options[nextIndex]) {
        return
      }

      options[nextIndex].click()
      if (focus) {
        options[nextIndex].focus()
      }
      setSelectedIndex(nextIndex)
    },
    [selectedIndex]
  )

  return (
    <Container ref={containerRef}>
      <Group
        ref={groupRef}
        orientation="horizontal"
        value={value ? `c:${value.id}` : 'null'}
        onValueChange={(v: string) => {
          if (!onChange || v === 'null') {
            return
          }

          const [, id] = v.split(':')
          if (!id) {
            return
          }

          const color = colors.find((c) => c.id === id)
          if (!color) {
            return
          }

          onChange(color)
        }}
        data-empty={value === null}
        style={{
          transform: `translateX(calc(50% - var(--_box-width) * ${selectedIndex} - var(--_box-gap) - var(--_box-active-width) * 0.5 - ${gestureX}px))`,
          transition: isGesturing ? 'none' : undefined
        }}
      >
        <NullValueItem disabled={!!disabled} checked={value === null} />
        {colors.map((color) => (
          <ColorSelectorItem
            key={color.id}
            color={color}
            selected={value}
            disabled={disabled}
          />
        ))}
      </Group>
      <NavigationButton
        disabled={isFirst}
        aria-controls={groupRef.current?.id}
        aria-label="前の色へ"
        data-invisible={isFirst || isGesturing}
        onClick={() => moveSelection(-1)}
      >
        <Icon type="left" />
      </NavigationButton>
      <NavigationButton
        disabled={isLast}
        aria-controls={groupRef.current?.id}
        aria-label="次の色へ"
        data-invisible={isLast || isGesturing}
        onClick={() => moveSelection(1)}
      >
        <Icon type="right" />
      </NavigationButton>
    </Container>
  )
}

const floatingAnim = keyframes`
  from {
    transform: translateY(calc(var(--_float-amount, 3px) * -0.5));
  }

  to {
    transform: translateY(calc(var(--_float-amount, 3px) * 0.5));
  }
`

const Container = styled.div`
  --_box-width: ${BOX_WIDTH}px;
  --_box-height: 56px;
  --_box-active-width: 56px;
  --_box-active-height: 72px;
  --_float-amount: 2px;
  --_box-gap: 24px;

  position: relative;
  overflow-x: hidden;
  width: 100%;

  touch-action: none;
`

const Group = styled(RadioGroup)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  transition: transform 0.15s ease;

  &:focus-within ~ button > svg {
    animation: 0.5s 0s ease infinite both alternate ${floatingAnim};
  }
`

const NavigationButton = styled.button`
  --_size: 16px;
  --_gap: 2px;
  --_width: calc(var(--_box-gap) - var(--_gap) * 2);

  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--_width);
  height: var(--_box-active-height);
  font-size: var(--_size);

  transition: opacity 0.3s 0.1s ease-out;

  &:first-of-type {
    transform: translateX(
      calc(-1 * (100% + var(--_gap) + var(--_box-active-width) * 0.5))
    );
  }

  &:nth-of-type(2) {
    transform: translateX(calc(var(--_box-active-width) * 0.5 + var(--_gap)));
  }

  &[data-invisible='true'] {
    pointer-events: none;

    opacity: 0;
    transition: none;
  }

  &:focus-visible {
    color: var(--link-color);
    outline: none;
  }
`
