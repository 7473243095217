import * as React from 'react'
import { Fragment, useMemo } from 'react'

import { fetchDesign, fetchColors } from '~/api/customization'
import { fetchProduct } from '~/api/products'
import { CustomizationCreationWarnings } from '~/components/organisms/CustomizationCreationWarnings'
import { OutdatedVersionedItemWarning } from '~/components/organisms/OutdatedVersionedItemWarning'
import {
  BootstrapErrorPage,
  FetchErrorBody,
  ProductFetchErrorBody
} from '~/components/pages/BootstrapErrorPage'
import { CustomizePage } from '~/components/pages/CustomizePage'
import { useAsyncResult } from '~/hooks/useAsyncResult'
import type { ItemCreation as ItemCreationMode } from '~/hooks/useBootstrapMode'
import { fromSearchParams } from '~/utils/customization'

import { Core, CoreProps } from './Core'
import { loadingMessages } from './loadingMessages'
import { StorageSelectionGuard } from './StorageSelectionGuard'

interface ItemCreationProps {
  mode: ItemCreationMode
}

export const ItemCreation = (props: ItemCreationProps) => {
  return (
    <StorageSelectionGuard>
      <Body {...props} />
    </StorageSelectionGuard>
  )
}

const Body = ({ mode }: ItemCreationProps) => {
  const colors = useAsyncResult(() => fetchColors(), [])
  const design = useAsyncResult(() => fetchDesign(mode.product.id), [mode.type])
  const product = useAsyncResult(
    () => fetchProduct(mode.product.id),
    [mode.type]
  )

  if (colors.status === 'error') {
    return (
      <BootstrapErrorPage title="カラーの取得に失敗">
        <FetchErrorBody error={colors.error} onRetry={() => colors.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (product.status === 'error') {
    return (
      <BootstrapErrorPage title="商品データの読み込みに失敗">
        <ProductFetchErrorBody
          error={product.error}
          onRetry={() => product.refetch()}
        />
      </BootstrapErrorPage>
    )
  }

  if (design.status === 'error') {
    return (
      <BootstrapErrorPage title="デザインデータの読み込みに失敗">
        <FetchErrorBody error={design.error} onRetry={() => design.refetch()} />
      </BootstrapErrorPage>
    )
  }

  if (colors.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.colors} />
  }

  if (product.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.product} />
  }

  if (design.status !== 'ok') {
    return <CustomizePage loading message={loadingMessages.design} />
  }

  return (
    <WithCustomization
      mode={mode}
      colors={colors.result}
      design={design.result}
      product={product.result}
    />
  )
}

const WithCustomization = (
  props: Omit<CoreProps, 'initialCustomization'> & { mode: ItemCreationMode }
) => {
  const searchParams = useMemo<URLSearchParams>(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const [initialCustomization, warnings] = useMemo(
    () =>
      fromSearchParams(searchParams, {
        design: props.design,
        colors: props.colors
      }),
    [searchParams, props.design, props.colors]
  )

  return (
    <Fragment>
      <Core {...props} initialCustomization={initialCustomization} />
      <CustomizationCreationWarnings warnings={warnings} />
      {props.mode.product.version &&
        props.mode.product.version !== props.product.version && (
          <OutdatedVersionedItemWarning />
        )}
    </Fragment>
  )
}
