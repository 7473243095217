/** @jsx jsx */
import * as React from 'react'
import { jsx, css } from '@emotion/react'

import Close from './icons/Close'
import Drip from './icons/Drip'
import Info from './icons/Info'
import ArrowRight from './icons/ArrowRight'
import ArrowLeft from './icons/ArrowLeft'
import Twitter from './icons/Twitter'
import Facebook from './icons/Facebook'
import Instagram from './icons/Instagram'
import Line from './icons/Line'
import LongArrowDown from './icons/LongArrowDown'
import Loupe from './icons/Loupe'
import Heart from './icons/Heart'
import Check from './icons/Check'
import Stamp from './icons/Stamp'
import StampShadow from './icons/StampShadow'
import Slash from './icons/Slash'
import { Trash } from './icons/Trash'
import Exchange from './icons/Exchange'
import Share from './icons/Share'
import ShareApple from './icons/ShareApple'
import ShareLink from './icons/ShareLink'
import Copy from './icons/Copy'
import Question from './icons/Question'
import Cart from './icons/Cart'
import { CartIn } from './icons/CartIn'

type Type =
  | 'close'
  | 'drip'
  | 'info'
  | 'right'
  | 'left'
  | 'twitter'
  | 'facebook'
  | 'instagram'
  | 'line'
  | 'long-arrow-down'
  | 'loupe'
  | 'heart'
  | 'check'
  | 'stamp'
  | 'stamp-shadow'
  | 'slash'
  | 'exchange'
  | 'share'
  | 'share_apple'
  | 'share_link'
  | 'copy'
  | 'question'
  | 'cart'
  | 'cart-in'
  | 'trash'

export interface Props {
  className?: string

  /** アイコンの種類 */
  type: Type

  /**
   * HTMLの `role` 属性
   */
  role?: string
}

/**
 * 汎用的に使用するアイコンセット
 */
export const Icon: React.FC<Props> = ({ className, type, ...rest }) => {
  const icon = () => {
    switch (type) {
      case 'close':
        return <Close />
      case 'drip':
        return <Drip />
      case 'info':
        return <Info />
      case 'right':
        return <ArrowRight />
      case 'left':
        return <ArrowLeft />
      case 'twitter':
        return <Twitter />
      case 'facebook':
        return <Facebook />
      case 'instagram':
        return <Instagram />
      case 'line':
        return <Line />
      case 'long-arrow-down':
        return <LongArrowDown />
      case 'loupe':
        return <Loupe />
      case 'heart':
        return <Heart />
      case 'check':
        return <Check />
      case 'stamp':
        return <Stamp />
      case 'stamp-shadow':
        return <StampShadow />
      case 'slash':
        return <Slash />
      case 'exchange':
        return <Exchange />
      case 'share':
        return <Share />
      case 'share_apple':
        return <ShareApple />
      case 'share_link':
        return <ShareLink />
      case 'copy':
        return <Copy />
      case 'question':
        return <Question />
      case 'cart':
        return <Cart />
      case 'cart-in':
        return <CartIn />
      case 'trash':
        return <Trash />
      default:
        return <div />
    }
  }

  return (
    <svg
      {...rest}
      className={className}
      css={css({
        height: '1em',
        verticalAlign: 'text-top',
        overflow: 'visible'
      })}
      viewBox="0 0 16 16"
      fill="currentColor"
    >
      {icon()}
    </svg>
  )
}

export default Icon
