import { useMemo } from 'react'

interface UseLoginPageUrlParams {
  /**
   * ログイン後にリダイレクトするページ
   */
  redirectTo?: URL | string
}

/**
 * ログインページのURLを返す、だけ
 */
export function useLoginPageUrl({
  redirectTo
}: UseLoginPageUrlParams = {}): URL {
  return useMemo(() => {
    const url = new URL(
      'signin',
      process.env.REACT_APP_ORDER_URL || 'https://order.joggo.jp'
    )

    if (redirectTo) {
      url.searchParams.set('r', redirectTo.toString())
    }

    return url
  }, [redirectTo])
}
