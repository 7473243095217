import { useEffect } from 'react'

import { useToast } from '~/hooks/useToast'

export const OutdatedVersionedItemWarning = () => {
  const toast = useToast()

  useEffect(() => {
    toast.show({
      variant: 'warning',
      title:
        '商品のバージョンが新しくなったため、完全に復元できない可能性があります'
    })
  }, [])

  return null
}
