import * as zod from 'zod'

import { PrintType } from '~/types/PrintType'

const partCustomization = zod.object({
  /**
   * どの色を使うか
   */
  color: zod.object({
    id: zod.string()
  })
})

type PartCustomization = zod.infer<typeof partCustomization>

const printLogoCustomization = zod.object({
  printType: zod.nativeEnum(PrintType)
})

type PrintLogoCustomization = zod.infer<typeof printLogoCustomization>

const printNameCustomization = zod.union([
  zod.object({
    printType: zod.nativeEnum(PrintType),
    text: zod.string()
  }),
  zod.object({
    printType: zod.null(),
    text: zod.string()
  })
])

type PrintNameCustomization = zod.infer<typeof printNameCustomization>

const readyPrintNameCustomization = zod.object({
  printType: zod.nativeEnum(PrintType),
  text: zod.string()
})

export interface Customization {
  /**
   * パーツのカスタマイズ状態
   *
   * キーはパーツのIDとなり、ユーザがまだ該当パーツをカスタマイズしていない場合は `null` となる。
   */
  parts: Record<string, PartCustomization | null>

  /**
   * ロゴのカスタマイズ状態
   *
   * ユーザがまだロゴを選んでいない、もしくは商品にロゴが入らない場合は `null` となる。
   */
  printLogo: PrintLogoCustomization | null

  /**
   * 名入れのカスタマイズ状態
   *
   * 商品に名入れが出来ない場合は `null` となる。
   * まだ名入れを選んでいない場合は `undefined` になる。
   */
  printName: PrintNameCustomization | null | undefined
}

export const readyCustomization = zod.object({
  /**
   * パーツのカスタマイズ状態
   *
   * キーはパーツのIDとなる。
   */
  parts: zod.record(partCustomization),

  /**
   * ロゴのカスタマイズ状態
   *
   * 商品にロゴが入らない場合は `null` となる。
   */
  printLogo: zod.union([printLogoCustomization, zod.null()]),

  /**
   * 名入れのカスタマイズ状態
   *
   * ユーザが `なし` を選んだ場合、もしくは商品に名入れが出来ない場合は `null` となる。
   */
  printName: zod.union([readyPrintNameCustomization, zod.null()])
})

/**
 * 注文可能な状態のカスタマイズデータ
 */
export type ReadyCustomization = zod.infer<typeof readyCustomization>
