import { useCallback } from 'react'
import * as zod from 'zod'

import { readyCustomization } from '~/types/Customization'
import { LocalStorage } from '~/types/LocalStorage'

const storageItem = zod.object({
  id: zod.string(),
  destination: zod.enum(['myDesign', 'cart']),
  product: zod.object({
    id: zod.string(),
    version: zod.string()
  }),
  customization: readyCustomization,
  savedAt: zod.string().optional()
})

export type StorageItem = zod.infer<typeof storageItem>

interface TemporaryStorage {
  get(id?: string): Promise<StorageItem | null>
  getAll(): Promise<StorageItem[]>

  save(data: Omit<StorageItem, 'id'> & { id?: string }): Promise<void>
  clear(): Promise<void>

  /**
   * アイテムを一つ削除する
   * @returns 更新後の一覧
   */
  delete(data: Pick<StorageItem, 'id'>): Promise<readonly StorageItem[]>
}

/**
 * ユーザがページを離れてもカスタマイズ状態を保持するためのストレージI/F
 */
export function useCustomizationStorage(): TemporaryStorage {
  const getAll = useCallback<TemporaryStorage['getAll']>(async () => {
    const item = localStorage.getItem(LocalStorage.DesignV2)
    if (!item) {
      return []
    }

    try {
      return zod.array(storageItem).parse(JSON.parse(item))
    } catch (err) {
      throw new Error('一時保存データの読み込みに失敗しました', { cause: err })
    }
  }, [])

  const get = useCallback<TemporaryStorage['get']>(
    async (id) => {
      const items = await getAll()

      if (!id) {
        return items[0] || null
      }

      return items.find((item) => item.id === id) || null
    },
    [getAll]
  )

  const save = useCallback<TemporaryStorage['save']>(
    async (data) => {
      const id = data.id ?? Date.now().toString(24)
      const item: StorageItem = {
        ...data,
        id,
        savedAt: new Date().toISOString()
      }

      const items = await getAll()

      try {
        localStorage.setItem(
          LocalStorage.DesignV2,
          JSON.stringify([...items, item])
        )
      } catch (err) {
        throw new Error('カスタマイズデータの一時保存に失敗しました', {
          cause: err
        })
      }
    },
    [getAll]
  )

  const clear = useCallback(async () => {
    try {
      localStorage.removeItem(LocalStorage.DesignV2)
    } catch (err) {
      throw new Error('一時保存領域の消去に失敗しました', { cause: err })
    }
  }, [])

  const deleteItem = useCallback<TemporaryStorage['delete']>(
    async ({ id }) => {
      const items = await getAll()

      try {
        const updated = items.filter((item) => item.id !== id)

        localStorage.setItem(LocalStorage.DesignV2, JSON.stringify(updated))

        return updated
      } catch (err) {
        throw new Error('一時保存データの削除に失敗しました', { cause: err })
      }
    },
    [getAll]
  )

  return { getAll, get, save, clear, delete: deleteItem }
}
