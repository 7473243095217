import React from 'react'

export const Copy: React.FC = () => {
  return (
    <path
      transform="translate(1 0)"
      d="M13.563 2.063L11.937.437A1.493 1.493 0 0010.88 0H5.5A1.5 1.5 0 004 1.5V3H1.5A1.5 1.5 0 000 4.5v10A1.5 1.5 0 001.5 16h7a1.5 1.5 0 001.5-1.5V13h2.5a1.5 1.5 0 001.5-1.5V3.121c0-.398-.156-.777-.438-1.058zM11 1.016c.09.02.168.066.23.129l1.625 1.625c.063.062.11.14.13.23H11zM9 14.5c0 .277-.223.5-.5.5h-7a.498.498 0 01-.5-.5v-10c0-.277.223-.5.5-.5H4v7.5A1.5 1.5 0 005.5 13H9zm4-3c0 .277-.223.5-.5.5h-7a.498.498 0 01-.5-.5v-10c0-.277.223-.5.5-.5H10v2.25c0 .414.336.75.75.75H13zm0 0"
    />
  )
}

export default Copy
