/** @jsx jsx */
import { ReactNode, forwardRef } from 'react'
import styled from '@emotion/styled/macro'
import { jsx, keyframes } from '@emotion/react'

import { Icon } from '~/components/atoms/Icon'

interface StepMenuItemProps {
  className?: string

  disabled?: boolean

  /**
   * ステップの状態
   *
   * - `not_completed`       ... ステップを完了していない
   * - `completed`           ... ステップを完了した
   * - `completed:${string}` ... ステップを完了し、色が指定された (`:` の後はCSS Colorとして扱われる)
   */
  status?: 'not_completed' | 'completed' | `completed:${string}`

  /**
   * 現在アクティブなステップかどうか
   */
  selected?: boolean

  children: Exclude<ReactNode, undefined | null | boolean>

  /**
   * クリックされた際のイベントハンドラ
   */
  onClick?(): void
}

export const StepMenuItem = forwardRef<HTMLLIElement, StepMenuItemProps>(
  (
    {
      className,
      disabled,
      status = 'not_completed',
      selected,
      children,
      onClick
    }: StepMenuItemProps,
    ref
  ) => {
    return (
      <Container ref={ref} className={className}>
        <Button
          aria-current={selected ? 'step' : undefined}
          disabled={disabled}
          onClick={() => onClick?.()}
        >
          {status === 'not_completed' ? null : status === 'completed' ? (
            <Badge aria-label="設定済み">
              <Icon type="check" />
            </Badge>
          ) : (
            <Badge
              aria-label="色を設定済み"
              style={{ backgroundColor: status.replace('completed:', '') }}
            />
          )}
          <StepPosition>
            <StepPositionText />
          </StepPosition>
          <span>{children}</span>
        </Button>
      </Container>
    )
  }
)

const Container = styled.li`
  display: block;
  position: relative;
  min-width: 80px;
  counter-increment: current;
  flex-shrink: 0;
`

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--gutter-small);
  gap: var(--gutter-extra-small);
  font-size: var(--primary-font-size);
  line-height: 1.33;
  border: 1px solid var(--border-darker);
  width: 100%;

  background-color: var(--gray-0);
  border-radius: 4px;

  &[aria-current='step'] {
    box-shadow: 0 0 0 4px var(--link-color);
  }

  &:disabled {
    background-color: var(--gray-100);
    color: var(--black-200);
  }
`

const StepPosition = styled.span`
  font-weight: var(--bold-weight);
`

const StepPositionText = styled.span`
  &::before {
    content: counter(current) ' / ' counter(total);
  }
`

const badgeEnterAnimation = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`

const Badge = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 9px;
  border: 1px solid var(--gray-400);

  background-color: var(--primary-color);
  border-radius: 50%;
  color: var(--gray-0);

  animation: 0.15s 0s cubic-bezier(0.34, 1.56, 0.64, 1) both 1
    ${badgeEnterAnimation};
`
