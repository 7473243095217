import { useMemo } from 'react'

/**
 * カートページのURLを返す、だけ
 */
export function useCartPageUrl(): URL {
  return useMemo(() => {
    const url = new URL(
      'cart/items/',
      process.env.REACT_APP_ORDER_URL || 'https://order.joggo.jp'
    )

    return url
  }, [])
}
